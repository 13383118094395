import React, {useContext, useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Container, Tooltip, useMediaQuery} from "@mui/material";
import {Home as HomeIcon, Notifications as NotificationsIcon} from "@mui/icons-material";
import {PAContext} from "../../../Services/PAContext";
import {useLocation, useNavigate} from "react-router-dom";
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);




export default function LeftSideMenuResponsive(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const { dataArray, setSideMenuWidth } = useContext(PAContext);
    const { Events, Account, workingOnEventId } = dataArray;

    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);


    const handleDrawerToggle = () => {
        setOpen(!open);

        !open ?  setSideMenuWidth(240) : setSideMenuWidth(70);

    };

    const [currentEventCount, setCurrentEventCount] = useState(0);
    const [pastEventCount, setPastEventCount] = useState(0);
    const [categoriesList, setCategoriesList] = useState({});

    //if no Event has been selected yet
    const baseItems = [
        { icon: <HomeIcon />, label: 'Home', count: 0, selected: location.pathname === '/dashboard'},
        { icon: <ChevronRightIcon />, label: 'Current', count: currentEventCount , selected: location.pathname === '/dashboard/Events/Current'},
        { icon: <ChevronLeftIcon />, label: 'Past', count: pastEventCount , selected: location.pathname === '/dashboard/Events/Past'}
    ];
    const categoryItems = [
        { icon: <HomeIcon />, label: 'Home', count: 0},
        { icon: <ChevronRightIcon />, label: 'Current', count: currentEventCount },
        { icon: <ChevronLeftIcon />, label: 'Past', count: pastEventCount }
    ];

    //if an Event has been selected
    const eventItemsList = [];


    useEffect(() => {
        console.log("the Events array has changed");
        const today = new Date();
        setCurrentEventCount(Events.filter(event => new Date(event.calendar.begin_date) >= today).length);
        setPastEventCount(Events.filter(event => new Date(event.calendar.end_date) < today).length);

        const uniqueCategoriesCount = getUniqueCategoriesForAttendee(Events, Account.id);
        const sortedCategories = Object.entries(uniqueCategoriesCount).sort(([categoryA], [categoryB]) =>
            categoryA.localeCompare(categoryB)
        );

        Object.keys(sortedCategories).forEach((item) => {

            console.log("the item : "+sortedCategories[item]);
            console.log(`sortedCategories: ${sortedCategories[item][0]}, Count: ${sortedCategories[item][1]}`);
        });

        Object.keys(sortedCategories).map((item) => {
            console.log("the map item : "+sortedCategories[item]);
            console.log(`map item info: ${sortedCategories[item][0]}, Count: ${sortedCategories[item][1]}`);
        })

        console.log("sorted : "+JSON.stringify(Object.fromEntries(sortedCategories)));

        setCategoriesList(sortedCategories);

    }, [Events]);


    useEffect(() => {
        //update the menu with the relevant list
        console.log("the workingOnEventId has changed : "+workingOnEventId);


    }, [workingOnEventId]);

    const [selectedIndex, setSelectedIndex] = useState(1);

    const handleMenuItemClick = (e, index) => {
        console.log('button click ', e);
        setSelectedIndex(index);

        if (e === "Home"){
            props.setViewingEventGroup("all");
            navigate('/dashboard/Events');
        } else if (e === "Current"){
            props.setViewingEventGroup("current");
            navigate('/dashboard/Events/Current');
        } else if (e === "Past"){
            props.setViewingEventGroup("past");
            navigate('/dashboard/Events/Past');
        } else {
            props.setViewingEventGroup(e);
            navigate('/dashboard/Events/ByCategory');
        }
    };


    // Function to get unique categories for a particular menu_item id
    const getUniqueCategoriesForAttendee = (events, attendeeId) => {
        console.log("uniqueCategoriesCount for : "+attendeeId);

        const uniqueCategoriesCount = {};

        events.forEach((event) => {
            console.log("uniqueCategoriesCount event.menu_items : "+JSON.stringify(event.menu_items));

            event.menu_items.forEach((menuItem) => {

                if (menuItem.id === attendeeId) {
                    menuItem.categories.forEach((category) => {
                        if (!uniqueCategoriesCount.hasOwnProperty(category)) {
                            uniqueCategoriesCount[category] = 1;
                        } else {
                            uniqueCategoriesCount[category]++;
                        }
                    });
                }
            });
        });

        console.log("uniqueCategoriesCount : "+JSON.stringify(uniqueCategoriesCount));

        return uniqueCategoriesCount;
    };



    return (




            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerToggle}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {isMobileScreen ? (
                        open ? (
                            workingOnEventId === "" ? (
                                <>
                                    {baseItems.map((item, index) => (
                                        <ListItemButton
                                            key={index}
                                            selected={item.selected}
                                            onClick={() => handleMenuItemClick(item.label)}
                                        >
                                            {item.icon}
                                            <ListItemText primary={item.label} />
                                            <Badge badgeContent={item.count} color="primary" />
                                        </ListItemButton>
                                    ))}
                                    <Divider />
                                    {categoriesList.length > 0 && (
                                        Object.keys(categoriesList).map((item, index) => (
                                            <ListItem
                                                key={index+300}
                                                secondaryAction={
                                                    categoriesList[item][1]
                                                }
                                                onClick={() => handleMenuItemClick(categoriesList[item][0])}
                                            >
                                                <FolderSpecialIcon />
                                                <ListItemText primary={categoriesList[item][0]} />
                                            </ListItem>
                                        ))
                                    )}
                                </>
                            ) : (
                                eventItemsList.map((item, index) => (
                                    <ListItemButton key={index} onClick={handleMenuItemClick}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.label} />
                                        <Badge badgeContent={item.count} color="primary" />
                                    </ListItemButton>
                                ))
                            )
                        ) : (
                            // Show only item icons in mobile mode
                            workingOnEventId === "" ? (
                                <>
                                    {baseItems.map((item, index) => (
                                        <ListItemButton
                                            key={index}
                                            selected={item.selected}
                                            onClick={() => handleMenuItemClick(item.label)}
                                        >
                                            <Badge badgeContent={item.count} color="primary">
                                                {item.icon}
                                            </Badge>
                                        </ListItemButton>
                                    ))}
                                    <Divider />
                                    {categoriesList.length > 0 && (
                                        Object.keys(categoriesList).map((item, index) => (
                                            <Tooltip
                                                key={index+200}
                                                title={categoriesList[item][0]}
                                                placement="right"
                                            >
                                                <ListItemButton
                                                    onClick={() => handleMenuItemClick(categoriesList[item][0])}
                                                >
                                                    <Badge badgeContent={categoriesList[item][1]} color="primary">
                                                        <FolderSpecialIcon />
                                                    </Badge>
                                                </ListItemButton>
                                            </Tooltip>
                                        ))
                                    )}
                                </>
                            ) : (
                                eventItemsList.map((item, index) => (
                                    <ListItemButton
                                        key={index}
                                        selected={item.selected}
                                        onClick={handleMenuItemClick}
                                    >
                                        <Badge badgeContent={item.count} color="primary">
                                            {item.icon}
                                        </Badge>
                                    </ListItemButton>
                                ))
                            )
                        )

                    ) : (
                        // Show full list in larger screens

                        open ? (
                            //show full width
                            workingOnEventId === "" ? (
                                <>
                                    {baseItems.map((item, index) => (
                                        <ListItemButton
                                            key={index}
                                            selected={item.selected}
                                            onClick={() => handleMenuItemClick(item.label)}
                                        >
                                            {item.icon}
                                            <ListItemText primary={item.label} />
                                            <Badge badgeContent={item.count} color="primary" />
                                        </ListItemButton>
                                    ))}
                                    <Divider />
                                    {categoriesList.length > 0 && (
                                        Object.keys(categoriesList).map((item, index) => (
                                            <ListItem
                                                key={index+300}
                                                secondaryAction={
                                                    categoriesList[item][1]
                                                }
                                                onClick={() => handleMenuItemClick(categoriesList[item][0])}
                                            >
                                                <FolderSpecialIcon />
                                                <ListItemText primary={categoriesList[item][0]} />
                                            </ListItem>
                                        ))
                                    )}
                                </>
                            ) : (
                                eventItemsList.map((item, index) => (
                                    <ListItemButton key={index} onClick={handleMenuItemClick}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.label} />
                                        <Badge badgeContent={item.count} color="primary" />
                                    </ListItemButton>
                                ))
                            )

                        ) : (
                            //just show the icons and the badges
                            workingOnEventId === "" ? (
                                <>
                                    {baseItems.map((item, index) => (
                                        <ListItemButton
                                            key={index}
                                            selected={item.selected}
                                            onClick={() => handleMenuItemClick(item.label)}
                                        >
                                            <Badge badgeContent={item.count} color="primary">
                                                {item.icon}
                                            </Badge>
                                        </ListItemButton>
                                    ))}
                                    <Divider />
                                    {categoriesList.length > 0 && (
                                        Object.keys(categoriesList).map((item, index) => (
                                            <Tooltip
                                                key={index+200}
                                                title={categoriesList[item][0]}
                                                placement="right"
                                            >
                                                <ListItemButton
                                                    onClick={() => handleMenuItemClick(categoriesList[item][0])}
                                                >
                                                    <Badge badgeContent={categoriesList[item][1]} color="primary">
                                                        <FolderSpecialIcon />
                                                    </Badge>
                                                </ListItemButton>
                                            </Tooltip>
                                        ))
                                    )}
                                </>
                            ) : (
                                eventItemsList.map((item, index) => (
                                    <ListItemButton key={index} onClick={handleMenuItemClick}>
                                        <Badge badgeContent={item.count} color="primary">
                                            {item.icon}
                                        </Badge>
                                    </ListItemButton>
                                ))
                            )

                        )

                    )}
                </List>

            </Drawer>


    );
}
