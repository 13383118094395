import React, {useEffect, useState} from 'react';
import {Avatar, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import {red} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";



function AttendeeCard(props) {


    const [attendee, setAttendee] = useState(props.attendee);

    useEffect(()=> {
        setAttendee(props.attendee);
    }, [props.attendee]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    const formatPhoneNumber = (prefix, phone) => {
        return `${prefix} ${phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`;
    };

    return (

        <Card
            sx={{
                width: '250px', // Fixed width for each card
                flexShrink: 0, // Prevent cards from shrinking
                padding: '16px',
                margin: '16px',
                borderRadius: '8px'
            }}
        >

            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {attendee.fname.charAt(0)}
                    </Avatar>
                }
                title={`${capitalizeFirstLetter(attendee.fname)} ${capitalizeFirstLetter(attendee.lname)}`}
                subheader={dayjs(attendee.created).format('MMM D, YYYY')}
                subheaderTypographyProps={{ sx: { fontSize: '11px', color: 'text.secondary' } }} // Change font size and color
            />

            <CardContent sx={{display:'grid'}}>
                <Typography variant="subtitle2">
                    Nickname : {attendee.nickname}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    Email : {attendee.email}
                </Typography>

                {
                    attendee.address !== "" ? (
                        <Typography variant="caption" color="text.secondary" sx={{mt:1.5, mb:1.5}}>
                            {attendee.address}
                            <br />
                            {attendee.city}  {attendee.state}  {attendee.zip}
                            <br />
                            {attendee.country}
                        </Typography>
                    ):(
                        <></>
                    )
                }

                <Typography variant="caption" color="text.secondary">
                    Role : {attendee.role}
                </Typography>



                <Typography variant="caption" color="text.secondary">
                    Phone: {formatPhoneNumber(attendee.phone_prefix, attendee.phone)}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    Language : {attendee.language}
                </Typography>
            </CardContent>

            <CardActions>
                {/* add the number of Events */}
                <div
                    className="activities-suggestions-add-list-item-footer-occupants-container"
                >
                    <Badge
                        badgeContent={attendee.events.length}
                        color="primary"
                    >
                        <i className="icon-calendar"  title="Events"/>
                    </Badge>
                </div>
                {/* add the number of Menu Items */}
                <div
                    className="activities-suggestions-add-list-item-footer-occupants-container"
                >
                    <Badge
                        badgeContent={attendee.menu_items.length}
                        color="primary"
                    >
                        <i className="icon-folder-heart"  title="Categories"/>
                    </Badge>
                </div>
                {/* add the number of Essentials */}
                <div
                    className="activities-suggestions-add-list-item-footer-occupants-container"
                >
                    <Badge
                        badgeContent={attendee.essentials.length}
                        color="primary"
                    >
                        <i className="icon-checklist2"  title="Essentials"/>
                    </Badge>
                </div>
                {/* add the number of Documents */}
                <div
                    className="activities-suggestions-add-list-item-footer-occupants-container"
                >
                    <Badge
                        badgeContent={attendee.documents.length}
                        color="primary"
                    >
                        <i className="icon-file-check"  title="Documents"/>
                    </Badge>
                </div>

            </CardActions>



        </Card>
    )

}

export default AttendeeCard;
