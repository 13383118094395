// Home.js

import React from 'react';

import CreateEventContent from "./CreateEventContent";
import {Grid} from "@mui/material";



function CreateEvent() {


    return (

        <Grid container spacing={12}>
            <CreateEventContent />
        </Grid>

    )

}

export default CreateEvent;
