import Location from "./Location";


class Idea {
    constructor(){

        this.id = 0;
        this.index = 0;
        this.title = "";
        this.category = "";

        this.attendee_id = "";

        this.url = "";

        this.location = new Location();
        this.photos = [];

        this.comments = [];

        this.status = 0;
    }
}

export default Idea;
