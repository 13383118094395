import React, {useContext, useEffect, useState} from 'react';
import {DatePicker, PickersDay, TimePicker} from "@mui/x-date-pickers";
import {
    Card,
    CardContent,
    CardMedia,
    Chip,
    FormControl,
    Grid,
    InputLabel, MenuItem,
    Paper, Select,
    Switch,
    Tab,
    TextField
} from "@mui/material";
import {PAContext} from "../../../Services/PAContext";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import './datesTimes.css';
import datesTimesHeaderImage from "../../../graphics/Reminder-Vacation-in-calendar-544113512_2125x1417-1200x0-c-default.jpeg";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import {addGradientStyle} from "../../ButtonStyle/Add";
import {cancelChipStyle, cancelGradientStyle} from "../../ButtonStyle/Cancel";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import {updateChipStyle} from "../../ButtonStyle/Update";

// Extend dayjs to work with localized formats and weekdays
dayjs.extend(localizedFormat);
dayjs.extend(weekday);



function DatesTimesContent(props) {

    const { dataArray, setPlanning } = useContext(PAContext);
    const { Planning } = dataArray;

    const [currentBeginDate, setCurrentBeginDate] = useState(null);
    const [currentEndDate, setCurrentEndDate] = useState(null);
    const [datesCount, setDatesCount] = useState(0);
    const [updatedBeginDate, setUpdatedBeginDate] = useState(null);
    const [updatedEndDate, setUpdatedEndDate] = useState(null);


    useEffect(() => {
        console.log("useEffect Planning.time_table : " +Planning.time_table);

        if (Object.keys(Planning).length > 0){
            const _timeTable = Planning.time_table;

            console.log("Planning.time_table : "+JSON.stringify(_timeTable, null, '\t'));

            if (_timeTable.dates.length > 0){
                const dates = _timeTable.dates.map(item => new Date(item.date));

                // Find the earliest and latest dates
                const earliestDate = new Date(Math.min(...dates));
                const latestDate = new Date(Math.max(...dates));

                // Convert the dates back to string format if needed
                const earliestDateString = earliestDate.toISOString().split('T')[0];
                const latestDateString = latestDate.toISOString().split('T')[0];

                console.log('Earliest Date:', earliestDateString);
                console.log('Latest Date:', latestDateString);

                setCurrentBeginDate(dayjs(earliestDateString));
                setCurrentEndDate(dayjs(latestDateString));

                setUpdatedBeginDate(dayjs(earliestDateString));
                setUpdatedEndDate(dayjs(latestDateString));

                setDatesCount(_timeTable.dates.length);

                //see if there is a begin-time and an end-time
                let beginTime, endTime;
                for (const item of _timeTable.dates) {
                    if (item['begin-time']) {
                        beginTime = item['begin-time'];
                        setBeginTimeValue(dayjs(beginTime, "h:mm A"));
                        if (beginTime !== "12:00 AM"){
                            setTimeOptionChecked(true);
                        }

                    }

                    if (item['end-time']) {
                        endTime = item['end-time'];
                        setEndTimeValue(dayjs(endTime, "h:mm A"));
                        if (endTime !== "11:59 PM"){
                            setTimeOptionChecked(true);
                        }
                    }
                }
                console.log('Begin Time:', beginTime);
                console.log('End Time:', endTime);

            }

            if (_timeTable.days.length > 0){
                setEventDaysCount(_timeTable.days.length);
                setEventDaysStartingOnDay(_timeTable.days[0]['day 1']);

                //see if there is a begin-time and an end-time
                let beginTime, endTime;
                for (const item of _timeTable.days) {
                    if (item['begin-time']) {
                        beginTime = item['begin-time'];
                        setBeginTimeValue(dayjs(beginTime, "h:mm A"));
                        if (beginTime !== "12:00 AM"){
                            setTimeOptionChecked(true);
                        }
                    }

                    if (item['end-time']) {
                        endTime = item['end-time'];
                        setEndTimeValue(dayjs(endTime, "h:mm A"));
                        if (endTime !== "11:59 PM"){
                            setTimeOptionChecked(true);
                        }
                    }
                }

                console.log('Begin Time:', beginTime);
                console.log('End Time:', endTime);

            }

        }

    }, [Planning.time_table]);

    function handlePromptChangeDatesOK() {
        console.log("handlePromptChangeDatesOK");

        setCurrentBeginDate(updatedBeginDate);
        setCurrentEndDate(updatedEndDate);
//        setUpdatedBeginDate(null);
//        setUpdatedEndDate(null);

        console.log("update the Planning dates");

        //update the Event
        if (Object.keys(Planning).length === 0){
            //create a new Event
            const newEvent = new PAEvent();

            newEvent.title = "My New Event";

            const _timetable = new TimeTable();

            /*
                figure out if the time is involved in the Event
             */
            newEvent.time_table = createTimeTable();

            console.log("New Event : "+JSON.stringify(newEvent, null, '\t'));

            setPlanning(newEvent);

            props.setEventName("My New Event");

        } else {

            Planning.time_table = createTimeTable();
            setPlanning(Planning);
            console.log("updated Planning : "+JSON.stringify(Planning, null, '\t'));

        }

        props.setEventBeginDate(updatedBeginDate);
        props.setEventEndDate(updatedEndDate);

        console.log("see if need to pull weather");
        needToCheckWeather();

        props.setShowEventItemConfig(false);
    }

    function handlePromptChangeDatesClose() {
        props.setShowEventItemConfig(false);
//        setUpdatedBeginDate(null);
//        setUpdatedEndDate(null);

    }

    function createTimeTable(){
        console.log("createTimeTable");


        const _timeTable = new TimeTable();

        //figure out the dates first
        if (timeOptionChecked
            && updatedBeginDate !== null
            && updatedEndDate !== null
            && updatedBeginTime !== null
            && updatedEndTime !== null){

            console.log("updatedBeginDate : "+updatedBeginDate.format());
            console.log("updatedEndDate : "+updatedEndDate.format());

            console.log("times are involved");
            //times need to be included
            console.log("updatedBeginTime : "+updatedBeginTime.format());
            console.log("updatedEndTime : "+updatedEndTime.format());

            setUpdatedBeginDate(updatedBeginDate.set('hour', updatedBeginTime.hour()).set('minute', updatedBeginTime.minute()).set('second', 0));
            setUpdatedEndDate(updatedEndDate.set('hour', updatedEndTime.hour()).set('minute', updatedEndTime.minute()).set('second', 0));

            const _modifiedBeginDateTime = updatedBeginDate.set('hour', updatedBeginTime.hour()).set('minute', updatedBeginTime.minute()).set('second', 0);
            const _modifiedEndDateTime = updatedEndDate.set('hour', updatedEndTime.hour()).set('minute', updatedEndTime.minute()).set('second', 0);

            console.log("_modifiedBeginDateTime : "+_modifiedBeginDateTime.format());
            console.log("_modifiedEndDateTime : "+_modifiedEndDateTime.format());

            const dateArray = [];

            let currentDate = _modifiedBeginDateTime;

            if (updatedBeginDate.isSame(updatedEndDate)){
                //single day event with begin and end times
                const dayInfo = {
                    'date': currentDate.format('YYYY-MM-DD'),
                    'dayOfWeek': currentDate.format('dddd'),
                    'all-day': false,
                    'begin-time': _modifiedBeginDateTime.format('h:mm A'),
                    'end-time': _modifiedEndDateTime.format('h:mm A'),
                    'notes': '' };

                dateArray.push(dayInfo);
            } else {
                //begin date is different from end date
                while (currentDate.isBefore(_modifiedEndDateTime) || currentDate.isSame(_modifiedEndDateTime, 'day')) {
                    const dayInfo = {
                        'date': currentDate.format('YYYY-MM-DD'),
                        'dayOfWeek': currentDate.format('dddd'),
                        'all-day': true,
                        'notes': ''
                    };

                    if (currentDate.isSame(_modifiedBeginDateTime, 'day')) {
                        dayInfo['begin-time'] = _modifiedBeginDateTime.format('h:mm A');
                        dayInfo['all-day'] = false;
                    }

                    if (currentDate.isSame(_modifiedEndDateTime, 'day')) {
                        dayInfo['end-time'] = _modifiedEndDateTime.format('h:mm A');
                        dayInfo['all-day'] = false;
                    }

                    dateArray.push(dayInfo);

                    currentDate = currentDate.add(1, 'day');
                }
            }

            _timeTable.dates = dateArray;

        } else if (updatedBeginDate !== null && updatedEndDate !== null){
            //full day(s) Event
            console.log("full day events");

            console.log("updatedBeginDate : "+updatedBeginDate.format());
            console.log("updatedEndDate : "+updatedEndDate.format());

            let currentDate = updatedBeginDate;

            const dateArray = [];

            while (currentDate.isBefore(updatedEndDate) || currentDate.isSame(updatedEndDate, 'day')) {
                console.log("working on : "+currentDate.format('YYYY-MM-DD'));
                dateArray.push({
                    date: currentDate.format('YYYY-MM-DD'),
                    dayOfWeek: currentDate.format('dddd'),
                    'all-day': true,
                    notes: '',
                });

                currentDate = currentDate.add(1, 'day');

            }

            _timeTable.dates = dateArray;
        }

        //figure out the days if chosen
        console.log("eventDaysCount : "+eventDaysCount);

        if (eventDaysCount > 0){
            if (eventDaysStartingOnDay !== ''){
                const daysArray = [];

                const _dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                let _startDayIndex = _dayNames.indexOf(eventDaysStartingOnDay);

                for (let i=1; i <= eventDaysCount; i++){
                    const _dayItem = {};
                    _dayItem['day '+i] = _dayNames[_startDayIndex % 7];
                    _dayItem['all-day'] = true;
                    _dayItem['notes'] = '';

                    if (i === 1 && updatedBeginTime !== null ){
                        _dayItem['all-day'] = false;
                        _dayItem['begin-time'] = updatedBeginTime.format('h:mm A');
                    }

                    if (i === parseInt(eventDaysCount) && updatedEndTime !== null ){
                        _dayItem['all-day'] = false;
                        _dayItem['end-time'] = updatedEndTime.format('h:mm A');
                    }

                    daysArray.push(_dayItem);
                    _startDayIndex++;
                }

                _timeTable.days = daysArray;

            } else {
                //no starting day selected
                const daysArray = [];

                console.log("updatedBeginTime : "+updatedBeginTime);
                console.log("updatedEndTime : "+updatedEndTime);

                for (let i=1; i <= eventDaysCount; i++){
                    const _dayItem = {};
                    _dayItem['day '+i] = '';
                    _dayItem['all-day'] = true;
                    _dayItem['notes'] = '';

                    if (i === 1 && updatedBeginTime !== null ){
                        _dayItem['all-day'] = false;
                        _dayItem['begin-time'] = updatedBeginTime.format('h:mm A');
                    }

                    if (i === parseInt(eventDaysCount) && updatedEndTime !== null ){
                        _dayItem['all-day'] = false;
                        _dayItem['end-time'] = updatedEndTime.format('h:mm A');
                    }

                    daysArray.push(_dayItem);
                }

                _timeTable.days = daysArray;
            }

        }

        console.log("_timeTable : "+JSON.stringify(_timeTable, null, '\t'));

        return _timeTable;

    }

    function handleUpdatedBeginDate(e) {
        console.log("handleUpdatedBeginDate : "+dayjs(e));
        setUpdatedBeginDate(dayjs(e));

        if (updatedEndDate === null) {
            setUpdatedEndDate(dayjs(e));
        } else if (e.isAfter(updatedEndDate)){
            console.log("updating the end date");
            setUpdatedEndDate(dayjs(e));
        }

    }

    function handleUpdatedEndDate(e) {
        console.log("handleUpdatedEndDate : "+e);
        setUpdatedEndDate(dayjs(e));
    }

    function needToCheckWeather(){

        try {
            if (Planning && Planning.begin_date && Planning.location.google_place_id) {
                // Both properties are not empty, so call your function here
                console.log("Pull the weather using this new location");
                props.setPullWeatherReport(true);

            }
        } catch (e){}
    }

    console.log("updatedBeginDate : "+updatedBeginDate);
    console.log("updatedEndDate : "+updatedEndDate);


    const [beginTimeSelected, setBeginTimeSelected] = useState(false);
    const [endTimeSelected, setEndTimeSelected] = useState(false);
    const [beginTimeValue, setBeginTimeValue] = useState(null);
    const [endTimeValue, setEndTimeValue] = useState(null);
    const [updatedBeginTime, setUpdatedBeginTime] = useState(null);
    const [updatedEndTime, setUpdatedEndTime] = useState(null);

    function handleUpdatedBeginTime(e){
        setUpdatedBeginTime(e);
    }
    function handleUpdatedEndTime(e){
        setUpdatedEndTime(e);
    }

    const highlightedDates = [];
    function ServerDay(props) {
        const { highlightedDates = [], day, outsideCurrentMonth, ...other } = props;

        const isSelected =
            !props.outsideCurrentMonth &&
            highlightedDates.includes(props.day.format('YYYY-MM-DD'));

        return (
            <Badge
                key={props.day.toString()}
                variant={isSelected ? "dot" : "standard"} // Use variant="dot" for a dot, "standard" for a regular badge
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: isSelected ? 'red' : 'transparent', // Customize the dot color here
                        width: '8px', // Adjust the dot size as needed
                        height: '8px',
                        borderRadius: '50%', // Make it a circle
                        top: '10px', // Adjust top and right values to move the dot
                        right: '10px', // Adjust top and right values to move the dot
                    },
                }}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    const [schedulingTabSelected, setSchedulingTabSelected] = useState("");
    function handleScheduleToggleItemClick(_item){
        schedulingTabSelected === _item ? setSchedulingTabSelected("") : setSchedulingTabSelected(_item)
    }

    const [tabValue, setTabValue] = useState("dates");
    const handleTabValueChange = (event, newValue) => {
        console.log("handleTabValueChange");
        setTabValue(newValue);
    };
    useEffect(() => {
        console.log("tabValue : "+tabValue);
    }, [tabValue]);

    const [ timeOptionChecked, setTimeOptionChecked ] = useState(false);
    function handleTimeOptionCheckedChange(){

        if (timeOptionChecked){
            //they are unselecting the time component
            //so clear any existing update values
            setUpdatedBeginTime(null);
            setUpdatedEndTime(null);
        } else {
            //they are selecting the time component


        }
        setTimeOptionChecked(!timeOptionChecked);
    }

    const [ eventDaysCount, setEventDaysCount ] = useState(0);
    const [ eventDaysStartingOnDay, setEventDaysStartingOnDay ] = useState('');
    function handleEventDaysCountChange(e){
        setEventDaysCount(e.target.value);
    }
    function handleEventDaysStartingOnChange(event){
        setEventDaysStartingOnDay(event.target.value);
    }




    return (

        <div className="dates-times-selection-container" >
            {/* show the header */}
            <div
                className="dates-times-selection-header"
            >
                <div className="dates-times-selection-header-text">
                    <h5>Event Dates / Days</h5>
                </div>
                <div className="dates-times-selection-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        onClick={handlePromptChangeDatesClose}
                    />
                    <Chip
                        sx={updateChipStyle}
                        label="Save"
                        size="small"
                        onClick={handlePromptChangeDatesOK}
                    />
                </div>
            </div>


            {/* show the calendar inputs */}

            <div className="supplies-list-items-container">
                <Grid container spacing={2} direction="column">

                    <Grid item >
                        <TabContext sx={{ position:'relative'}} value={tabValue}>
                            <Box
                                sx={{
                                    width: '350px',
                                    position: 'fixed',
                                    marginTop: '-5px',
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                    backdropFilter: 'blur(6px)',
                                    zIndex:'2'
                                }}
                            >
                                <TabList
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabValueChange}
                                    aria-label="Event Dates / Days"
                                >
                                    <Tab
                                        label={datesCount === 1 ? `1 Date` : `${datesCount} Dates`}
                                        value="dates"
                                    />
                                    <Tab
                                        label={eventDaysCount === 1 ? `1 Day` : `${eventDaysCount} Days`}
                                        value="days"
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="dates" sx={{ padding:'50px 10px 10px 10px'}}>

                                <Typography color="text.secondary" sx={{marginBottom:'20px'}}>
                                    Click on the calendar icon to select the begin date and end date.
                                </Typography>

                                <Grid item key="begin_date" sx={{marginBottom:'20px'}}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        id="event_begin_date_picker"
                                        label="Begin Date"
                                        defaultValue={updatedBeginDate}
                                        value={updatedBeginDate}
                                        onChange={handleUpdatedBeginDate}
                                    />
                                </Grid>
                                <Grid item key="end_date" sx={{marginBottom:'20px'}}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        id="event_end_date_picker"
                                        label="End Date"
                                        defaultValue={updatedEndDate}
                                        value={updatedEndDate}
                                        minDate={updatedBeginDate}
                                        onChange={handleUpdatedEndDate}
                                    />
                                </Grid>

                                <Grid item sx={{marginBottom:'20px'}} className="dates-times-selection-times-text">
                                    Is this an Event with Begin and End times?
                                    If so, choose the start time and end time.
                                </Grid>

                                <Grid item
                                      sx={{marginBottom:'20px', textAlign:'right'}}
                                      className="dates-times-selection-times-text">
                                    <Switch
                                        checked={timeOptionChecked}
                                        onChange={handleTimeOptionCheckedChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>

                                {
                                    timeOptionChecked ? (
                                        <>
                                            <Grid item key="begin_time" sx={{marginBottom:'20px'}}>
                                                <TimePicker
                                                    sx={{ width: '100%' }}
                                                    id="event_begin_time_picker"
                                                    label="Begin Time"
                                                    value={beginTimeValue}
                                                    onChange={handleUpdatedBeginTime}
                                                />
                                            </Grid>
                                            <Grid item key="end_time" sx={{marginBottom:'20px'}}>
                                                <TimePicker
                                                    sx={{ width: '100%' }}
                                                    id="event_end_time_picker"
                                                    label="End Time"
                                                    value={endTimeValue}
                                                    onChange={handleUpdatedEndTime}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }


                                {/*  show the Calendar with the highlighted date(s)  */}
                                {/*
                                <div style={{marginTop: '20px'}}>
                                    <DateCalendar
                                        showDaysOutsideCurrentMonth
                                        fixedWeekNumber={6}
                                        slots={{
                                            day: ServerDay,
                                        }}
                                        slotProps={{
                                            day: {
                                                highlightedDates, // Pass the highlightedDates array to the ServerDay component
                                            },
                                        }}
                                        readOnly
                                    />
                                </div>
                                */}
                            </TabPanel>
                            <TabPanel value="days" sx={{ padding:'50px 10px 10px 10px'}}>

                                <Typography color="text.secondary" sx={{marginBottom:'20px'}}>
                                    If you just want to build an
                                    Event by days, such as a 5 day trip, select how many days, and optionally, you can set the
                                    day of the week if you know that, for example, an excursion begins on a Thursday.
                                </Typography>

                                <TextField
                                    fullWidth
                                    sx={{marginBottom:'20px'}}
                                    id="event_date_days"
                                    label="Number of Days"
                                    value={eventDaysCount}
                                    onChange={handleEventDaysCountChange}
                                />

                                <FormControl
                                    fullWidth
                                    sx={{marginBottom:'20px'}}
                                >
                                    <InputLabel id="demo-simple-select-label">Starting On Day</InputLabel>
                                    <Select
                                        labelId="event-days-select-label"
                                        id="event_days_select"
                                        value={eventDaysStartingOnDay}
                                        label="Starting On Day"
                                        onChange={handleEventDaysStartingOnChange}
                                    >
                                        <MenuItem value="">Not Selected</MenuItem>
                                        <MenuItem value="Sunday">Sunday</MenuItem>
                                        <MenuItem value="Monday">Monday</MenuItem>
                                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                                        <MenuItem value="Thursday">Thursday</MenuItem>
                                        <MenuItem value="Friday">Friday</MenuItem>
                                        <MenuItem value="Saturday">Saturday</MenuItem>
                                    </Select>
                                </FormControl>

                                <Grid item sx={{marginBottom:'20px'}} className="dates-times-selection-times-text">
                                    Is this an Event with Begin and End times?
                                    If so, choose the start time and end time.
                                </Grid>

                                <Grid item
                                      sx={{marginBottom:'20px', textAlign:'right'}}
                                      className="dates-times-selection-times-text">
                                    <Switch
                                        checked={timeOptionChecked}
                                        onChange={handleTimeOptionCheckedChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>

                                {
                                    timeOptionChecked ? (
                                        <>
                                            <Grid item key="begin_time" sx={{marginBottom:'20px'}}>
                                                <TimePicker
                                                    sx={{ width: '100%' }}
                                                    id="event_begin_time_picker"
                                                    label="Begin Time"
                                                    value={beginTimeValue}
                                                    onChange={handleUpdatedBeginTime}
                                                />
                                            </Grid>
                                            <Grid item key="end_time" sx={{marginBottom:'20px'}}>
                                                <TimePicker
                                                    sx={{ width: '100%' }}
                                                    id="event_end_time_picker"
                                                    label="End Time"
                                                    value={endTimeValue}
                                                    onChange={handleUpdatedEndTime}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }


                            </TabPanel>
                        </TabContext>
                    </Grid>

                </Grid>
            </div>

            {/*
            <div className="dates-times-selection-dates-container">
                <Grid spacing={2} direction="column" sx={{padding: '0 10px'}}>


                    <div className="lodging-suggestions-add-list-item-footer-container">

                        <Typography variant="body2">
                            You can use Days or Dates (or both) to structure your Event.
                        </Typography>
                        <Typography color="text.secondary">
                            If you know the Dates then select those from the calendar. If you just want to build an
                            Event by days, such as a 5 day trip, select how many days, and optionally, you can set the
                            day of the week if you know that, for example, an excursion begins on a Thursday and ends on
                            Sunday.
                        </Typography>
                        <div
                            style={{backgroundColor: schedulingTabSelected === "Dates" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-dates-container"
                            onClick={()=>handleScheduleToggleItemClick('Days')}
                        >
                            <Badge
                                badgeContent={0}
                                color="badge"
                                showZero
                            >
                                Days
                            </Badge>
                        </div>
                        <div
                            style={{backgroundColor: schedulingTabSelected === "Dates" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-dates-container"
                            onClick={()=>handleScheduleToggleItemClick('Dates')}
                        >
                            <Badge
                                badgeContent={0}
                                color="badge"
                                showZero
                            >
                                Dates
                            </Badge>
                        </div>
                    </div>



                    <Grid item key="begin_date" sx={{marginBottom:'20px'}}>
                        <DatePicker
                            sx={{ width: '100%' }}
                            id="event_begin_date_picker"
                            label="Begin Date"
                            defaultValue={updatedBeginDate}
                            onChange={handleUpdatedBeginDate}
                        />
                    </Grid>
                    <Grid item key="end_date" sx={{marginBottom:'20px'}}>
                        <DatePicker
                            sx={{ width: '100%' }}
                            id="event_end_date_picker"
                            label="End Date"
                            defaultValue={updatedEndDate}
                            minDate={updatedBeginDate}
                            onChange={handleUpdatedEndDate}
                        />
                    </Grid>

                    <Grid item sx={{marginBottom:'20px'}} className="dates-times-selection-times-text">
                        Is this an Event with Begin and End times?
                        If so, choose the start time and end time.
                    </Grid>

                    <Grid item key="begin_time" sx={{marginBottom:'20px'}}>
                        <TimePicker
                            sx={{ width: '100%' }}
                            id="event_begin_time_picker"
                            label="Begin Time"
                            value={beginTimeValue}
                            onChange={(newValue) => setBeginTimeValue(newValue)}
                        />
                    </Grid>
                    <Grid item key="end_time" sx={{marginBottom:'20px'}}>
                        <TimePicker
                            sx={{ width: '100%' }}
                            id="event_end_time_picker"
                            label="End Time"
                            value={endTimeValue}
                            onChange={(newValue) => setEndTimeValue(newValue)}
                        />
                    </Grid>

                </Grid>

            </div>
            */}


            {/*  show the Interesting  */}
            <div style={{marginTop: '20px'}}>
                <Paper elevation={2} sx={{margin: '20px'}}>
                    <Card>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={datesTimesHeaderImage}
                            title="Date and Times"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h7" component="div">
                                Flexibility at it's core
                            </Typography>

                            <Typography gutterBottom variant="body2" color="text.secondary">
                                Different types of Events require flexibility in creating an Itinerary.
                            </Typography>

                            <Typography gutterBottom variant="body2" color="text.secondary">
                                <strong>Important</strong> Selecting dates is needed before adding many of the Event
                                itinerary items. Note however, that choosing Event times doesn't
                                have any impact on the Itinerary. It is merely for overall Event titles.
                            </Typography>

                            <Typography gutterBottom variant="body2" color="text.secondary">
                                <strong>Multiple Day Events</strong>. In the simplest form, just choose beginning
                                dates and ending dates.
                            </Typography>

                            <Typography gutterBottom variant="body2" color="text.secondary">
                                <strong>Same Day Events</strong>. Dinner parties, birthdays, beach days -
                                select the date and then choose the beginning time and ending time.
                            </Typography>

                            <Typography gutterBottom variant="body2" color="text.secondary">
                                <strong>Multiple days with time</strong>. Select the beginning date and time,
                                and then select the ending date and time. For example, maybe a 48 hr Event that
                                begins on Tuesday at 8am and goes until Thursday at 8am.
                            </Typography>
                        </CardContent>
                    </Card>

                </Paper>
            </div>

        </div>
    )

}

export default DatesTimesContent;
