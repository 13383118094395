
import React from "react";

import FeaturesContent from "./FeaturesContent";
import {Grid} from "@mui/material";




function Features() {


    return (

        <Grid container spacing={12}>
            <FeaturesContent />
        </Grid>

    )

}

export default Features;
