import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import {Card, CardContent, CardMedia, Chip, Grid, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import {cancelChipStyle, cancelGradientStyle} from "../../ButtonStyle/Cancel";
import {addGradientStyle} from "../../ButtonStyle/Add";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import FlightCard from "../Flights/FlightCard";
import Typography from "@mui/material/Typography";
import './flights.css';
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import {selectedAttendeeStyle, unselectedAttendeeStyle} from "../../ButtonStyle/AttendeeNames";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";


const groupFlightsByDay = (flights) => {
    return flights.reduce((groups, flight) => {
        const date = dayjs(flight.depart_timestamp).format('YYYY-MM-DD'); // Group by formatted date
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(flight);
        return groups;
    }, {});
};

const calculateTotalTime = (flightsForDay) => {
    const firstFlight = flightsForDay[0]; // First flight of the day
    const lastFlight = flightsForDay[flightsForDay.length - 1]; // Last flight of the day

    const startTime = dayjs(firstFlight.depart_timestamp);
    const endTime = dayjs(lastFlight.arrive_timestamp);

    // Total time in minutes between the first departure and the last arrival
    const totalMinutes = endTime.diff(startTime, 'minute');

    return totalMinutes;
};

function FlightsList(props) {

    const navigate = useNavigate();
    const _global_map_ref = useRef();

    const { dataArray, setPlanningFlights } = useContext(PAContext);
    const { Account, Planning } = dataArray;

    const [eventFlights, setEventFlights] = useState([]);
    const [filteredFlights, setFilteredFlights] = useState([]);

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const attendeesWithFlightsCounts = Planning.attendees.map((attendee) => {
        const flightCount = Planning.flights.reduce((count, flight) =>
            count + (flight.attendees.some(a => a.id === attendee.id) ? 1 : 0), 0
        );
        console.log("----------------- flight count : "+ flightCount);
        return { ...attendee, flightCount };
    });
    const attendeesWithFlights = attendeesWithFlightsCounts.filter(attendee => attendee.flightCount > 0);
    const attendeesWithoutFlights = attendeesWithFlightsCounts.filter(attendee => attendee.flightCount === 0);
    attendeesWithFlights.sort((a, b) => b.flightCount - a.flightCount);
    attendeesWithoutFlights.sort((a, b) => a.nickname.localeCompare(b.nickname));
    const sortedAttendees = [...attendeesWithFlights, ...attendeesWithoutFlights];

    const filterFlights = () => {
        console.log("filterFlights for : "+selectedAttendee);
        console.log("Planning.flights : "+JSON.stringify(Planning.flights, null, '\t'));

        const filteredItems = Planning.flights.filter(flight =>
            flight.attendees.some(attendee => attendee.id === selectedAttendee)
        );

        console.log("setting the filterFlights to : "+filteredItems.length);

        setEventFlights(filteredItems);
        setFilteredFlights(filteredItems); // Initialize with all filtered flights
    };

    const handleChipClick = (_attendee_id) => {
        console.log("Show flights for : "+_attendee_id);
        setSelectedAttendee(_attendee_id);
    }

    const [anchorFlightsListMenu, setAnchorFlightsListMenu] = useState(null);
    const handleFlightsListMenuOpenMenu = (event) => {
        setAnchorFlightsListMenu(event.currentTarget);
    };
    const handleFlightsListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorFlightsListMenu(event.currentTarget);

        if (dataId === "searchAndBook") {
            navigate('/Planner/Flights/SearchAndBook');
        } else if (dataId === "search"){
            navigate('/Planner/Flights/Search');
        } else if (dataId === "enterDetails"){
            navigate('/Planner/Flights/EnterDetails');
        }
    };
    const handleFlightsListMenuClose = () => {
        setAnchorFlightsListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    useEffect(() => {
        if (Planning.flights) {
            filterFlights();
        }

    }, [Planning.flights, selectedAttendee]);

    const updateFlightDetails = (updatedFlight) => {
        // Find the index of the updated Flight in eventFlights
        const flightIndex = eventFlights.findIndex((flight) => flight.id === updatedFlight.id);

        if (flightIndex !== -1) {
            // If the flight is found, update it in the eventFlights array
            const updatedEventFlightsArray = [...eventFlights];
            updatedEventFlightsArray[flightIndex] = updatedFlight;

            setEventFlights(updatedEventFlightsArray);

            //this updates the flight at the PAContext level
            setPlanningFlights(Planning["id"], updatedEventFlightsArray);

            console.log("updatedEventFlights");
        }

    };

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        return '';
    }

    const formatTimestamp = (timestamp, timezone) => {
        const date = new Date(timestamp);

        // Use Intl.DateTimeFormat to format the date as "Wed, Jun 5"
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            weekday: 'short',   // "Wed"
            month: 'short',     // "Jun"
            day: 'numeric'      // "5"
        });

        return formatter.format(date);
    };


    const flightsByDay = groupFlightsByDay(filteredFlights);


    return (
        <div className="flights-list-container" >

            {/* show the header */}
            <div className="flights-list-header" >
                <div className="flights-list-header-text">
                    <h5>Flights List</h5>
                </div>
                <div className="flights-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize:'16px'}} />
                        }
                        size="small"
                        onClick={handleFlightsListMenuOpenMenu}
                    />
                    <Menu
                        sx={{ width: 320, maxWidth: '100%' }}
                        anchorEl={anchorFlightsListMenu}
                        id="library-menu"
                        open={Boolean(anchorFlightsListMenu)}
                        onClose={handleFlightsListMenuClose}

                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList
                            dense
                            sx={{ width: 320, maxWidth: '100%' }}
                        >
                            {
                                Account.role === "master_admin" ? (
                                    <MenuItem
                                        data-id="searchAndBook"
                                        onClick={handleFlightsListMenuClick}>
                                        <ListItemText>Search & Book</ListItemText>
                                    </MenuItem>
                                ) : (
                                    <></>
                                )
                            }

                            <MenuItem
                                data-id="search"
                                onClick={handleFlightsListMenuClick}>
                                <ListItemText>Add By Search</ListItemText>
                            </MenuItem>
                            <MenuItem
                                data-id="enterDetails"
                                onClick={handleFlightsListMenuClick}>
                                <ListItemText>Enter Flight Details</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>

            {/* Attendee List */}
            <div className="flights-list-attendee-list-container">
                {sortedAttendees.length > 0 ? (
                    sortedAttendees.map((attendee, index) => (
                        <Badge
                            key={index}
                            badgeContent={attendee.flightCount > 0 ? attendee.flightCount : null}
                            sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'white',
                                    border: '1px solid #ddd',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '50%',
                                    top: '10px',
                                    right: '10px',
                                },
                            }}
                        >
                            <Chip
                                sx={selectedAttendee === attendee.id ? selectedAttendeeStyle : unselectedAttendeeStyle}
                                label={capitalizeFirstLetter(attendee.nickname)}
                                clickable
                                onClick={() => handleChipClick(attendee.id)}
                                style={{margin: '0.5rem'}}
                            />
                        </Badge>
                    ))
                ) : (
                    <div style={{padding: '20px'}}>
                        <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                        <Typography variant="caption">Need to add some Attendees first</Typography>
                    </div>
                )}
            </div>

            {/* show the list of flights */}
            <div className="flights-list-items-container">

                {/* Check if there are no flights */}
                {
                    filteredFlights.length === 0 ? (
                        <Typography variant="body2" sx={{
                            textAlign: 'center',
                            mt: 2,  // Adds space on the top (use 2, 3, etc. for different spacing)
                            mb: 2,  // Adds space on the bottom
                        }}>
                            No flights for {Planning.attendees.find((_att) => _att.id === selectedAttendee?.id)?.nickname || 'this Attendee'}
                        </Typography>
                    ) : (
                        Object.keys(flightsByDay)
                            .map((day) => {
                            const flightsForDay = flightsByDay[day].sort((a, b) => dayjs(a.depart_timestamp).diff(dayjs(b.depart_timestamp)))  // Sort flights by time;

                            // Calculate total time spent from first departure to last arrival for this day
                            const totalMinutes = calculateTotalTime(flightsForDay);
                            const totalHours = Math.floor(totalMinutes / 60);
                            const remainingMinutes = totalMinutes % 60;

                            return (
                                <Card key={day} sx={{ mb: 3 }}>
                                    <CardContent>
                                        {/* Display the day and date */}
                                        <Typography variant="h6" color="primary">
                                            {dayjs(day).format('dddd, MMM D')}  {/* Example: "Wednesday, Jun 5" */}
                                        </Typography>

                                        {/* Display total time in air and airports */}
                                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2, textAlign: 'right' }}>
                                            Total: {totalHours} h {remainingMinutes} m
                                        </Typography>

                                        {/* Render flights for that day, sorted by depart_timestamp */}
                                        {flightsForDay
                                            .sort((a, b) => dayjs(a.depart_timestamp).diff(dayjs(b.depart_timestamp)))  // Sort flights by time
                                            .map((flightDetails, index) => {
                                                const nextFlight = flightsForDay[index + 1];
                                                let layoverTime = null;

                                                if (nextFlight) {
                                                    // Calculate arrival and next departure times
                                                    const arrivalTime = dayjs(flightDetails.arrive_timestamp);
                                                    const nextDepartureTime = dayjs(nextFlight.depart_timestamp);

                                                    // Calculate layover time in minutes
                                                    layoverTime = nextDepartureTime.diff(arrivalTime, 'minute');
                                                }

                                                const layoverHours = Math.floor(layoverTime / 60);
                                                const layoverMinutes = layoverTime % 60;

                                                return (
                                                    <Grid key={index} item sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        {/* Flight details */}
                                                        <FlightCard
                                                            flight={flightDetails}
                                                            updateFlightDetails={updateFlightDetails}
                                                        />

                                                        {/* Conditionally render layover information if there's a next flight */}
                                                        {nextFlight && layoverTime !== null && (
                                                            <>
                                                                <Divider sx={{ my: 2 }} />
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    sx={{ mt: 1, textAlign: 'center' }}
                                                                >
                                                                    Layover:
                                                                    {layoverHours > 0 && ` ${layoverHours} h`}
                                                                    {layoverMinutes > 0 && ` ${layoverMinutes} m`}
                                                                </Typography>
                                                                <Divider sx={{ my: 2 }} />
                                                            </>
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                    </CardContent>
                                </Card>
                            );
                        })
                    )
                }
            </div>

        </div>
    )

}

export default FlightsList;
