import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import dayjs from "dayjs";


function WeatherDetails(props) {

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    const [weatherData, setWeatherData] = useState(Planning.weather);

    useEffect(()=> {
        console.log("the new weather data : "+JSON.stringify(Planning.weather, null, '\t'));

        if (Planning.weather){
            setWeatherData(Planning.weather);
        }
    }, [Planning.weather])


    return (

        <>
            {
                weatherData ? (
                    <div>
                        {weatherData.map((location, index) => (

                            <div key={index}>
                                {/* Display the day's weather data here */}
                                <p>Location: {location.city} {location.state}</p>

                                {
                                    location['report']? (
                                        location['report'].days.map((day, day_index)=> (
                                            <div key={day_index}>
                                                <p>Date: {dayjs(day.datetime, 'YYYY-MM-DD').format('dddd MMM D')}</p>
                                                <p>Icon: {day.icon}</p>
                                                <p>Conditions: {day.conditions}</p>
                                                <p>Description: {day.description}</p>
                                            </div>

                                        ))
                                    ) : (
                                        <></>
                                    )

                                }

                            </div>
                        ))}
                    </div>
                ) : (
                    <div>No Weather Data</div>
                )
            }
        </>
    )


    function getEventWeatherForecastData(_search_data){
        console.log("getEventWeatherForecastData : "+JSON.stringify(_search_data, null, '\t'));


        const _visualCrossingBase = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/";
        const _visualCrossingTimelineService = "timeline/";
        const _search_location = (_search_data.location_type==="address" ? _search_data.location+"/" : (_search_data.latitude+","+_search_data.longitude)+"/");
        const _search_begin_date = _search_data.begin_date+"/";
        const _search_end_date = _search_data.end_date;

        let _search_string = "";

        console.log("days until : "+Math.ceil(dayjs(Planning.begin_date).diff(dayjs(), "days", true)));
        console.log("days after : "+Math.ceil(dayjs().diff(dayjs(Planning.end_date), "days", true)));

        let _in_event = false;
        if (Math.ceil(dayjs(Planning.begin_date).diff(dayjs(), "days", true)) <= 0
            && Math.ceil(dayjs().diff(dayjs(Planning.end_date), "days", true)) < 1) {
            _in_event = true;
        }

        if (_in_event) {
            //get hourly data
            console.log("Getting the hourly data : in Event");
            _search_string = _visualCrossingBase+_visualCrossingTimelineService+_search_location+_search_begin_date+_search_end_date
                +"?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&unitGroup="+_search_data.unit_group;
        } else {
            //just get daily numbers
            console.log("Getting the daily data");
            _search_string =_visualCrossingBase+_visualCrossingTimelineService+_search_location+_search_begin_date+_search_end_date
                +"?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&include=days&unitGroup="+_search_data.unit_group;
        }

        fetch(_search_string.replaceAll(" ", "%20"), {
            method: 'GET',
            headers: {

            },

        }).then(response => {

            if (!response.ok) {

                throw response; //check the http response code and if isn't ok then throw the response as an error
            }

            return response.json(); //parse the result as JSON

        }).then(response => {
            //response now contains parsed JSON ready for use
            console.log("the weather response : "+JSON.stringify(response));

            //successful
            props.setWeatherReportReceived(true);
            props.setWeatherReportReceivedSuccessOpen(true);

            setWeatherData(response);

        }).catch((errorResponse) => {

            console.log("error response : "+errorResponse);
            console.log("error response code : "+errorResponse.status);

            if (errorResponse.text) { //additional error information
                errorResponse.text().then( errorMessage => {
                    //errorMessage now returns the response body which includes the full error message
                    console.log("Weather error : "+errorMessage);

 //                   showWeatherDataErrorForEventWeather(_container);

                })
            } else {
                //no additional error information
            }
        });

    }


}

export default WeatherDetails;
