import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";

import {PAContext} from "../../../Services/PAContext";

import {Container} from "@mui/material";
import LeftSideMenuResponsive from "./LeftSideMenuResponsive";
import CenterContentHome from "./CenterContentHome";
import CenterContentEvents from "./CenterContentEvents";
import EventOverview from "../Event/Overview/EventOverview"





function Dashboard(props){
    console.log("the dashboard! : "+JSON.stringify(props));

    const { dataArray } = useContext(PAContext);

    const [viewingEventGroup, setViewingEventGroup] = useState(props.viewingEventGroup);
    const [viewingEventId, setViewingEventId] = useState("");

    useEffect(() => {
        setViewingEventGroup(props.viewingEventGroup);
    }, [props.viewingEventGroup]);


    return (

        <Container sx={{display: 'flex', padding: "100px 0 0 0 !important"}}>

            {
                dataArray.authenticated ? (

                    <>
                        <LeftSideMenuResponsive
                            viewingEventGroup={viewingEventGroup}
                            setViewingEventGroup={setViewingEventGroup}/>

                        <Routes>
                            <Route path="/" element={<CenterContentHome viewingEventGroup={viewingEventGroup} />} />

                            {/* Dashboard child routes */}
                            <Route path="Events" element={
                                <CenterContentEvents
                                    type="all"
                                    setViewingEventId={setViewingEventId}/>
                            } />
                            <Route path="Events/Current" element={
                                <CenterContentEvents
                                    type="current"
                                    setViewingEventId={setViewingEventId}/>
                            } />
                            <Route path="Events/Past" element={
                                <CenterContentEvents
                                    type="past"
                                    setViewingEventId={setViewingEventId}/>
                            } />
                            <Route path="Events/ByCategory" element={
                                <CenterContentEvents
                                    type="category"
                                    viewingEventGroup={viewingEventGroup}
                                    setViewingEventId={setViewingEventId}
                                />} />

                            <Route path="Event" element={
                                <EventOverview viewingEventId={viewingEventId}/>}
                            />

                        </Routes>
                    </>

                ) : (
                    <div>Need to Login</div>
                )
            }


        </Container>


    )


}
export default Dashboard;
