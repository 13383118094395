
import React from 'react';






function CreateEventContent() {


    return (

        <></>

    )

}

export default CreateEventContent;
