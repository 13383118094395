import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Chip,
    Grid,
    Slider
} from "@mui/material";
import {cancelGradientStyle} from "../../ButtonStyle/Cancel";

import './lodging.css';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import StarIcon from '@mui/icons-material/Star';
import {addGradientStyle} from "../../ButtonStyle/Add";
import {useNavigate} from "react-router-dom";


function LodgingSuggestions(props){

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { SuggestionsLodging } = dataArray;

    const gridContainerRef = useRef(null);
    const gridItemRefs = {};

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    const [priceRangeLow, setPriceRangeLow] = useState(0);
    const [priceRangeHigh, setPriceRangeHigh] = useState(1000000);
    const [pricePoint, setPricePoint] = useState([0, 1000000]);
    const [lodgingFilterCount, setLodgingFilterCount] = useState(SuggestionsLodging.length);

    useEffect(()=> {

        //find the min and max pricing

        let highestPrice = -1000000; // Initialize as negative infinity
        let lowestPrice = 1000000;   // Initialize as positive infinity

        // Iterate through the array of hotels
        for (const lodging of SuggestionsLodging) {

            console.log("lodging : "+JSON.stringify(lodging, null, '\t'));

            const _item_check_in_date = dayjs(lodging.check_in_date);
            const _item_check_out_date = dayjs(lodging.check_out_date);
            const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');
            const _total_price = parseFloat(lodging['accommodation'].cheapest_rate_total_amount);
            const _item_per_night_rate = _total_price / _item_num_nights;

            console.log("_total_price : "+_total_price);

            if (!isNaN(_total_price)) {
                // Check if the price is a valid number
                if (_item_per_night_rate > highestPrice) {
                    highestPrice = _item_per_night_rate; // Update the highest price
                }
                if (_item_per_night_rate < lowestPrice) {
                    lowestPrice = _item_per_night_rate;  // Update the lowest price
                }
            }
        }

        setPriceRangeLow(lowestPrice);
        setPriceRangeHigh(highestPrice);
        setPricePoint([lowestPrice, highestPrice]);
        props.setViewSuggestionLodgingMarkersFilter([0,1000000]);

    }, []);

    useEffect(() => {
        console.log(" ----------------------- SuggestionsLodging changed");

        //find the min and max pricing

        let highestPrice = -1000000; // Initialize as negative infinity
        let lowestPrice = 1000000;   // Initialize as positive infinity

        // Iterate through the array of hotels
        for (const lodging of SuggestionsLodging) {

            console.log("lodging : "+JSON.stringify(lodging, null, '\t'));

            const _item_check_in_date = dayjs(lodging.check_in_date);
            const _item_check_out_date = dayjs(lodging.check_out_date);
            const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');
            const _total_price = parseFloat(lodging['accommodation'].cheapest_rate_total_amount);
            const _item_per_night_rate = _total_price / _item_num_nights;

            console.log("_total_price : "+_total_price);

            if (!isNaN(_total_price)) {
                // Check if the price is a valid number
                if (_item_per_night_rate > highestPrice) {
                    highestPrice = _item_per_night_rate; // Update the highest price
                }
                if (_item_per_night_rate < lowestPrice) {
                    lowestPrice = _item_per_night_rate;  // Update the lowest price
                }
            }
        }

        setPriceRangeLow(lowestPrice);
        setPriceRangeHigh(highestPrice);
        setPricePoint([lowestPrice, highestPrice]);
        props.setViewSuggestionLodgingMarkersFilter([0,1000000]);

    }, [SuggestionsLodging]);

    const handleSliderChange = (event, newValue) => {

        setPricePoint(newValue);
        props.setViewSuggestionLodgingMarkersFilter(newValue);

        const _filtered_count = SuggestionsLodging
            .filter((_item)=>{

                const _item_check_in_date = dayjs(_item.check_in_date);
                const _item_check_out_date = dayjs(_item.check_out_date);
                const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                const _item_price = _item['accommodation'].cheapest_rate_total_amount;
                const _item_per_night_rate = _item_price / _item_num_nights;

                return (_item_per_night_rate >= newValue[0] && _item_per_night_rate <= newValue[1]);

            });
        setLodgingFilterCount(_filtered_count.length);

    };
//    const valueLabelFormat = (pricePoint) => `$${pricePoint.toFixed(2)}`;
    const valueLabelFormat = (pricePoint) => pricePoint.toFixed(2);


    const shadesOfGreen = [
        '#00522a', // Darkest Green for one star
        '#008c3a', // Dark Green for two stars
        '#00bf49', // Moderate Green for three stars
        '#4edb73', // Light Green for four stars
        '#a1f7a4', // Lightest Green for five stars
    ];

    function CustomRating({ value }) {
        return (
            <div style={{width: '100%', textAlign: 'right'}}>
                {Array.from({ length: 5 }).map((_, index) => (
                    <StarIcon
                        key={index}
                        sx={{
                            color: index < value ? shadesOfGreen[index] : '#aaa',
                            fontSize: '16px',
                        }}
                    />
                ))}
            </div>
        );
    }

    function addLodgingFromDuffel(_lodging_id){
        console.log("addLodgingFromDuffel : "+_lodging_id);

        props.setCurrentFeature("Lodging");
        const foundLodging = SuggestionsLodging.find(_lodging => _lodging.id === _lodging_id);
        props.setLodgingFromSuggestions(foundLodging);
        props.setShowEventItemConfig(true);

        navigate('/Planner/Lodging/AddFromSuggestions');

    }

    useEffect(()=> {
        console.log("something selected from map : "+props.lodgingSuggestionNameFromMap);
        //bring it into the viewable area

        console.log("gridItemRefs : "+gridItemRefs[props.lodgingSuggestionNameFromMap]);
        console.log("gridContainerRef.current : "+gridContainerRef.current);

        if (gridItemRefs[props.lodgingSuggestionNameFromMap] && gridContainerRef.current) {
            try {
                console.log("gridItemRefs[props.lodgingSuggestionNameFromMap] : " + gridItemRefs[props.lodgingSuggestionNameFromMap]);

                gridItemRefs[props.lodgingSuggestionNameFromMap].scrollIntoView({
                    behavior: 'smooth', // You can use 'auto' for instant scrolling
                    block: 'start',
                });

            } catch (e){
                console.log(e);
            }
        }

    }, [props.lodgingSuggestionNameFromMap]);


    return (

        <div className="lodging-suggestions-list-container" >
            {/* show the header */}
            <div
                className="lodging-suggestions-list-header"
            >
                <div className="lodging-suggestions-list-header-text">
                    <h5>Lodging Suggestions {lodgingFilterCount===0?'':lodgingFilterCount}</h5>
                </div>

                <div className="lodging-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelGradientStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptChangeClose}
                    />

                </div>
            </div>

            {/*
            <div className="lodging-suggestions-list-pricing-disclaimer">
                <Typography variant="caption" display="block" gutterBottom>
                    * Any pricing is based on a 5 night stay for 2 weeks from today.
                    Once you have chosen Event dates then exact pricing will be shown.
                </Typography>
            </div>
            */}

            {
                SuggestionsLodging.length > 0 ? (
                    <>
                        <div className="lodging-suggestions-price-range-display-container">
                            <span className="lodging-suggestions-price-range-display-low">{pricePoint[0].toFixed(0)}</span>
                            <span className="lodging-suggestions-price-range-display-high"> - {pricePoint[1].toFixed(0)}</span>
                        </div>


                        <div className="lodging-suggestions-price-range-container">
                            <Slider
                                sx={{display:'flex',width:'80%',marginLeft:'auto', marginRight:'auto'}}
                                size="small"
                                value={pricePoint}
                                onChange={handleSliderChange}
                                min={priceRangeLow} // Set the minimum price
                                max={priceRangeHigh} // Set the maximum price
                                valueLabelDisplay="on" // Display labels for the selected values
                                valueLabelFormat={valueLabelFormat}
                                aria-labelledby="range-slider"
                            />
                        </div>
                    </>

                ) : (
                    <></>
                )
            }

            {/* show the list of lodging suggestions */}
            <div ref={gridContainerRef} className="lodging-suggestions-list-items-container">
                <Grid container spacing={2} direction="column">
                    {
                        SuggestionsLodging.length > 0 ? (


                            SuggestionsLodging
                                .filter((_item)=>{

                                    const _item_check_in_date = dayjs(_item.check_in_date);
                                    const _item_check_out_date = dayjs(_item.check_out_date);
                                    const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                                    const _item_price = _item['accommodation'].cheapest_rate_total_amount;
                                    const _item_per_night_rate = _item_price / _item_num_nights;

                                    return (_item_per_night_rate >= pricePoint[0] && _item_per_night_rate <= pricePoint[1]);

                                })
                                .sort((_item1, _item2) => {

                                    // Sort in ascending order based on per_night_rate
                                    const _item1_check_in_date = dayjs(_item1.check_in_date);
                                    const _item1_check_out_date = dayjs(_item1.check_out_date);
                                    const _item1_num_nights = _item1_check_out_date.diff(_item1_check_in_date, 'days');
                                    const _item1_price = _item1['accommodation'].cheapest_rate_total_amount;
                                    const _item1_per_night_rate = _item1_price / _item1_num_nights;

                                    const _item2_check_in_date = dayjs(_item2.check_in_date);
                                    const _item2_check_out_date = dayjs(_item2.check_out_date);
                                    const _item2_num_nights = _item2_check_out_date.diff(_item2_check_in_date, 'days');
                                    const _item2_price = _item2['accommodation'].cheapest_rate_total_amount;
                                    const _item2_per_night_rate = _item2_price / _item2_num_nights;

                                    return _item1_per_night_rate - _item2_per_night_rate;
                                })
                                .map((_item, index) => {

//                                    console.log("the lodging : "+JSON.stringify(_item, null, '\t'));

                                    const _item_check_in_date = dayjs(_item.check_in_date);
                                    const _item_check_out_date = dayjs(_item.check_out_date);
                                    const _item_num_nights = _item_check_out_date.diff(_item_check_in_date, 'days');

                                    const _item_price = _item['accommodation'].cheapest_rate_total_amount;
                                    const _item_currency = _item['accommodation'].cheapest_rate_currency;
                                    const _item_per_night_rate = _item_price / _item_num_nights;

                                    return (
                                        <Grid
                                            item
                                            key={_item['id']}
                                            ref={(ref) => (gridItemRefs[_item['id']] = ref)}
                                            sx={{display:'flex'}}>

                                            <Card
                                                className="lodging-suggestions-list-item"
                                            >
                                                <CardContent sx={{position: 'relative'}}>
                                                    <div className="lodging-suggestions-list-item-header-chip-holder">
                                                        <Chip
                                                            sx={addGradientStyle}
                                                            label="Add"
                                                            size="small"
                                                            onClick={()=>addLodgingFromDuffel(_item['id'])}
                                                        />
                                                    </div>

                                                </CardContent>
                                                {
                                                    _item['accommodation']['photos'][0] === undefined ? (
                                                        <></>
                                                    ) : (
                                                        <CardMedia
                                                            sx={{ height: 100 }}
                                                            image={_item['accommodation']['photos'][0].url}
                                                            title="green iguana"
                                                        />
                                                    )
                                                }
                                                <CardContent>
                                                    <Typography variant="body2">
                                                        {_item['accommodation'].name}
                                                    </Typography>
                                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                        {_item['accommodation']['location']['address'].line_one}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Chip
                                                        label={`${_item_per_night_rate.toFixed(2)} per night ${_item_currency}`}
                                                        size="small"
                                                        variant={"outlined"}
                                                        color="success"
                                                        sx={{
                                                            boxShadow: 2,
                                                            borderRadius: 2,
                                                            textAlign: 'center',
                                                            fontWeight: '700',
                                                        }}
                                                    />
                                                    {
                                                        _item['accommodation']['ratings'] === null || _item['accommodation']['ratings'].length === 0 ? (
                                                            <div style={{flex:'1',textAlign:'right', fontSize:'12px'}}>No Ratings</div>
                                                        ) : (
                                                            <CustomRating value={_item['accommodation']['ratings'][0].value} />
                                                        )
                                                    }
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                })
                        ):(
                            <div style={{padding: '20px', textAlign:'center'}} >
                                {/* place is required and preferably some real dates */}
                                <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                                <Typography variant="subtitle2">No Lodging suggestions yet.</Typography>
                                <Typography variant="caption">Once an area has been selected then the Concierge will look for Lodging suggestions.</Typography>
                            </div>
                        )

                    }
                </Grid>

            </div>

        </div>
    )

}

export default LodgingSuggestions;

