import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../../Services/PAContext";

import {Card, CardContent, CardMedia, Container, Grid, Paper} from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";


function EventOverview(props){

    const { dataArray } = useContext(PAContext);
    const { Events, Account } = dataArray;

    const [ workingOnEvent, setWorkingOnEvent ] = useState(null);

    useEffect(() => {

        const _evt = Events.find(event => event.id === props.viewingEventId);

        if (_evt){
            setWorkingOnEvent(_evt);
        } else {
            setWorkingOnEvent(null);
        }

    }, [props.viewingEventId]);

    /*
        this shows the basic EventOverview of a specific Event

        such as the name, dates, cover image, budget, notes, and any Agency details

     */

    const formattedEventDates = () => {

        const _begin_date = new dayjs(workingOnEvent["calendar"].begin_date);
        const _end_date = new dayjs(workingOnEvent["calendar"].end_date);

        if (_end_date.diff(_begin_date, 'day') !== 0){
            //they are different dates
            return (_begin_date.format('MMMM Do') +" - "+_end_date.format('MMMM Do'));
        } else {
            //it is a same day Event
            return (_begin_date.format('MMMM Do YYYY') +"  "+ _begin_date.format('h:mm a') +" - "+_end_date.format('h:mm a'));
        }
    }

    const hostedBy = () => {
        return (
            <>
                {
                    workingOnEvent["host"].map((_host, index) => {
                        console.log("looking for HOST:", _host);
                        const attendee = workingOnEvent["attendees"].find((_att) => _att.id === _host);
                        return (
                            <Typography key={index} variant="subtitle1" color="text.secondary" component="div">
                                {attendee ? attendee.fname.charAt(0).toUpperCase() + attendee.fname.slice(1).toLowerCase() : 'Unknown'}
                            </Typography>
                        );
                    })
                }
            </>
        );
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="sm">
            {workingOnEvent === null ? (
                <div style={{ position: 'relative', padding: '8px' }}>Event Not Found</div>
            ) : (
                <Box style={{ position: 'relative', padding: '8px' }}>
                    <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', mb: 2 }}>
                        <CardMedia
                            component="img"
                            sx={{ width: isMobile ? '100%' : 151 }}
                            image={workingOnEvent["cover_image_thumbnail"]}
                            alt="Event cover"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : 'auto' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    {workingOnEvent["name"]}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {formattedEventDates()}
                                </Typography>
                                {workingOnEvent["notes"] && (
                                    <Typography component="div" variant="h5">
                                        Notes: {workingOnEvent["notes"]}
                                    </Typography>
                                )}
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    Hosted By :
                                </Typography>
                                {hostedBy()}
                            </CardContent>
                        </Box>
                    </Card>

                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {/* Itinerary */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Itinerary"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-calendar"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Itinerary
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Map */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Map"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-map"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Map It
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Attendees */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Attendees"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-user-24"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["attendees"].length} Attendee{workingOnEvent["attendees"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Activities */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Activities"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-list-heart"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["activities"].length} {workingOnEvent["activities"].length === 1 ? 'Activity' : 'Activities'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Lodging */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Lodging"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-bed"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["lodging"].length} Lodging{workingOnEvent["lodging"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Meals */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Meals"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-fork-knife2"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["meals"].length} Meal{workingOnEvent["meals"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Receipts */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                }}>
                                    <IconButton
                                        title="Receipts"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-receipt"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["receipts"].length} Receipt{workingOnEvent["receipts"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Flights */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Flights"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-airplane"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["flights"].length} Flight{workingOnEvent["flights"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Documents */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Documents"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-file-check"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["documents"].length} Document{workingOnEvent["documents"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Ideas */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Ideas"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-lamp-bright"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["ideas"].length} Idea{workingOnEvent["ideas"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Supplies */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Supplies"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-checklist2"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["supplies"].length === 1 ? '1 Supply' : workingOnEvent["supplies"].length+' Supplies'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* To Do */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="To Do"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-checklist-time"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["to_do"].length} To Do
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Photos */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Photos"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-picture2"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["photos"].length} Photo{workingOnEvent["photos"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Messages */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Messages"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-smartphone-text"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {workingOnEvent["messages"].length} Message{workingOnEvent["messages"].length === 1 ? '' : 's'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Weather */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        padding: '8px'
                                    }}>
                                    <IconButton
                                        title="Weather"
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            backgroundColor: '',
                                            border: '0',
                                            color: '#f09637',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <i className="icon-sun-wind"/>
                                    </IconButton>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Weather
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                    </Grid>
                </Box>

            )}
        </Container>
    );

}

export default EventOverview;