export const rsvpYesChipStyle = {
    boxShadow: '0 0 5px #5E9F3D',
    border: '1px solid #5E9F3D',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#caffb5', // Reverse the gradient on hover
    },
};
export const rsvpDeclineChipStyle = {
    boxShadow: '0 0 5px #ff0000',
    border: '1px solid #ff0000',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#ffd6d6', // Reverse the gradient on hover
    },
};
export const rsvpNaChipStyle = {
    boxShadow: '0 0 5px #f09637',
    border: '1px solid #f09637',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#ffdebc', // Reverse the gradient on hover
    },
};
