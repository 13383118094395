import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import './budget.css';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import {
    Avatar,
    Button,
    Grid,
    InputBase,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    Paper,
    TextField
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { PieChart } from '@mui/x-charts/PieChart';
import Divider from "@mui/material/Divider";


function BudgetSummary(props){

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    const [ currentFeature, setCurrentFeature ] = useState('');

    const [ totalBudget, setTotalBudget ] = useState(Planning.budget_total);
    const [ totalBudgetActual, setTotalBudgetActual ] = useState(Planning.budget_actual);

    const [ lodgingTotal, setLodgingTotal ] = useState(0);
    const [ flightsTotal, setFlightsTotal ] = useState(0);
    const [ mealsTotal, setMealsTotal ] = useState(0);
    const [ activitiesTotal, setActivitiesTotal ] = useState(0);
    const [ receiptsTotal, setReceiptsTotal ] = useState(0);
    const [ unassignedTotal, setUnassignedTotal ] = useState(0);


    const [pieChartData, setPieChartData] = useState([]);



    useEffect(() => {

        if (Object.keys(Planning).length > 0) {
            //an Event exists

            // Calculate total expenses for lodgings
            const _lodgings_total = Planning.lodging.reduce((total, _lodging) => {
                const lodgingAmount = parseFloat(_lodging.amount_total);

                // Check if lodgingAmount is a valid number
                if (!isNaN(lodgingAmount)) {
                    return total + lodgingAmount;
                }

                return total;
            }, 0);

            // Calculate total expenses for flights
            const _flights_total = Planning.flights.reduce((total, _flight) => {
                const flightAmount = parseFloat(_flight.amount_total);

                // Check if flightAmount is a valid number
                if (!isNaN(flightAmount)) {
                    return total + flightAmount;
                }

                return total;
            }, 0);

            // Calculate total expenses for meals
            const _meals_total = Planning.meals.reduce((total, _meal) => {
                const mealAmount = parseFloat(_meal.amount_total);

                // Check if mealAmount is a valid number
                if (!isNaN(mealAmount)) {
                    return total + mealAmount;
                }

                return total;
            }, 0);

            // Calculate total expenses for activities
            const _activities_total = Planning.activities.reduce((total, _activity) => {
                const activityAmount = parseFloat(_activity.amount_total);

                // Check if activityAmount is a valid number
                if (!isNaN(activityAmount)) {
                    return total + activityAmount;
                }

                return total;
            }, 0);

            setLodgingTotal(_lodgings_total);
            setFlightsTotal(_flights_total);
            setMealsTotal(_meals_total);
            setActivitiesTotal(_activities_total);

            setTotalBudgetActual(_lodgings_total + _flights_total + _meals_total + _activities_total);

            populatePieChartData();

        } else {
            console.log("Planning is empty : "+Planning);
        }

    },[]);

    useEffect(() => {
        console.log("updated eventBudgetActual or eventBudgetTotal");
//        setTotalBudget(props.eventBudgetTotal);
//        setTotalBudgetActual(props.eventBudgetActual);
    }, [props.eventBudgetActual, props.eventBudgetTotal]);

    useEffect(() => {
        console.log(JSON.stringify(Planning.attendees, null, '\t'));

//        setAttendeeSummary(Planning.attendees);

    }, [Planning.attendees]);


    const showAttendeeSummary = () => {

        return (

            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {

                    Planning.attendees
                        .sort((a, b) => a.lname.localeCompare(b.lname))
                        .map((_att, index) => {
                            const totalExpensesByCurrency = {};

                            // Calculate total expenses for activities
                            Planning.activities.forEach((activity) => {
                                const _activityAttendees = activity.attendees;

                                const { currency } = activity;
                                for (const _activityAttendee of _activityAttendees) {
                                    if (_activityAttendee.id === _att.id) {
                                        // Check if the currency exists in the totalExpensesByCurrency object
                                        if (!totalExpensesByCurrency[currency]) {
                                            totalExpensesByCurrency[currency] = 0;
                                        }

                                        // Add the amount to the total for the respective currency
                                        totalExpensesByCurrency[currency] += parseFloat(_activityAttendee.cost);
                                    }
                                }
                            });

                            // Calculate total expenses for lodgings
                            Planning.lodging.forEach((lodging) => {
                                const _lodgingAttendees = lodging.attendees;
                                const { currency } = lodging;
                                for (const _lodgingAttendee of _lodgingAttendees) {
                                    if (_lodgingAttendee.id === _att.id) {
                                        // Check if the currency exists in the totalExpensesByCurrency object
                                        if (!totalExpensesByCurrency[currency]) {
                                            totalExpensesByCurrency[currency] = 0;
                                        }

                                        // Add the amount to the total for the respective currency
                                        totalExpensesByCurrency[currency] += parseFloat(_lodgingAttendee.cost);
                                    }
                                }
                            });

                            // Calculate total expenses for meals
                            Planning.meals.forEach((meal) => {
                                const _mealAttendees = meal.attendees;

                                const { currency } = meal;
                                for (const _mealAttendee of _mealAttendees) {
                                    if (_mealAttendee.id === _att.id) {
                                        // Check if the currency exists in the totalExpensesByCurrency object
                                        if (!totalExpensesByCurrency[currency]) {
                                            totalExpensesByCurrency[currency] = 0;
                                        }

                                        // Add the amount to the total for the respective currency
                                        totalExpensesByCurrency[currency] += parseFloat(_mealAttendee.cost);
                                    }
                                }
                            });

                            //check for no properties
                            if (Object.keys(totalExpensesByCurrency).length === 0){
                                totalExpensesByCurrency['USD'] = 0;
                            }

                            // Render the total expenses for each currency
                            const currencyItems = Object.entries(totalExpensesByCurrency).map(([currency, totalCost]) => (
                                <Typography variant="body2" color="text.secondary" key={currency}>
                                    {totalCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                                </Typography>
                            ));

                            return (

                                    <ListItem key={index} sx={{padding: '10px 0'}}>
                                        <ListItemAvatar>
                                            <Avatar {...stringAvatar(`${formatName(_att.fname)}, ${formatName(_att.lname)}`)} />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {formatName(_att.fname)} {formatName(_att.lname)}
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <div>
                                                {currencyItems}
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>

                            );
                        })
                }

            </List>
        )

    }

    const [ showEditEventBudget, setShowEditEventBudget ] = useState(false);
    const [ updatedEventBudget, setUpdatedEventBudget ] = useState(0);

    function handleShowEditEventBudget(){
        setShowEditEventBudget(!showEditEventBudget);
    }
    function handleUpdateEventBudget(event){
        setUpdatedEventBudget(event.target.value);
    }
    const handleSetEventBudgetOK = () => {
        if (parseFloat(updatedEventBudget) > 0){

            setTotalBudget(updatedEventBudget);

            props.setEventBudgetTotal(updatedEventBudget);

            handleShowEditEventBudget();
        } else {
            //invalid
        }

    }

    const handleCategoryIconClick = (categoryName) => {
        // Handle the click event here
        console.log(`Clicked on ${categoryName}`);
        setCurrentFeature(categoryName);
    }

    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: 2,
                borderRadius: 2,
                textAlign: 'center',
                fontWeight: '700',
                background: `linear-gradient(195deg, #FFFFFF, ${stringToColor(name)})`,
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSetEventBudgetOK();
        }
    };

    function populatePieChartData(){

        setPieChartData([
            {
                id: 0,
                value: Planning.lodging.reduce((total, _lodging) => total + parseFloat(_lodging.amount_total), 0),
                label: 'Lodging'
            },
            {
                id: 1,
                value: Planning.flights.reduce((total, _flight) => total + parseFloat(_flight.amount_total), 0),
                label: 'Flights'
            },
            {
                id: 2,
                value: Planning.meals.reduce((total, _meal) => total + parseFloat(_meal.amount_total), 0),
                label: 'Meals'
            },
            {
                id: 3,
                value: Planning.activities.reduce((total, _activity) => total + parseFloat(_activity.amount_total), 0),
                label: 'Activities'
            },
        ]);
    }




    return (

        <div className="event-budget-dialog-box-content-holder">
            {/* show the overview */}
            <div
                className="event-budget-dialog-box-content-total-budget-amount-holder"
            >
                <div className="event-budget-dialog-box-content-total-budget-amount-row">
                    <div
                        className="event-budget-dialog-box-content-total-budget-amount-total"
                        onClick={handleShowEditEventBudget}>
                        <Typography variant="body2" color="text.secondary">
                            Budget
                        </Typography>
                        <div className="event-budget-dialog-box-content-total-budget-icon">
                            <i className="icon-money-pouch" />
                        </div>
                        <Typography variant="body2" color="text.secondary">
                            {totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </Typography>
                    </div>
                    <div
                        className="event-budget-dialog-box-content-total-budget-amount-actual">
                        <Typography variant="body2" color="text.secondary">
                            Expenses
                        </Typography>
                        <div className="event-budget-dialog-box-content-total-budget-icon">
                            <i className="icon-receipt" />
                        </div>
                        <Typography variant="body2" color="text.secondary">
                            {totalBudgetActual.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </Typography>
                    </div>
                    <div
                        className="event-budget-dialog-box-content-total-budget-amount-available">
                        <Typography variant="body2" color="text.secondary">
                            Available
                        </Typography>
                        <div className="event-budget-dialog-box-content-total-budget-icon">
                            <i className="icon-wallet" />
                        </div>
                        <Typography variant="body2" color="text.secondary">
                            {(parseFloat(totalBudget) - parseFloat(totalBudgetActual)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </Typography>
                        {
                            (parseFloat(totalBudget) - parseFloat(totalBudgetActual)).toFixed(2) >= 0 ? (
                                <i className="icon-check" style={{position:'absolute', top:5, right:5, fontSize:'11px', color:'green'}} />
                            ) : (
                                <i className="icon-warning" style={{position:'absolute', top:5, right:5, fontSize:'11px', color:'red'}}/>
                            )
                        }
                    </div>
                </div>

                {/* for changing the budget amount */}
                <div
                    className="event-budget-dialog-box-content-total-budget-change-holder"
                    style={{ display: showEditEventBudget ? 'block' : 'none' }}
                >
                    <TextField
                        fullWidth
                        id="amount"
                        label="Event Budget"
                        value={updatedEventBudget}
                        onChange={handleUpdateEventBudget}
                        onKeyDown={handleKeyDown}
                    />
                    <Button onClick={handleShowEditEventBudget} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleSetEventBudgetOK} autoFocus>
                        Update
                    </Button>
                </div>

            </div>

            {/* now show the pie chart */}
            <div>
                <PieChart
                    series={[
                        {
                            data: pieChartData,
                        },
                    ]}
                    width={400}
                    height={200}
                />
            </div>


            {/* now show the breakdown by Attendee */}
            {
                showAttendeeSummary()
            }

        </div>
    )
}

export default BudgetSummary;
