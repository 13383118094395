// Contact.js

import React from 'react';
import {Grid} from "@mui/material";

import ContactContent from "./ContactContent";



function Contact() {


    return (

        <Grid container spacing={12}>
            <ContactContent />
        </Grid>

    )
}

export default Contact;
