import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {Button, Card, CardContent, CardMedia, Chip, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {updateChipStyle} from "../../ButtonStyle/Update";



function IdeasCard(props){

    const { dataArray, addPlanningAttendee } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [eventIdea, setEventIdea] = useState(props.idea);
    const [selectedAction, setSelectedAction] = useState("");
    const [ideaTabSelected, setIdeaTabSelected] = useState("");


    useEffect(()=> {
        console.log("props.idea has been updated --- : ");
        setEventIdea(props.idea);
    }, [props.idea]);


    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        return '';
    }

    return (




            <Card
                style={{width:'100%', marginBottom:'20px'}}
            >

                {/* add any photos if they exist */}
                {
                    eventIdea['photos'] && eventIdea['photos'].length >= 0 ? (
                        <div className="activities-suggestions-add-list-item-photos">
                            {
                                eventIdea['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }


                <CardContent>
                    <Typography variant="body2">
                        {eventIdea['title']}
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                        {eventIdea['location'].address}
                    </Typography>

                    <Typography variant="body2">
                        {eventIdea['url']}
                    </Typography>
                </CardContent>

                <CardContent>
                    {

                        eventIdea['comments'].map((_comment, _index) => (

                            _comment['comment'] !== "" ? (
                                <>
                                    <Typography
                                        key={_index}
                                        sx={{ fontSize: '13px' }}
                                        component="div"
                                    >
                                        {_comment['comment']}
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: 'flex', // Enable flexbox
                                            justifyContent: 'space-between', // Space between the items
                                            alignItems: 'center', // Align items vertically
                                            width: '100%', // Take full width to allow space-between to work
                                            marginBottom: '20px' // Added marginBottom here for spacing
                                        }}
                                    >
                                        <Typography
                                            key={_index + 'id'}
                                            component="span"
                                            variant="caption"
                                            sx={{
                                                    fontSize: '10px'
                                                }}
                                        >
                                            {capitalizeFirstLetter(Attendees.find((_att) => _att.id === _comment['attendee_id'])?.fname) || ''}
                                        </Typography>
                                        <Typography
                                            key={_index + 'date'}
                                            component="span"
                                            variant="caption"
                                            sx={{
                                                fontSize: '10px'
                                            }}
                                        >
                                            {dayjs(_comment['comment_id']).format('MMM D h:mm A')}
                                        </Typography>
                                    </Box>

                                </>

                            ) : (
                                <></>
                            )

                        ))
                    }

                    {/*
                        add the input area
                    */}

                    <TextField
                        id="standard-basic"
                        label="Add a Comment"
                        variant="standard" />

                    <Chip
                        sx={updateChipStyle}
                        variant="contained"
                        label="Add Comment"
                    />

                </CardContent>


            </Card>

    )
}

export default IdeasCard;
