import {useContext} from "react";
import {PAContext} from "../../../Services/PAContext";
import {
    addEventAttendeePhotoToBrowserStorage, addEventPhoto,
    addEventPhotoThumbnail, getSpecificEventPhotoDetails
} from "../../../Services/BrowserDatabaseFunctions";
import Photo from "../../../MasterEventObjects/Photo";



export function E_uploadEventImageResults(_incoming) {
    console.log("E_uploadEventImageResults");

    /*
        this brings in the results of the Photo upload
        the full size image should already be here and the generated thumbnail will be coming once the
        resizing process is complete
     */

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    //update the browser IndexedDB database

    //if this image is for the same Event that may be loaded then update that as well

}

export function E_uploadEventAttendeePhotoThumbnailResults(_incoming) {
    console.log("E_uploadEventAttendeePhotoThumbnailResults");

    /*
        this brings in the thumbnail and full size image
     */

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    try {

        const _result = _incoming.result;

        if (_result === "ok"){

            try {
                const {
                    id, event_id, attendee_id, filename, filetype, height, width,
                    thumbnail_data, full_data, caption, private : _private, sharable, share_with,
                    full_size_uploaded, thumbnail_finished, last_modified, status
                } = _incoming;

                const newPhoto = new Photo();
                Object.assign(newPhoto, {
                    id, temp_id: 0, event_id, attendee_id, filename, filetype, height,
                    width, thumbnail_data, full_data, caption, private : _private, sharable,
                    share_with, full_size_uploaded, thumbnail_finished, last_modified, status
                });

                addEventPhotoThumbnail(newPhoto)
                    .then(success => {
                        if (success) {
                            if (Planning.id === event_id) {
                                const existingPhoto = Planning.photos.find(photo => photo.id === id);

                                if (existingPhoto) {
                                    Object.assign(existingPhoto, newPhoto);
                                } else {
                                    Planning.photos.push(newPhoto);
                                }
                            }
                        } else {
                            console.error("Failed to save photo to browser DB");
                        }
                    })
                    .catch(error => {
                        console.error("Error saving photo: ", error);
                    });

            } catch (error) {
                console.error("Unexpected error: ", error);
            }


        }

    } catch (e){
        console.log("ERROROROR : "+e);
    }

}

export default function E_uploadEventAttendeePhotosResults(_incoming, _planning) {
    console.log("E_uploadEventAttendeePhotosResults : "+JSON.stringify(_incoming, null, '\t'));

    try {

        const _result = _incoming.result;

        if (_result === "ok"){

            try {
                const {
                    id, event_id, attendee_id, filename, filetype, height, width,
                    thumbnail_data, full_data, caption, private : _private, sharable, share_with,
                    full_size_uploaded, thumbnail_finished, last_modified, status
                } = _incoming;

                const newPhoto = new Photo();
                Object.assign(newPhoto, {
                    id, temp_id: 0, event_id, attendee_id, filename, filetype, height,
                    width, thumbnail_data, full_data, caption, private : _private, sharable,
                    share_with, full_size_uploaded, thumbnail_finished, last_modified, status
                });

                //add the photo to the Event Photos
                const _photo_for_event = {};
                _photo_for_event.id = id;
                _photo_for_event.attendee_id = attendee_id;
                _photo_for_event.filename = filename;
                _photo_for_event.filetype = filetype;
                _photo_for_event.height = height;
                _photo_for_event.width = width;
                _photo_for_event.private = _private;
                _photo_for_event.sharable = sharable;
                _photo_for_event.share_with = share_with;
                _photo_for_event.full_size_uploaded = full_size_uploaded;
                _photo_for_event.thumbnail_finished = thumbnail_finished;
                _photo_for_event.caption = caption;
                _photo_for_event.last_modified = last_modified;

                console.log("_photo_for_event : "+_photo_for_event);

                addEventAttendeePhotoToBrowserStorage(event_id, _photo_for_event).then(success => {
                    if (success) {
                        console.log("SUCCESSFULLY added Photo to Event");
                    }
                });

                //add the Photo to the browser storage Photos store
                addEventPhoto(newPhoto)
                    .then(success => {
                        if (success) {

                            /*
                                if they are currently working on this Event then update
                             */
                            if (_planning.id === event_id) {
                                const existingPhoto = _planning.photos.find(photo => photo.id === id);

                                if (existingPhoto) {
                                    Object.assign(existingPhoto, newPhoto);
                                } else {
                                    _planning.photos.push(newPhoto);
                                }
                            }
                        } else {
                            console.error("Failed to save photo to browser DB");
                        }
                    })
                    .catch(error => {
                        console.error("Error saving photo: ", error);
                    });

            } catch (error) {
                console.error("Unexpected error: ", error);
            }


        }

    } catch (e){
        console.log("ERROROROR : "+e);
    }

}

export function E_getEventThumbnailImageResults(_incoming, _planning) {
    console.log("E_getEventThumbnailImageResults");

    /*
        the photo details are already in the Event but we needed to fetch the thumbnail data from the server
     */
    try {

        const _result = _incoming.result;

        if (_result === "ok"){

            try {
                const _photo = new Photo();
                _photo.event_id = _incoming.event_id;
                _photo.attendee_id = _incoming.attendee_id;
                _photo.id = _incoming.photo_id;
                _photo.filename = _incoming.file_name;
                _photo.thumbnail_data = _incoming.data;

                /*
                    get the photo details from the Event photos
                 */

                getSpecificEventPhotoDetails(_incoming.event_id, _incoming.photo_id).then(_db_photo => {
                    if (_db_photo) {

                        _photo.filetype = _db_photo.filetype;
                        _photo.height = _db_photo.height;
                        _photo.width = _db_photo.width;
                        _photo.caption = _db_photo.caption;
                        _photo.private = _db_photo.private;
                        _photo.sharable = _db_photo.sharable;
                        _photo.share_with = _db_photo.share_with;
                        _photo.full_size_uploaded = _db_photo.full_size_uploaded;
                        _photo.thumbnail_finished = _db_photo.thumbnail_finished;
                        _photo.last_modified = _db_photo.last_modified;

                        addEventPhotoThumbnail(_photo).then(_success => {

                            if (_success){
                                if (_planning.id === _incoming.event_id) {
                                    const existingPhoto = _planning.photos.find(photo => photo.id === _incoming.event_id);

                                    if (existingPhoto) {
                                        Object.assign(existingPhoto, _photo);
                                    } else {
                                        _planning.photos.push(_photo);
                                    }
                                }
                            }

                        })

                    } else {
                        console.error("Failed to save photo to browser DB");
                    }
                })
                    .catch(error => {
                        console.error("Error saving photo: ", error);
                    });

            } catch (error) {
                console.error("Unexpected error: ", error);
            }

        }

    } catch (e){
        console.log("ERROROROR : "+e);
    }

}

export function E_getEventFullSizeImageResults(_incoming) {
    console.log("E_getEventFullSizeImageResults");

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    //update the browser IndexedDB database

    //if this image is for the same Event that may be loaded then update that as well

}

export function E_updateEventAttendeePhotoPrivateResults(_incoming) {
    console.log("E_updateEventAttendeePhotoPrivateResults");

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    //update the browser IndexedDB database

    //if this image is for the same Event that may be loaded then update that as well

}

export function E_deleteEventPhotosResults(_incoming) {
    console.log("E_deleteEventPhotosResults");

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    //update the browser IndexedDB database

    //if this image is for the same Event that may be loaded then update that as well

}


