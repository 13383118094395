import React, {useContext} from "react";
import {PAContext} from "../../Services/PAContext";



function LoginContent (){

    const { dataArray } = useContext(PAContext);









}

export default LoginContent;
