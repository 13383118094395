import React from "react";

import './pricing.css';


function Pricing(){

    return (
        <div id="content_area" className="content-area-pricing">

            <div className="examples-pricing-page">
                <div className="every-trip-section">
                    <div className="every-trip-title-holder">
                        <div className="every-trip-title">The world is yours to explore.
                            <br />
                            For every kind of destination - the perfect trip starts here.
                        </div>
                    </div>
                    <div className="trip-examples-section">
                        <div className="trip-examples-row">
                            <div className="trip-examples-column">
                                <div className="trip-examples-title">Group trip planner</div>
                                <div className="trip-examples-description">
                                    Easily create a memorable and enjoyable experience for everyone involved.
                                </div>
                            </div>
                            <div className="trip-examples-column">
                                <div className="trip-examples-title">Multi-destination planner</div>
                                <div className="trip-examples-description">
                                    Make a complex trip into an easy one with all your information in one place.
                                </div>
                            </div>
                            <div className="trip-examples-column3">
                                <div className="trip-examples-title">Get-together planner</div>
                                <div className="trip-examples-description">
                                    large family get-togethers <br />
                                    family reunions <br />
                                    holidays and more!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pricing-title-holder">
                    <div className="pricing-title">Flexible pricing tailored to fit your needs.</div>
                </div>
                <div className="pricing-section">
                    <div className="pricing-row">
                        <div className="pricing-column">
                            <div className="pricing-column-plan1">
                                <div className="pricing-plan-title">Free</div>
                                <div className="pricing-plan-dollar-amount-free">$0</div>
                                <div className="get-started">
                                    <div className="pricing-get-started-holder-free">
                                        <button className="pricing-get-started-button-free"
                                                onClick="location.href='/login.html';">Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-column-plan2">
                                <div className="pricing-plan-title">Pay as you go</div>
                                <div className="pricing-plan-subtitle">Per event</div>
                                <div className="pricing-plan-dollar-amount">$5.99</div>
                                <div className="get-started">
                                    <div className="pricing-get-started-holder-free">
                                        <button className="pricing-get-started-button-free"
                                                onClick="location.href='/login.html';">Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-separator-line-container">
                        <div className="pricing-separator-line"></div>
                    </div>
                    <div className="pricing-row">
                        <div className="pricing-column">
                            <div className="pricing-column-plan1">
                                <div className="pricing-plan-title">Monthly</div>
                                <div className="pricing-plan-subtitle">Subscription</div>
                                <div className="pricing-plan-dollar-amount">$11.99</div>
                                <div className="pricing-plan-dollar-amount-frequency">/month</div>
                                <div className="get-started">
                                    <div className="pricing-get-started-holder">
                                        <button className="pricing-get-started-button"
                                                onClick="location.href='/login.html';">Start for Free
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-column-plan2">
                                <div className="pricing-plan-title">Yearly</div>
                                <div className="pricing-plan-subtitle">Subscription</div>
                                <div className="pricing-plan-dollar-amount">$49.99</div>
                                <div className="pricing-plan-dollar-amount-frequency">/year</div>
                                <div className="get-started">
                                    <div className="pricing-get-started-holder">
                                        <button className="pricing-get-started-button"
                                                onClick="location.href='/login.html';">Start for Free
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-table-header">Features you never knew you needed.</div>
            <div className="features-table">
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th className="features-free">Free</th>
                        <th className="features-per-event">Per Event</th>
                        <th className="features-monthly">Monthly Subscription</th>
                        <th className="features-yearly">Yearly Subscription</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="features-table-title"><b>Activities</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Single day/times or multi days</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Meals</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Easy entry or GPS</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Home cooking</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Lodging</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Easy entry or GPS</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Easy AirBnB</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Flights</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Easy entry</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Search entry</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Photos</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Group photo storage</td>
                        <td className="features-free">10</td>
                        <td className="features-per-event">20</td>
                        <td className="features-monthly">unlimited</td>
                        <td className="features-yearly">unlimited</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Messaging</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Text</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Images</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Supplies</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Personal list</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Can assign</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Essentials</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Ideas</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Easy add</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Group commenting</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Voting</td>
                        <td></td>
                        <td></td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>To-Do List</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Private</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Can assign</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Scheduled reminders</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Reminders</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>PUSH or Email</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Documents</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Encrypted</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Private</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Password protected</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Per event storage</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Personal document library</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Weather</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>All event location</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Daily detailed</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Map it</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Detailed overview</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Daily view/detailed view</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Routes and flight paths</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Receipts</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Private</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Budget tracking</td>
                        <td></td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Attendees</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>RSVP</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Easy load</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Bulk upload</td>
                        <td></td>
                        <td></td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Load phone contact (Android only)</td>
                        <td></td>
                        <td></td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td>Personal notification configuration</td>
                        <td></td>
                        <td></td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Notifications</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>PUSH and Email</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Categories</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>Customization</td>
                        <td className="features-free">x</td>
                        <td className="features-per-event">x</td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    <tr>
                        <td className="features-table-title"><b>Concierge</b></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                        <td className="features-table-title"></td>
                    </tr>
                    <tr>
                        <td>oh boy...</td>
                        <td></td>
                        <td></td>
                        <td className="features-monthly">x</td>
                        <td className="features-yearly">x</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default Pricing;
