
import React, {useState} from "react";

import '../../css/main.css';
import './features.css';
import {TextField} from "@mui/material";

import topGraphic from '../../graphics/featuresPageTopGraphic.png';
import fishProfile from '../../graphics/fish_profile.png';
import owlProfile from '../../graphics/owl_profile.png';
import dogProfile from '../../graphics/dog_profile.png';
import worldMapWithCircles from '../../graphics/worldMapWithCircles.png';
import featuresMap from '../../graphics/featuresMap.png';
import featuresMessaging from '../../graphics/featuresMessaging.png';
import featuresPhotos from '../../graphics/featuresPhotos.png';



function FeaturesContent() {



    const [ newsletterSuggestions, setNewsletterSuggestions] = useState(false);
    const [ newsletterDidYouKnow, setNewsletterDidYouKnow] = useState(false);
    const [ newsletterPopularDestinations, setNewsletterPopularDestinations] = useState(false);
    const [ newsletterOftenOverlooked, setNewsletterOftenOverlooked] = useState(false);
    const [ newsletterTravelTips, setNewsletterTravelTips] = useState(false);
    const [ newsletterAIExperimental, setNewsletterAIExperimental] = useState(false);

    const [ fname, setFname] = useState("");
    const [ email, setEmail] = useState("");
    const [ newsletterOptIn, setNewsletterOptIn] = useState(false);


    function handleLoginClick(event){

    }

    function handleNewsletterSignup(event){

    }

    function toggleNewsletterSuggestions(){
        setNewsletterSuggestions(!newsletterSuggestions);
    }
    function toggleNewsletterDidYouKnow(){
        setNewsletterDidYouKnow(!newsletterDidYouKnow);
    }
    function toggleNewsletterPopularDestinations(){
        setNewsletterPopularDestinations(!newsletterPopularDestinations);
    }
    function toggleNewsletterOftenOverlooked(){
        setNewsletterOftenOverlooked(!newsletterOftenOverlooked);
    }
    function toggleNewsletterTravelTips(){
        setNewsletterTravelTips(!newsletterTravelTips);
    }
    function toggleNewsletterAIExperimental(){
        setNewsletterAIExperimental(!newsletterAIExperimental);
    }

    function handleFnameChange(event){
        setFname(event.target.value);
    }
    function handleEmailChange(event){
        setEmail(event.target.value);
    }
    function toggleNewsletterOptIn(){
        setNewsletterOptIn(!newsletterOptIn);
    }




    return (

        <div id="content_area" className="content-area-features">

            <div className="section1-content-holder">
                <div className="section1-content-holder-container">

                    <div className="section1-content-holder-left">
                        <div className="section1-content-holder-left-title-line">
                            A Full Suite of Tools for Every Adventure
                        </div>
                        <div className="section1-content-holder-left-content-line">
                            Once you start using our comprehensive features you'll see why you can
                            become the most prepared for any adventure, planned or spontaneous.
                        </div>
                    </div>

                    <div className="section1-content-holder-right">
                        <div className="section1-content-holder-right-content">
                            <img className="section1-content-holder-right-content-img"
                                 src={topGraphic} alt="" />
                        </div>
                    </div>

                </div>
            </div>


            <div className="section1-2-content-holder">
                <div className="section1-2-content-holder-container">

                    <div className="section1-2-content-holder-left">
                        <div className="section1-2-content-holder-left-content">

                            <div className="content-area-box-event-holder">
                                <div className="content-area-box-event-holder-title">New Year's Eve Getaway</div>
                                <div className="content-area-box-event-holder-title-year">2022</div>
                                <div className="content-area-box-event-holder-image-holder">
                                    <img
                                        src="https://www.plannedadventure.com/PittnerEvents/event-63a3bd816e8d05477fbec965/thumbnail-happy-new-year-2023-images-download_7cccb.jpg"
                                        width="160"
                                        height="107"
                                        className="content-area-box-event-holder-image"
                                        alt=""
                                    />
                                </div>
                                <div className="content-area-box-event-footer-holder">
                                    <p className="content-area-box-event-footer-dates">Thu, Dec 29th - Mon, Jan 2nd</p>
                                    <p className="content-area-box-event-footer-location">Orlando, Fl</p></div>
                                <div className="content-area-box-event-countdown-holder">
                                    <div className="events-font-size-20">4 weeks ago</div>
                                </div>
                            </div>

                            <div className="content-area-box-event-holder">
                                <div className="content-area-box-event-holder-title">Marathon, FL</div>
                                <div className="content-area-box-event-holder-title-year">2022</div>
                                <div className="content-area-box-event-holder-image-holder">
                                    <img
                                        src="https://www.plannedadventure.com/PittnerEvents/event-636ae2fc58713f4a4167e580/thumbnail-corte_del_sol_edited-7-2.jpg"
                                        width="160"
                                        height="90"
                                        className="content-area-box-event-holder-image"
                                        alt="" /></div>
                                <div className="content-area-box-event-footer-holder">
                                    <p className="content-area-box-event-footer-dates">Sun, Nov 13th - Sun, Nov 20th</p>
                                    <p className="content-area-box-event-footer-location">Marathon, FL</p></div>
                                <div className="content-area-box-event-countdown-holder">
                                    <div className="events-font-size-20">2 months ago</div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="section1-2-content-holder-right">
                        <div className="section1-2-content-holder-right-title-line">
                            Events
                        </div>
                        <div className="section1-2-content-holder-right-content-line">
                            <p>This is where it all starts.</p>
                            <p>Create an Event as the central repository for all things related.</p>
                            <p></p>
                        </div>
                        <div className="section1-2-content-holder-right-content-footer">
                            <span
                                className="section1-2-content-holder-right-content-footer-label label">vacations</span>
                            <span className="section1-2-content-holder-right-content-footer-label label">trips</span>
                            <span className="section1-2-content-holder-right-content-footer-label label">weekend getaways</span>
                            <span
                                className="section1-2-content-holder-right-content-footer-label label">celebrations</span>
                            <span
                                className="section1-2-content-holder-right-content-footer-label label">get-togethers</span>
                            <span className="section1-2-content-holder-right-content-footer-label label">meetings</span>
                        </div>

                    </div>

                </div>
            </div>


            <div className="section1-3-content-holder">
                <div className="section1-3-content-holder-container">

                    <div className="section1-3-content-holder-left">
                        <div className="section1-3-content-holder-left-title-line">
                            Who's Attending
                        </div>
                        <div className="section1-3-content-holder-left-content-line">
                            <p>Add attendees to all Events.</p>
                            <p>Can be added from your phone Contacts, from previous Events, or entered manually.</p>
                            <p>You can elevate attendee privilege levels to get an extra hand for planning and
                                management.</p>
                        </div>
                        <div className="section1-3-content-holder-left-content-footer">
                            <span className="section1-3-content-holder-left-content-footer-label label">contacts</span>
                            <span
                                className="section1-3-content-holder-left-content-footer-label label">previous events</span>
                            <span
                                className="section1-3-content-holder-left-content-footer-label label">privileges</span>
                            <span
                                className="section1-3-content-holder-left-content-footer-label label">welcome message</span>
                            <span className="section1-3-content-holder-left-content-footer-label label">invites</span>
                            <span
                                className="section1-3-content-holder-left-content-footer-label label">notifications</span>
                            <span className="section1-3-content-holder-left-content-footer-label label">messaging</span>
                            <span className="section1-3-content-holder-left-content-footer-label label">rsvp</span>
                            <span
                                className="section1-3-content-holder-left-content-footer-label label">phone masking</span>
                        </div>
                    </div>

                    <div className="section1-3-content-holder-right">
                        <div className="section1-3-content-holder-right-content">

                            <div className="event-attendees-list-item-holder-new">
                                <div className="event-attendees-list-item-nickname-holder label">Keith</div>
                                <div className="event-attendees-list-item-icon-holder">
                                    <img
                                        className="event-attendees-list-item-icon-holder-icon"
                                        src={fishProfile}
                                        alt=""
                                    />
                                </div>
                                <div className="event-attendees-list-item-center-content-holder">
                                    <div className="event-attendees-list-item-center-content-name-holder">keith
                                        pittner
                                    </div>
                                    <span className="event-attendees-list-item-center-content-phone-holder">(843) 555-1212</span>
                                    <div className="event-attendees-list-item-center-content-third-line-holder">
                                        <div className="event-attendees-list-item-center-content-third-line-rsvp-title">
                                            RSVP :
                                            <div
                                                className="event-attendees-list-item-center-content-third-line-rsvp-yes label">YES</div>
                                        </div>
                                        <div
                                            className="event-attendees-list-item-center-content-third-line-icons-holder">
                                            <i className="icon-crown event-attendees-list-item-center-content-third-line-icons-host"
                                               title=""></i>
                                            <i className="icon-bell-ringing2 event-attendees-list-item-center-content-third-line-icons-notifications"
                                               title=""></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="event-attendees-list-item-holder-new">
                                <div className="event-attendees-list-item-nickname-holder label">Momma</div>
                                <div className="event-attendees-list-item-icon-holder">
                                    <img className="event-attendees-list-item-icon-holder-icon"
                                         src={owlProfile}
                                         alt=""
                                    />
                                </div>
                                <div className="event-attendees-list-item-center-content-holder">
                                    <div className="event-attendees-list-item-center-content-name-holder">patricia
                                        pittner
                                    </div>
                                    <span className="event-attendees-list-item-center-content-phone-holder">(843) 555-1214</span>
                                    <div className="event-attendees-list-item-center-content-third-line-holder">
                                        <div className="event-attendees-list-item-center-content-third-line-rsvp-title">
                                            RSVP :
                                            <div
                                                className="event-attendees-list-item-center-content-third-line-rsvp-yes label">YES</div>
                                        </div>
                                        <div
                                            className="event-attendees-list-item-center-content-third-line-icons-holder">
                                            <i className="icon-bell-ringing2 event-attendees-list-item-center-content-third-line-icons-notifications"
                                               title=""></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="event-attendees-list-item-holder-new">
                                <div className="event-attendees-list-item-nickname-holder label">Kels</div>
                                <div className="event-attendees-list-item-icon-holder">
                                    <img
                                        className="event-attendees-list-item-icon-holder-icon"
                                        src={dogProfile}
                                        alt=""
                                    />
                                </div>
                                <div className="event-attendees-list-item-center-content-holder">
                                    <div className="event-attendees-list-item-center-content-name-holder">kelsey
                                        pittner
                                    </div>
                                    <span className="event-attendees-list-item-center-content-phone-holder">(843) 555-1234</span>
                                    <div className="event-attendees-list-item-center-content-third-line-holder">
                                        <div className="event-attendees-list-item-center-content-third-line-rsvp-title">
                                            RSVP :
                                            <div
                                                className="event-attendees-list-item-center-content-third-line-rsvp-no-answer label">no
                                                answer</div>
                                        </div>
                                        <div
                                            className="event-attendees-list-item-center-content-third-line-icons-holder">
                                            <i className="icon-crown event-attendees-list-item-center-content-third-line-icons-host"
                                               title=""></i>
                                            <i className="icon-bell-ringing2 event-attendees-list-item-center-content-third-line-icons-notifications"
                                               title=""></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div className="section2-content-holder">
                <div className="section2-content-holder-container">

                    <div className="section2-content-holder-left">
                        <div className="section2-content-holder-left-content">
                            <img className="section2-content-holder-left-content-img"
                                 src={worldMapWithCircles}
                                 alt=""
                            />
                        </div>
                    </div>

                    <div className="section2-content-holder-right">
                        <div className="section2-content-holder-right-title-line">
                            Where To Go
                        </div>
                        <div className="section2-content-holder-right-content-line">
                            <p>So many places to explore, events to plan for.</p>
                            <p>Near or far, you can enter Domestic or International
                                destinations using an address, search using Google Maps, or using your GPS location.</p>
                        </div>
                        <div className="section2-content-holder-right-content-footer">
                            <span className="section2-content-holder-right-content-footer-label label">countries</span>
                            <span className="section2-content-holder-right-content-footer-label label">cities</span>
                            <span className="section2-content-holder-right-content-footer-label label">islands</span>
                            <span className="section2-content-holder-right-content-footer-label label">businesses</span>
                            <span className="section2-content-holder-right-content-footer-label label">eateries</span>
                            <span className="section2-content-holder-right-content-footer-label label">parks</span>
                        </div>

                    </div>

                </div>
            </div>


            <div className="section-newsletter-signup-content-holder">
                <div className="section-newsletter-signup-content-holder-container">

                    <div className="section-newsletter-content-holder-left">
                        <div className="section-newsletter-content-holder-left-title-line">
                            Newsletter Preferences
                        </div>
                        <div className="section-newsletter-content-holder-left-content-line">
                            <p>Please let us know which types of content you would look forward to so we can customize
                                just for you.</p>
                        </div>
                        <div className="section-newsletter-content-holder-left-content-preferences">
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_suggestions"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterSuggestions}
                                    onChange={toggleNewsletterSuggestions}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Suggestions
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_did_you_know"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterDidYouKnow}
                                    onChange={toggleNewsletterDidYouKnow}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Did you know
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_popular"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterPopularDestinations}
                                    onChange={toggleNewsletterPopularDestinations}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Popular Destinations
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_overlooked"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterOftenOverlooked}
                                    onChange={toggleNewsletterOftenOverlooked}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Often Overlooked
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_travel_tips"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterTravelTips}
                                    onChange={toggleNewsletterTravelTips}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Travel Tips
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_ai_experimental"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterAIExperimental}
                                    onChange={toggleNewsletterAIExperimental}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    AI Experimental
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-newsletter-content-holder-right">
                        <div className="section-newsletter-content-holder-right-title-line">
                            Sign Up
                        </div>

                        <div className="section-newsletter-content-holder-right-input">
                            <TextField
                                id="fname"
                                label="First Name"
                                required
                                value={fname}
                                onChange={handleFnameChange}
                                variant="standard"
                                fullWidth
                            />
                        </div>

                        <div className="section-newsletter-content-holder-right-input">
                            <TextField
                                id="email"
                                label="Email"
                                required
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                variant="standard"
                                fullWidth
                            />
                        </div>

                        <div className="newsletter-signup-opt-in-checkbox-line-holder">
                            <div id="newsletter_signup_opt_in_checkbox_holder"
                                 className="newsletter-signup-opt-in-checkbox-holder">
                                <input
                                    id="newsletter_signup_opt_in_checkbox"
                                    className="newsletter-signup-opt-in-checkbox"
                                    type="checkbox"
                                    value={newsletterOptIn}
                                    onChange={toggleNewsletterOptIn}
                                />
                            </div>
                            <span className="newsletter-signup-opt-in-title">
                                    Yes, please sign me up for your email newsletter.
                                    I'm excited to stay updated on your latest news and offers!</span>
                        </div>

                        <div className="newsletter-signup-submit-button-holder">
                            <button
                                id="newsletter_signup_submit_button"
                                className="newsletter-signup-submit-button"
                                onClick={handleNewsletterSignup}>
                                Sign Up
                                <i id="newsletter_signup_submit_button_icon"
                                   className="newsletter-signup-submit-button-icon-check"></i>
                            </button>
                        </div>

                        <div id="newsletter_signup_submit_results_holder"
                             className="newsletter-signup-submit-results-holder">

                        </div>

                    </div>
                </div>
            </div>


            <div className="section3-content-holder">
                <div className="section3-content-holder-container">

                    <div className="section3-content-holder-left">
                        <div className="section3-content-holder-left-title-line">
                            How To Get There
                        </div>
                        <div className="section3-content-holder-left-content-line">
                            <p>Driving can be a blast! And you can see the route on the map.</p>
                            <p>Flights can be easily entered with just the Airline and the flight number, also showing
                                up
                                on the map.</p>
                        </div>
                        <div className="section3-content-holder-left-content-footer">
                            <span className="section3-content-holder-left-content-footer-label label">driving</span>
                            <span className="section3-content-holder-left-content-footer-label label">flying</span>
                            <span className="section3-content-holder-left-content-footer-label label">walking</span>
                            <span
                                className="section3-content-holder-left-content-footer-label label">receipt linking</span>
                        </div>
                    </div>

                    <div className="section3-content-holder-right">
                        <div className="section3-content-holder-right-content">
                            <img
                                className="section3-content-holder-right-content-img"
                                src={featuresMap}
                                alt=""
                            />
                        </div>
                    </div>

                </div>
            </div>


            <div className="section4-content-holder">
                <div className="section4-content-holder-container">

                    <div className="section4-content-holder-left">
                        <div className="section4-content-holder-left-content">

                            <div className="section4-content-holder-left-content-top-line">
                                <div className="section4-content-holder-left-content-icon-holder">
                                    <i className="icon-map-marker-user section4-content-holder-left-content-icon-holder-icon"
                                       title=""></i>
                                    <div className="section4-content-holder-left-content-icon-holder-title">Near Me
                                    </div>
                                </div>

                                <div className="section4-content-holder-left-content-icon-holder">
                                    <i className="icon-magnifier-move section4-content-holder-left-content-icon-holder-icon"
                                       title=""></i>
                                    <div className="section4-content-holder-left-content-icon-holder-title">Find
                                        Location
                                    </div>
                                </div>
                            </div>

                            <div className="section4-content-holder-left-content-bottom-line">
                                <div className="section4-content-holder-left-content-icon-holder">
                                    <i className="icon-notebook section4-content-holder-left-content-icon-holder-icon"
                                       title=""></i>
                                    <div className="section4-content-holder-left-content-icon-holder-title">Enter
                                        Address
                                    </div>
                                </div>

                                <div className="section4-content-holder-left-content-icon-holder">
                                    <i className="icon-pushpin2 section4-content-holder-left-content-icon-holder-icon"
                                       title=""></i>
                                    <div className="section4-content-holder-left-content-icon-holder-title">Tag Me</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="section4-content-holder-right">
                        <div className="section4-content-holder-right-title-line">
                            Where To Stay
                        </div>
                        <div className="section4-content-holder-right-content-line">
                            <p>There's no limit to places you can stay.</p>
                            <p>Planned Adventure makes it a breeze
                                to add lodging in almost any way imaginable, even if it's out under the stars.</p>
                        </div>
                        <div className="section4-content-holder-right-content-footer">
                            <span className="section4-content-holder-right-content-footer-label label">hotel</span>
                            <span className="section4-content-holder-right-content-footer-label label">vrbo</span>
                            <span className="section4-content-holder-right-content-footer-label label">airbnb</span>
                            <span className="section4-content-holder-right-content-footer-label label">hostel</span>
                            <span className="section4-content-holder-right-content-footer-label label">house</span>
                            <span className="section4-content-holder-right-content-footer-label label">tent</span>
                            <span className="section4-content-holder-right-content-footer-label label">camper</span>
                            <span className="section4-content-holder-right-content-footer-label label">directions</span>
                            <span
                                className="section4-content-holder-right-content-footer-label label">receipt linking</span>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section7-content-holder">
                <div className="section7-content-holder-container">

                    <div className="section7-content-holder-left">
                        <div className="section7-content-holder-left-title-line">
                            What To Do
                        </div>
                        <div className="section7-content-holder-left-content-line">
                            <p>Activities come in all shapes and sizes.</p>
                            <p>Our special IDEAS feature makes it fun and easy for everyone to get involved.
                                Add activities that everyone is excited about.</p>
                            <p>Adding Activities makes it easy for everyone to know what's going on each day, when to be
                                there,
                                and where to be. Google Directions are accessible from any Activity address just by
                                clicking on it.</p>
                        </div>
                        <div className="section7-content-holder-left-content-footer">
                            <span className="section7-content-holder-left-content-footer-label label">date/time</span>
                            <span className="section7-content-holder-left-content-footer-label label">ideas</span>
                            <span className="section7-content-holder-left-content-footer-label label">businesses</span>
                            <span className="section7-content-holder-left-content-footer-label label">locations</span>
                            <span className="section7-content-holder-left-content-footer-label label">events</span>
                            <span
                                className="section7-content-holder-left-content-footer-label label">entertainment</span>
                            <span className="section7-content-holder-left-content-footer-label label">attendees</span>
                            <span className="section7-content-holder-left-content-footer-label label">directions</span>
                            <span
                                className="section7-content-holder-left-content-footer-label label">receipt linking</span>
                        </div>
                    </div>

                    <div className="section7-content-holder-right">
                        <div className="section7-content-holder-right-content">

                            <div className="event-ideas-list-items-list-item-holder">
                                <div className="event-idea-details-title-holder">
                                    <i className="icon-lamp-bright event-idea-list-item-details-attendee-icon"></i>
                                    <i className="icon-fork-knife2 event-idea-list-item-details-category-icon"
                                       title=""></i>
                                    La Fontaine de BelleVille
                                </div>
                                <div className="event-idea-details-url-holder">
                                    <i className="mi-language event-idea-list-item-details-icon"></i>
                                    <div className="event-idea-details-url-link-holder">http://lafontaineparis.fr/
                                    </div>
                                </div>
                                <div className="event-idea-details-address-holder">
                                    <i className="icon-address-book event-idea-list-item-details-address-icon"></i>
                                    <div className="event-idea-details-address-actual-holder">
                                        <div className="">La Fontaine</div>
                                        <div className="">31-33 Rue Juliette Dodu</div>
                                        <div className="">Paris France 75010</div>
                                    </div>
                                    <div className="event-idea-details-phone-actual-holder">
                                        <div className="">+33 9 83 54 55 20</div>
                                    </div>
                                </div>
                                <div className="event-idea-details-comments-window">
                                    <div id="event_idea_details_comments_title"
                                         className="event-idea-details-comments-label label">Comments
                                    </div>
                                    <div id="event_idea_details_comments_holder"
                                         className="event-idea-details-comments-holder">
                                        <div className="event-idea-details-comment-item-holder">
                                            <div className="event-idea-details-comment-item-text">Good views and people
                                                watching
                                            </div>
                                            <div className="event-idea-details-comment-item-owner">Kels</div>
                                            <div className="event-idea-details-comment-item-timestamp">Aug 30 11:22 PM
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="event-activities-item-day-event-holder">
                                <i className="icon-list-heart event-activities-item-day-event-holder-activities-icon"
                                   title=""></i>
                                <div className="event-card-event-activity-item-day-header">1:00 PM - 4:00 PM</div>
                                <div className="event-card-event-activity-item-name-holder">Caverns</div>
                                <div className="event-card-event-activity-item-location-holder">
                                    <div className="event-card-event-activity-item-location-name-holder">Tuckaleechee
                                        Caverns
                                    </div>
                                    <div className="event-card-event-activity-item-activity-address-holder">825 Cavern
                                        Road
                                    </div>
                                    <div
                                        className="event-card-event-activity-item-activity-city-state-zip-holder">Townsend
                                        TN 37882
                                    </div>
                                    <div className="event-card-event-activity-item-phone-holder">+1 865-448-2274</div>
                                    <i className="icon-paste event-card-event-activity-item-location-copy" title=""></i>
                                </div>
                                <div className="event-card-event-activity-item-attendee-list-holder">
                                    <span className="event-card-event-activity-item-attendee-list-item-holder">
                                    <i className="icon-group event-card-event-activity-item-attendee-list-item-icon" title=""></i>
                                    <span className="event-card-event-activity-item-attendee-list-item-count">11</span></span>
                                </div>
                                <div className="event-card-event-activity-item-receipt-item-holder"></div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div className="section6-content-holder">
                <div className="section6-content-holder-container">

                    <div className="section6-content-holder-left">
                        <div className="section6-content-holder-left-content">

                            <div className="section6-content-holder-left-content-item-holder">

                                <div className="event-supplies-attendee-list-subcategory-holder">
                                    <span
                                        className="event-supplies-attendee-list-item-category-line-category label">Accessories</span>
                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                                checked
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">phone charger
                                                </div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">watch</div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">Watch charger
                                                </div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="section6-content-holder-left-content-item-holder">

                                <div className="event-supplies-attendee-list-subcategory-holder">
                                    <span
                                        className="event-supplies-attendee-list-item-category-line-category label">Bathroom</span>
                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">deodorant</div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon"
                                               title=""></i>
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                                checked
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">hairbrush</div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon"
                                               title=""></i>
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                                checked
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">razor</div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon"
                                               title=""></i>
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                                checked
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">shaving cream
                                                </div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon"
                                               title=""></i>
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">toothbrush</div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon"
                                               title=""></i>
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>

                                    <div className="event-supplies-attendee-list-subcategory-item-holder">
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-checkbox-status-holder">
                                            <input
                                                className="event-supplies-attendee-list-subcategory-item-top-line-checkbox"
                                                type="checkbox"
                                            />
                                        </div>
                                        <div
                                            className="event-supplies-attendee-list-subcategory-item-center-content-holder">
                                            <div
                                                className="event-supplies-attendee-list-subcategory-item-top-line-holder">
                                                <div className="event-supplies-attendee-list-item-name">toothpaste</div>
                                            </div>
                                        </div>
                                        <div className="event-supplies-attendee-list-subcategory-item-edit-icon-holder">
                                            <i className="icon-eye-cross event-supplies-attendee-list-item-category-line-private-icon"
                                               title=""></i>
                                            <i className="icon-heart2 event-supplies-attendee-list-item-category-line-essential-icon"
                                               title=""></i>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="section6-content-holder-right">
                        <div className="section6-content-holder-right-title-line">
                            What To Take
                        </div>
                        <div className="section6-content-holder-right-content-line">
                            <p>Tools so you won't end up missing anything.</p>
                            <p>Using our Essentials feature you can keep a list at the ready for each new Event.</p>
                            <p>Privacy mode keeps list items visible only to you.</p>
                        </div>
                        <div className="section6-content-holder-right-content-footer">
                            <span className="section6-content-holder-right-content-footer-label label">food</span>
                            <span className="section6-content-holder-right-content-footer-label label">supplies</span>
                            <span className="section6-content-holder-right-content-footer-label label">category</span>
                            <span
                                className="section6-content-holder-right-content-footer-label label">subcategory</span>
                            <span className="section6-content-holder-right-content-footer-label label">can assign</span>
                            <span
                                className="section6-content-holder-right-content-footer-label label">privacy mode</span>
                            <span className="section6-content-holder-right-content-footer-label label">essentials</span>
                            <span
                                className="section6-content-holder-right-content-footer-label label">receipt linking</span>
                        </div>

                    </div>

                </div>
            </div>


            <div className="section5-content-holder">
                <div className="section5-content-holder-container">

                    <div className="section5-content-holder-title-line">
                        <div className="section5-content-holder-title-line-title">
                            Where To Eat
                        </div>
                        <div className="section5-content-holder-title-line-content">
                            <p>Sometimes you want to cook. Sometimes you want to eat out. Other times you want
                                take-out.</p>
                            <p>All of these are perfectly fine and can be added in a matter of seconds.</p>
                        </div>
                        <div className="section5-content-holder-title-line-footer">
                            <span className="section5-content-holder-title-line-footer-label label">date/time</span>
                            <span className="section5-content-holder-title-line-footer-label label">eateries</span>
                            <span className="section5-content-holder-title-line-footer-label label">deliveries</span>
                            <span className="section5-content-holder-title-line-footer-label label">i'm cooking</span>
                            <span className="section5-content-holder-title-line-footer-label label">directions</span>
                            <span
                                className="section5-content-holder-title-line-footer-label label">receipt linking</span>
                        </div>
                    </div>

                    <div className="section5-content-holder-content-line">

                        <div className="section5-content-holder-content-line-item-holder">
                            <div className="event-meals-item-day-event-holder">
                                <div className="event-card-event-meal-item-day-header-meal-name">10:00 AM brunch</div>
                                <div className="event-card-event-meal-item-location-holder">
                                    <div className="event-card-event-meal-item-location-name-holder">NBC Sports
                                        Grill &amp; Brew™
                                    </div>
                                    <div className="event-card-event-meal-item-details-holder">
                                        <div className="event-card-event-meal-item-details-holder-label label">
                                            Details
                                        </div>
                                        <div className="pointer">
                                            <div className="event-card-event-meal-item-meal-address-holder">6000
                                                Universal Boulevard
                                            </div>
                                            <div
                                                className="event-card-event-meal-item-meal-city-state-zip-holder">Orlando
                                                FL 32819
                                            </div>
                                            <i className="icon-paste event-card-event-meal-item-location-copy"
                                               title="Copy to Clipboard"></i>
                                        </div>
                                        <div className="event-card-event-meal-item-phone-holder pointer">+1 407-224-3663
                                        </div>
                                    </div>
                                    <div className="event-meals-add-meal-eatery-details-eatin">Dine in
                                    </div>
                                </div>
                                <div className="event-card-event-meal-item-receipt-item-holder">
                                    <i className="icon-receipt event-card-event-meal-item-receipt-item-icon"></i>
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-content-line-item-holder">
                            <div className="event-meals-item-day-event-holder">
                                <div className="event-card-event-meal-item-day-header-meal-name">2:30 PM lunch</div>
                                <div className="event-card-event-meal-item-location-holder">
                                    <div className="event-card-event-meal-item-location-name-holder">Baldinos Giant
                                        Jersey Subs
                                    </div>
                                    <div className="event-card-event-meal-item-details-holder">
                                        <div className="event-card-event-meal-item-details-holder-label label">
                                            Details
                                        </div>
                                        <div className="pointer">
                                            <div className="event-card-event-meal-item-meal-address-holder">1022 U.S.
                                                80
                                            </div>
                                            <div
                                                className="event-card-event-meal-item-meal-city-state-zip-holder">Pooler
                                                GA 31322
                                            </div>
                                            <i className="icon-paste event-card-event-meal-item-location-copy"
                                               title="Copy to Clipboard"></i>
                                        </div>
                                        <div className="event-card-event-meal-item-phone-holder pointer">+1 912-748-7827
                                        </div>
                                    </div>
                                    <div className="event-meals-add-meal-eatery-details-eatin">Dine in
                                    </div>
                                </div>
                                <div className="event-card-event-meal-item-receipt-item-holder">
                                    <i className="icon-receipt event-card-event-meal-item-receipt-item-icon"></i>
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-content-line-item-holder">
                            <div className="event-meals-item-day-event-holder">
                                <div className="event-card-event-meal-item-day-header-meal-name">7:00 PM dinner</div>
                                <div className="event-card-event-meal-item-location-holder">
                                    <div className="event-card-event-meal-item-location-name-holder">Back At Airbnb
                                    </div>
                                    <div className="event-card-event-meal-item-details-holder">
                                        <div className="event-card-event-meal-item-details-holder-label label">
                                            Details
                                        </div>
                                        <div className="event-card-event-meal-item-details-holder-text">
                                            Baked potato bar
                                            <br />Broccoli and cauliflower medley
                                            <br />Beer
                                            <br />Wine
                                        </div>
                                    </div>
                                </div>
                                <div className="event-card-event-meal-item-receipt-item-holder"></div>
                            </div>
                        </div>
                    </div>


                    <div className="section5-content-holder-bottom-tray">

                        <div className="section5-content-holder-bottom-tray-item-holder">
                            <div className="event-meals-item-day-events-empty-item-holder">
                                <div className="event-meals-add-meal-names-item-holder">
                                    <i className="icon-plus event-meals-add-meal-names-item-holder-add-icon"
                                       title=""></i>
                                    <div className="event-meals-add-meal-names-item-holder-selected"></div>
                                    <i className="icon-fried-egg event-meals-add-meal-names-item-holder-icon"
                                       title=""></i>
                                    <div className="event-meals-add-meal-names-item-holder-title">Breakfast</div>
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-bottom-tray-item-holder">
                            <div className="event-meals-add-meal-names-item-holder">
                                <i className="icon-plus event-meals-add-meal-names-item-holder-add-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-selected"></div>
                                <i className="icon-toast event-meals-add-meal-names-item-holder-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-title">Brunch
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-bottom-tray-item-holder">
                            <div className="event-meals-add-meal-names-item-holder">
                                <i className="icon-plus event-meals-add-meal-names-item-holder-add-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-selected"></div>
                                <i className="icon-burger event-meals-add-meal-names-item-holder-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-title">Lunch
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-bottom-tray-item-holder">
                            <div className="event-meals-add-meal-names-item-holder">
                                <i className="icon-plus event-meals-add-meal-names-item-holder-add-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-selected"></div>
                                <i className="icon-apple event-meals-add-meal-names-item-holder-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-title">Snack
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-bottom-tray-item-holder">
                            <div className="event-meals-add-meal-names-item-holder">
                                <i className="icon-plus event-meals-add-meal-names-item-holder-add-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-selected"></div>
                                <i className="icon-platter event-meals-add-meal-names-item-holder-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-title">Dinner
                                </div>
                            </div>
                        </div>

                        <div className="section5-content-holder-bottom-tray-item-holder">
                            <div className="event-meals-add-meal-names-item-holder">
                                <i className="icon-plus event-meals-add-meal-names-item-holder-add-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-selected"></div>
                                <i className="icon-ice-cream event-meals-add-meal-names-item-holder-icon" title=""></i>
                                <div className="event-meals-add-meal-names-item-holder-title">Dessert
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section8-content-holder">
                <div className="section8-content-holder-container">

                    <div className="section8-content-holder-left">
                        <div className="section8-content-holder-left-content">

                            <div className="event-todos-list-items-list-item-holder">
                                <div className="event-todo-list-item-left-side-holder">
                                    <div
                                        className="event-todo-list-item-category-icon-holder todo-priority-urgent-icon-color">
                                        <i className="icon-alarm2 event-todo-list-item-category-icon" title=""></i>
                                    </div>
                                    <span
                                        className="event-todo-list-item-right-side-time-holder">7:00 AM  - 7:15 AM</span>
                                </div>
                                <div className="event-todo-list-item-right-side-title-content-holder">
                                    <div className="event-todo-list-item-right-side-title-holder">pack suitcase</div>
                                    <div className="event-todo-list-item-right-side-notes-holder">Make sure it doesn't
                                        weigh more than 50lbs
                                    </div>
                                </div>
                                <div className="event-todo-list-item-right-side-reminders-holder">
                                    <i className="icon-bell-ringing2 todo-dropdown-icon-normal-icon-color event-todo-list-item-right-side-reminder-icon"
                                       title=""></i>
                                    <div className="event-todo-list-item-right-side-reminders-title">Scheduled Reminders
                                        :
                                    </div>
                                    <div className="event-todo-list-item-right-side-reminders-day-of">Day of : 6:45 AM
                                    </div>
                                </div>
                                <div className="event-todo-list-item-right-side-bottom-tray-holder">
                                    <div
                                        className="event-todo-list-item-right-side-bottom-tray-assignee-holder">Robert
                                    </div>
                                    <span className="event-todo-list-item-right-side-bottom-tray-checkbox-holder">
                                        Done
                                        <input
                                            className="event-todo-list-item-right-side-bottom-tray-checkbox pointer"
                                            type="checkbox"
                                        />
                                    </span>
                                </div>
                            </div>

                            <div className="event-todos-list-items-list-item-holder">
                                <div className="event-todo-list-item-left-side-holder">
                                    <div
                                        className="event-todo-list-item-category-icon-holder todo-priority-high-icon-color">
                                        <i className="icon-alarm2 event-todo-list-item-category-icon" title=""></i>
                                    </div>
                                    <span
                                        className="event-todo-list-item-right-side-time-holder">8:00 AM  - 8:15 AM</span>
                                </div>
                                <div className="event-todo-list-item-right-side-title-content-holder">
                                    <div className="event-todo-list-item-right-side-title-holder">dog to kennel</div>
                                    <div className="event-todo-list-item-right-side-notes-holder">Bring all paperwork
                                        and leash
                                    </div>
                                </div>
                                <div className="event-todo-list-item-right-side-reminders-holder">
                                    <i className="icon-bell-ringing2 todo-dropdown-icon-normal-icon-color event-todo-list-item-right-side-reminder-icon"
                                       title=""></i>
                                    <div className="event-todo-list-item-right-side-reminders-title">Scheduled Reminders
                                        :
                                    </div>
                                    <div className="event-todo-list-item-right-side-reminders-day-of">Day of : 7:30 AM
                                    </div>
                                </div>
                                <div className="event-todo-list-item-right-side-bottom-tray-holder">
                                    <div className="event-todo-list-item-right-side-bottom-tray-assignee-holder">Sammy
                                    </div>
                                    <span className="event-todo-list-item-right-side-bottom-tray-checkbox-holder">
                                        Done
                                        <input
                                            className="event-todo-list-item-right-side-bottom-tray-checkbox pointer"
                                            type="checkbox"
                                        />
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="section8-content-holder-right">
                        <div className="section8-content-holder-right-title-line">
                            To-Do & Reminders
                        </div>
                        <div className="section8-content-holder-right-content-line">
                            <p>Things can get hectic!</p>
                            <p>Use To-Do to keep a list of things that need to get done before, during, or after an
                                Event.</p>
                            <p>With each To-Do item you can schedule Reminders that use PUSH notifications or SMS
                                messaging.
                                Reminders can be scheduled up to 5 days before any To-Do item is scheduled.</p>
                        </div>
                        <div className="section8-content-holder-right-content-footer">
                            <span className="section8-content-holder-right-content-footer-label label">date/time</span>
                            <span className="section8-content-holder-right-content-footer-label label">category</span>
                            <span className="section8-content-holder-right-content-footer-label label">can assign</span>
                            <span
                                className="section8-content-holder-right-content-footer-label label">priority mode</span>
                            <span
                                className="section8-content-holder-right-content-footer-label label">privacy mode</span>
                            <span
                                className="section8-content-holder-right-content-footer-label label">notifications</span>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section9-content-holder">
                <div className="section9-content-holder-container">

                    <div className="section9-content-holder-left">
                        <div className="section9-content-holder-left-title-line">
                            Messaging
                        </div>
                        <div className="section9-content-holder-left-content-line">
                            <p>With each Event you can add SMS messaging, for a separate small fee.</p>
                            <p>Instantly create group messaging without the need for you to do it on your phone.
                                A unique number assigned to your Event, automatically released a week after Event
                                ends.</p>
                            <p>All communication stays with the Event indefinitely.</p>
                        </div>
                        <div className="section9-content-holder-left-content-footer">
                            <span className="section9-content-holder-left-content-footer-label label">group chat</span>
                            <span className="section9-content-holder-left-content-footer-label label">no setup for attendees</span>
                            <span className="section9-content-holder-left-content-footer-label label">privacy</span>
                            <span className="section9-content-holder-left-content-footer-label label">rsvp</span>
                            <span
                                className="section9-content-holder-left-content-footer-label label">notifications</span>
                            <span className="section9-content-holder-left-content-footer-label label">reminders</span>
                        </div>
                    </div>

                    <div className="section9-content-holder-right">
                        <div className="section9-content-holder-right-content">
                            <img
                                className="section9-content-holder-right-content-img"
                                src={featuresMessaging}
                                alt=""
                            />
                        </div>
                    </div>

                </div>
            </div>


            <div className="section10-content-holder">
                <div className="section10-content-holder-container">

                    <div className="section10-content-holder-left">
                        <div className="section10-content-holder-left-content">

                            <div className="event-attendees-edit-item-holder-notifications-holder">
                                <div className="event-attendees-edit-item-holder-notifications-holder-label label">Event
                                    Notifications
                                </div>
                                <div className="event-attendees-edit-item-holder-notifications-description">
                                    Set the notification preferences for Event.
                                </div>
                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Activities</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Messages</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Supplies</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Meals</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Lodging</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Flights</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Days Leading To
                                            Event</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">To-Do</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                                <div className="event-notification-item-holder">
                                    <div className="event-notification-item-holder-label-holder">
                                        <label className="event-notification-item-holder-label">Daily</label>
                                    </div>
                                    <input className="event-notification-item-holder-checkbox" type="checkbox" checked />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="section10-content-holder-right">
                        <div className="section10-content-holder-right-title-line">
                            Notifications
                        </div>
                        <div className="section10-content-holder-right-content-line">
                            <p>Attendees choose Notification preferences.</p>
                            <p>Can use PUSH notifications or SMS if the Event has SMS services.</p>
                            <p>Attendees can change Notification preferences for each Event.</p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section11-content-holder">
                <div className="section11-content-holder-container">

                    <div className="section11-content-holder-left">
                        <div className="section11-content-holder-left-title-line">
                            Photos
                        </div>
                        <div className="section11-content-holder-left-content-line">
                            <p>Uploaded photos are automatically shared with all Attendees.</p>
                            <p>Everyone is able to upload their photos making it easy to capture different moments.</p>
                        </div>
                    </div>

                    <div className="section11-content-holder-right">
                        <div className="section11-content-holder-right-content">
                            <img
                                className="section11-content-holder-right-content-img"
                                src={featuresPhotos}
                                alt=""
                            />
                        </div>
                    </div>

                </div>
            </div>


            <div className="section12-content-holder">
                <div className="section12-content-holder-container">

                    <div className="section12-content-holder-left">
                        <div className="section12-content-holder-left-content">

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">5</i>
                                <i className="icon-ruby event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">General</div>
                                <div className="event-receipts-category-item-holder-totals">$182.93
                                </div>
                            </div>

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">2</i>
                                <i className="icon-theater event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">Activities</div>
                                <div className="event-receipts-category-item-holder-totals">$70.00
                                </div>
                            </div>

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">2</i>
                                <i className="icon-airplane event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">Flights</div>
                                <div className="event-receipts-category-item-holder-totals">$422.97
                                </div>
                            </div>

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">1</i>
                                <i className="icon-bed event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">Lodging</div>
                                <div className="event-receipts-category-item-holder-totals">$359.78
                                </div>
                            </div>

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">6</i>
                                <i className="icon-fork-knife2 event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">Meals</div>
                                <div className="event-receipts-category-item-holder-totals">$310.25
                                </div>
                            </div>

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">7</i>
                                <i className="icon-tag2 event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">Shopping</div>
                                <div className="event-receipts-category-item-holder-totals">$89.98
                                </div>
                            </div>

                            <div className="event-receipts-category-item-holder">
                                <i className="event-receipts-category-item-holder-count" title="">4</i>
                                <i className="icon-bag event-receipts-category-item-holder-icon" title=""></i>
                                <div className="event-receipts-category-item-holder-title">Supplies</div>
                                <div className="event-receipts-category-item-holder-totals">$173.66
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="section12-content-holder-right">
                        <div className="section12-content-holder-right-title-line">
                            Budgeting & Receipts
                        </div>
                        <div className="section12-content-holder-right-content-line">
                            <p>Keep track of all expenses and receipts.</p>
                            <p>The easiest way to keep track of costs and receipts. Including a photo of the receipt or
                                a PDF file
                                is a great way to store for later reference.</p>
                        </div>
                        <div className="section12-content-holder-left-content-footer">
                            <span
                                className="section12-content-holder-left-content-footer-label label">expense tracking</span>
                            <span className="section12-content-holder-left-content-footer-label label">category</span>
                            <span
                                className="section12-content-holder-left-content-footer-label label">photo of receipts</span>
                            <span
                                className="section12-content-holder-left-content-footer-label label">pdf receipts</span>
                            <span
                                className="section12-content-holder-left-content-footer-label label">receipt linking</span>
                            <span
                                className="section12-content-holder-left-content-footer-label label">privacy mode</span>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )

}

export default FeaturesContent;
