import React from 'react';




function CenterContentHome(){


    return (

        <div>Center Content Home</div>

    )



}

export default CenterContentHome;
