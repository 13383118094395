
export const updateChipStyle = {
    marginLeft: '10px',
    boxShadow: '0 0 5px #5E9F3D',
    border: '1px solid #5E9F3D',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#caffb5', // Reverse the gradient on hover
    },
};

export const updateGradientStyle = {
    marginLeft: '10px',
    boxShadow: 2,
    borderRadius: 2,
    textAlign: 'center',
    fontWeight: '700',
    background: 'linear-gradient(195deg, #e8f2ed, #abd154)',
    border: 0, // Remove border (optional)
    color: 'black', // Text color (adjust as needed)
};
