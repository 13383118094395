import React from 'react';

import {Grid} from "@mui/material";
import SignupContent from "./SignupContent";




function Signup() {



    return (
        <Grid container spacing={12}>
            <SignupContent />
        </Grid>
    )
}

export default Signup;
