import React, {useContext, useState} from 'react';

import {useNavigate} from "react-router-dom";
import {PAContext} from "../../Services/PAContext";

import './websiteUpdated.css'

import et_collage from '../../graphics/ET-COLLAGE.jpg';
import todoReminder_new from '../../graphics/todoReminder_new.png';
import ideasNewGrey from '../../graphics/ideas-new-grey.png';
import itineraryNew from '../../graphics/itinerary_new.png';
import mapNew from '../../graphics/map-new.png';
import suppliesNew from '../../graphics/supplies-new.png';
import collaborateNew from '../../graphics/collaborate-new.png';

import travelMassive from '../../graphics/travelMassive.png';
import productHunt from '../../graphics/productHunt.png';

import brevo from '../../graphics/brevo.png';
import duffel from '../../graphics/duffel.png';
import growCo from '../../graphics/growco.png';
import generator from '../../graphics/generator.png';
import visualCrossing from '../../graphics/visualCrossing.png';

import newColorsDashboard from '../../graphics/new-colors-dashboard.png';
import newColorsItinerary from '../../graphics/new-colors-itinerary.png';

import london from '../../graphics/london.jpg';
import londonMobile from '../../graphics/london-mobile.jpg';
import frankfurt from '../../graphics/frankfurt.jpg';
import frankfurtMobile from '../../graphics/frankfurt-mobile.jpg';
import parisApartments from '../../graphics/paris-apartments.jpg';
import parisApartmentsMobile from '../../graphics/paris-apartments-mobile.jpg';
import dublin from '../../graphics/dublin.jpg';
import dublinMobile from '../../graphics/dublin-mobile.jpg';

import instagramLogo from '../../graphics/Instagram_Glyph_Black.png';
import linkedInLogo from '../../graphics/LI-In-Bug.png';
import facebookLogo from '../../graphics/Facebook_Logo_Primary.png';

import './home.css';
import {TextField} from "@mui/material";




function HomeContent(props) {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);

    const [ newsletterSuggestions, setNewsletterSuggestions] = useState(false);
    const [ newsletterDidYouKnow, setNewsletterDidYouKnow] = useState(false);
    const [ newsletterPopularDestinations, setNewsletterPopularDestinations] = useState(false);
    const [ newsletterOftenOverlooked, setNewsletterOftenOverlooked] = useState(false);
    const [ newsletterTravelTips, setNewsletterTravelTips] = useState(false);
    const [ newsletterAIExperimental, setNewsletterAIExperimental] = useState(false);

    const [ fname, setFname] = useState("");
    const [ email, setEmail] = useState("");
    const [ newsletterOptIn, setNewsletterOptIn] = useState(false);


    function handleLoginClick(event){

    }

    function handleNewsletterSignup(event){

    }

    function toggleNewsletterSuggestions(){
        setNewsletterSuggestions(!newsletterSuggestions);
    }
    function toggleNewsletterDidYouKnow(){
        setNewsletterDidYouKnow(!newsletterDidYouKnow);
    }
    function toggleNewsletterPopularDestinations(){
        setNewsletterPopularDestinations(!newsletterPopularDestinations);
    }
    function toggleNewsletterOftenOverlooked(){
        setNewsletterOftenOverlooked(!newsletterOftenOverlooked);
    }
    function toggleNewsletterTravelTips(){
        setNewsletterTravelTips(!newsletterTravelTips);
    }
    function toggleNewsletterAIExperimental(){
        setNewsletterAIExperimental(!newsletterAIExperimental);
    }

    function handleFnameChange(event){
        setFname(event.target.value);
    }
    function handleEmailChange(event){
        setEmail(event.target.value);
    }
    function toggleNewsletterOptIn(){
        setNewsletterOptIn(!newsletterOptIn);
    }


    return (

        <div id="page-container" className="page-content-background-rectangle">

            <div id="content_area" className="area-travel-made-easy">
                <div className="area-travel-made-easy-badge-holder">
                    <a href='https://www.travelmassive.com/posts/planned-adventure-302043152?ref=plannedadventure'>
                        <img
                            style={{width:'333px', height:'73px'}}
                            src='https://travelmassive.io/badges/white_award/302043152.svg'
                            alt='Planned Adventure featured on Travel Massive'
                        />
                    </a>
                </div>
                <div id="left_container" className="area-travel-made-easy-left-container">
                    <div id="top_content_area" className="top-content-area">
                        <div id="content_header" className="content-header">
                            <div className="content-header-banner-title-holder">
                                Travel made easy. <br />
                            </div>
                            <div className="content-header-banner-subtitle-holder">
                                The only app you need for your next great adventure.
                            </div>
                        </div>
                    </div>

                    <div className="tagline">
                        <div id="content_tagline" className="content-tagline">Build, organize, and map your itineraries
                            for travels near and far - all in one place.
                        </div>
                    </div>

                    <div className="get-started">
                        <div className="get-started-holder">
                            <button className="get-started-button" onClick={handleLoginClick}>Get Started
                                for Free
                            </button>
                        </div>
                    </div>
                </div>

                <div id="right_container" className="area-travel-made-easy-right-container">
                    <div className="ET-holder">
                        <img src={et_collage} alt="" />
                    </div>
                </div>
            </div>

            <div className="area-ai-powered">
                <div className="ai-statement">
                    AI-powered travel planning: The future of travel is here.
                    <i className="icon-robot" />
                </div>
            </div>

            <div className="area-promo-video">
                <div className="promo-video-holder">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/l4P1CAXLrJs"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                    </iframe>
                </div>

                <div className="promo-video-content">
                    <div className="promo-video-header">One app. Endless adventures.</div>
                    <div className="promo-video-subheader">Switching between multiple apps, tabs, and tools is a thing of the past.</div>
                </div>
            </div>

            <div className="area-features">
                <div className="features-features">
                    <div className="features-title">Features that kick all your other tools to the curb.</div>
                </div>
                <div className="features-list">
                    <div className="features-row1">
                        <div className="features-box1-column1">
                            <div className="box-1">
                                <div className="todos-image">
                                    <img src={todoReminder_new} alt="" />
                                </div>
                                <div className="features-box-title">To-dos and Reminders</div>
                                <div className="features-box-subtitle">Todos and reminders help you stay organized and on schedule.</div>
                            </div>
                        </div>
                        <div className="features-box1-column2">
                            <div className="box-1">
                                <div className="ideas-image">
                                    <img src={ideasNewGrey} alt="" />
                                </div>
                                <div className="features-box-title">Ideas and Collaborating</div>
                                <div className="features-box-subtitle">An easy way to reach a consensus on what to do.</div>
                            </div>
                        </div>
                        <div className="features-box1-column3">
                            <div className="box-1">
                                <div className="itinerary-image">
                                    <img src={itineraryNew} alt="" />
                                </div>
                                <div className="itinerary-box-title">Your whole Itinerary</div>
                                <div className="features-box-subtitle">All important aspects of each day right at your fingertips.</div>
                            </div>
                        </div>
                    </div>
                    <div className="features-row2">
                        <div className="features-box2-column1">
                            <div className="box-2">
                                <div className="map-image">
                                    <img src={mapNew} alt="" />
                                </div>
                                <div className="map-box-title">Map It</div>
                                <div className="features-box-subtitle">See the entire itinerary on a map and easily get directions with one click.</div>
                            </div>
                        </div>
                        <div className="features-column-2">
                            <div className="features-box2-column2">
                                <div className="box-2">
                                    <div className="ideas-image">
                                        <img src={suppliesNew} alt="" />
                                    </div>
                                    <div className="supplies-box-title">Supplies and Essentials</div>
                                    <div className="features-box-subtitle">Supplies specific to your next trip and pull in essentials that never change.</div>
                                </div>
                            </div>
                        </div>
                        <div className="features-column-3">
                            <div className="features-box2-column3">
                                <div className="box-2">
                                    <div className="itinerary-image">
                                        <img src={collaborateNew} alt="" />
                                    </div>
                                    <div className="collaboration-box-title">Collaboration in Real Time</div>
                                    <div className="features-box-subtitle">Stay in touch and close the gaps all in real time.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="get-started">
                    <div className="get-started-holder">
                        <button className="get-started-button" onClick={handleLoginClick}>Get Started for Free</button>
                    </div>
                </div>
            </div>

            <div className="area-travel-agents">
                <div className="area-travel-agents-features">
                    <div className="travelAgents-title">Pro for Travel Agents.</div>
                    <div className="travelAgents-subtitle">Give your customers an all new travel experience.</div>
                    <div className="features-row">
                        <div className="features-box1-column1">
                            <div className="box-1">
                                <div className="todos-image"><img src={itineraryNew} alt="" /></div>
                                <div className="features-box-title">Provide essential information</div>
                                <div className="features-box-subtitle">Give your customers everything they need in an organized way - packing lists, notes, suggestions, and more.</div>
                            </div>
                        </div>
                        <div className="features-box1-column2">
                            <div className="box-1">
                                <div className="ideas-image"><img src={collaborateNew} alt="" /></div>
                                <div className="features-box-title">Easily Communicate</div>
                                <div className="features-box-subtitle">An easy way to communicate with each other.</div>
                            </div>
                        </div>
                        <div className="features-box1-column3">
                            <div className="box-1">
                                <div className="itinerary-image"><img src={itineraryNew} alt="" /></div>
                                <div className="itinerary-box-title">Store past Itineraries</div>
                                <div className="features-box-subtitle">Reuse past itineraries, hotels, restaurants, and activities to make things easier.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="area-reviews">
                <div className="reviews-features">
                    <div className="reviews-header">Why we do what we do.</div>
                    <div id="testimonials_area" className="testimonials-area">
                        <div className="testimonials-row">
                            <div className="testimonials-column1">
                                <div className="testimonials-title">5 Stars!</div>
                                <div className="testimonials-label">
                                    <p>"I love that Planned Adventure puts everything for travel in one place;
                                        I can see flight info, packing list, lodging, and plans for myself and anyone
                                        I am traveling with."</p>
                                    <p>"By far, my favorite part is the packing list and being able to create an essentials list.
                                    No need to keep making the same list over and over again,
                                        I just add what is special for that particular trip!"</p>
                                </div>
                                <div className="testimonials-sublabel">Sadie E.</div>
                            </div>
                            <div className="testimonials-column2">
                                <div className="testimonials-title">All in one place!</div>
                                <div className="testimonials-label1">
                                    <p>"I love being able to keep all the details of a gathering or vacation in one central place
                                        and getting updates sent directly to my phone."</p>
                                    <p>"It is easy to set up as a host and delegate to-do lists
                                        directly to everyone who is attending."</p>
                                </div>
                                <div className="testimonials-sublabel"> Megan H.</div>
                            </div>
                            <div className="testimonials-column3">
                                <div className="testimonials-title">Nailed it!</div>
                                <div className="testimonials-label1">
                                    <p>"Collaboration is sooo simple!"</p>
                                    <p>"Planning a large group trip is hard enough without having to get everyone's opinion and feedback."</p>
                                    <p>"Planned Adventure makes it a breeze and is fun!"</p>
                                </div>
                                <div className="testimonials-sublabel"> Kristen L.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="area-featured-in">
                <div className="featured-in-title-holder">
                    <div className="featured-in-title">Featured in</div>
                    <div className="featured-in-title-color-box"></div>
                </div>
                <div className="featured-in-icon-sets">
                    <div className="featured-in-icon">
                        <a href="https://www.travelmassive.com/posts/planned-adventure-302043152" target="_blank">
                            <img src={travelMassive} alt="" />
                        </a>
                    </div>
                    <div className="featured-in-icon2">
                        <a href="https://www.producthunt.com/products/planned-adventure" target="_blank">
                            <img src={productHunt} alt="" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="area-helps-in-success">
                <div className="success-with-title-holder">
                    <div className="success-with-title">Who helps with our success</div>
                    <div className="success-with-title-color-box"></div>
                </div>
                <div className="success-with-icon-sets">
                    <div className="success-with-icon"><img src={brevo} alt="" /></div>
                    <div className="success-with-icon"><img src={duffel} alt="" /></div>
                    <div className="success-with-icon"><img src={visualCrossing} alt="" /></div>
                    <div className="success-with-icon"><img src={growCo} alt="" /></div>
                    <div className="success-with-icon"><img src={generator} alt="" /></div>
                </div>
            </div>

            <div className="area-pa-app-container">
                <div className="area-pa-app-text-holder-left">
                    <div className="area-pa-app-title">Planning on the go made easy with our app.</div>
                    <div className="area-pa-app-subtitle">Use our travel app on Android, iOS, and web to have access
                        to your trip no matter where life takes you.
                    </div>
                    <div className="banner-get-started">
                        <div className="banner-get-started-holder">
                            <button className="banner-get-started-button" onClick={handleLoginClick}>Get
                                Started for Free
                            </button>
                        </div>
                    </div>
                </div>
                <div className="area-pa-app-holder-right">
                    <div className="experience-images-holder">
                        <div className="experience-image">
                            <img src={newColorsDashboard} alt="" />
                        </div>
                        <div className="experience-image2">
                            <img src={newColorsItinerary} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="destinations-title-container">
                <div className="destinations-title">Discover your next awesome adventure!</div>
            </div>

            <div className="destinations-container">
                <div className="destinations-images-holder">
                    <div className="destination-image"><img src={london} alt="" /></div>
                    <div className="destination-image-mobile"><img src={londonMobile} alt="" /></div>
                    <div className="destination-image"><img src={frankfurt} alt="" /></div>
                    <div className="destination-image-mobile"><img src={frankfurtMobile} alt="" /></div>
                    <div className="destination-image"><img src={parisApartments} alt="" /></div>
                    <div className="destination-image-mobile"><img src={parisApartmentsMobile} alt="" /></div>
                    <div className="destination-image"><img src={dublin} alt="" /></div>
                    <div className="destination-image-mobile"><img src={dublinMobile} alt="" /></div>
                </div>
                <div className="destination-get-started">
                    <div className="destination-get-started-holder">
                        <button className="destination-get-started-button" onClick={handleLoginClick}>What are you waiting for?</button>
                    </div>
                </div>
            </div>

            <div className="section-newsletter-signup-content-holder">
                <div className="section-newsletter-signup-content-holder-container">

                    <div className="section-newsletter-content-holder-left">
                        <div className="section-newsletter-content-holder-left-title-line">
                            Newsletter Preferences
                        </div>
                        <div className="section-newsletter-content-holder-left-content-line">
                            <p>Please let us know which types of content you would look forward to so we can customize
                                just for you.</p>
                        </div>
                        <div className="section-newsletter-content-holder-left-content-preferences">
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_suggestions"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterSuggestions}
                                    onChange={toggleNewsletterSuggestions}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Suggestions
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_did_you_know"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterDidYouKnow}
                                    onChange={toggleNewsletterDidYouKnow}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Did you know
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_popular"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterPopularDestinations}
                                    onChange={toggleNewsletterPopularDestinations}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Popular Destinations
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_overlooked"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterOftenOverlooked}
                                    onChange={toggleNewsletterOftenOverlooked}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Often Overlooked
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_travel_tips"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterTravelTips}
                                    onChange={toggleNewsletterTravelTips}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    Travel Tips
                                </div>
                            </div>
                            <div className="section-newsletter-content-holder-left-content-preference-item-holder">
                                <input
                                    id="newsletter_preferences_ai_experimental"
                                    className="section-newsletter-content-holder-left-content-preference-item-checkbox"
                                    type="checkbox"
                                    value={newsletterAIExperimental}
                                    onChange={toggleNewsletterAIExperimental}
                                />
                                <div
                                    className="section-newsletter-content-holder-left-content-preference-item-title">
                                    AI Experimental
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-newsletter-content-holder-right">
                        <div className="section-newsletter-content-holder-right-title-line">
                            Sign Up
                        </div>

                        <div className="section-newsletter-content-holder-right-input">
                            <TextField
                                id="fname"
                                label="First Name"
                                required
                                value={fname}
                                onChange={handleFnameChange}
                                variant="standard"
                                fullWidth
                            />
                        </div>

                        <div className="section-newsletter-content-holder-right-input">
                            <TextField
                                id="email"
                                label="Email"
                                required
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                variant="standard"
                                fullWidth
                            />
                        </div>

                        <div className="newsletter-signup-opt-in-checkbox-line-holder">
                            <div id="newsletter_signup_opt_in_checkbox_holder"
                                 className="newsletter-signup-opt-in-checkbox-holder">
                                <input
                                    id="newsletter_signup_opt_in_checkbox"
                                    className="newsletter-signup-opt-in-checkbox"
                                    type="checkbox"
                                    value={newsletterOptIn}
                                    onChange={toggleNewsletterOptIn}
                                />
                            </div>
                            <span className="newsletter-signup-opt-in-title">
                                    Yes, please sign me up for your email newsletter.
                                    I'm excited to stay updated on your latest news and offers!</span>
                        </div>

                        <div className="newsletter-signup-submit-button-holder">
                            <button
                                id="newsletter_signup_submit_button"
                                className="newsletter-signup-submit-button"
                                onClick={handleNewsletterSignup}>
                                Sign Up
                                <i id="newsletter_signup_submit_button_icon"
                                   className="newsletter-signup-submit-button-icon-check"></i>
                            </button>
                        </div>

                        <div id="newsletter_signup_submit_results_holder"
                             className="newsletter-signup-submit-results-holder">

                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-parent text-muted text-center">
                <div className="footer-right-side">
                    <div className="footer-links"><a href="https://plannedadventure.com/features.html">Features</a></div>
                    <div className="footer-links"><a href="/privacy.html">Privacy</a></div>
                    <div className="footer-links"><a href="/tos.html">Terms of Use</a></div>
                </div>
                <div className="footer-left-side">
                    <div className="footer-social-icons-holder">
                        <div className="footer-instagram-icon">
                            <a href="https://instagram.com/planned.adventure?igshid=NzZlODBkYWE4Ng==">
                                <img src={instagramLogo} alt="" />
                            </a>
                        </div>
                        <div className="footer-linkedin-icon">
                            <a href="src/Pages/HomePage/HomeContent" target="_blank">
                                <img src={linkedInLogo} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="footer-facebook-icon">
                        <a href="src/Pages/HomePage/HomeContent" target="_blank">
                            <img src={facebookLogo} alt="" />
                        </a>
                    </div>
                </div>
                <div id="websocket_connection_status" className="copyright">© Planned Adventure 2023</div>
            </div>
        </div>

    )

}

export default HomeContent;
