import React, {useContext, useEffect, useState} from 'react';

import {PAContext} from "../../../Services/PAContext";
import {Chip} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import './receipt.css';
import Badge from "@mui/material/Badge";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ReceiptCard from "./ReceiptCard";
import {PieChart} from "@mui/x-charts/PieChart";



function ReceiptList(props){

    const { dataArray } = useContext(PAContext);
    const { Account, Planning } = dataArray;

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const [eventReceipts, setEventReceipts] = useState([]);
    const [filteredReceipts, setFilteredReceipts] = useState([]);


    useEffect(() => {
        if (Planning.receipts) {
            filterReceipts();
        }
    }, [Planning.receipts, selectedAttendee]);

    const handleChipClick = (_attendee_id) => {
        console.log("Show receipts for : "+_attendee_id);
        setSelectedAttendee(_attendee_id);
    }

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        return '';
    }

    const attendeesWithReceiptCounts = Planning.attendees.map((attendee) => {
        const receiptCount = Planning.receipts.reduce((count, item) =>
            count + ((item.attendee_id === attendee.id && (!item.private || item.attendee_id === Account.id)) ? 1 : 0), 0
        );
        return { ...attendee, receiptCount };
    });
    const attendeesWithReceipts = attendeesWithReceiptCounts.filter(attendee => attendee.receiptCount > 0);
    const attendeesWithoutReceipts = attendeesWithReceiptCounts.filter(attendee => attendee.receiptCount === 0);
    attendeesWithReceipts.sort((a, b) => b.receiptCount - a.receiptCount);
    attendeesWithoutReceipts.sort((a, b) => a.nickname.localeCompare(b.nickname));
    const sortedAttendees = [...attendeesWithReceipts, ...attendeesWithoutReceipts];


    const filterReceipts = () => {
        console.log("filterReceipts for : "+selectedAttendee);

        const filteredItems = Planning.receipts.filter(receipt =>
            receipt.attendee_id === selectedAttendee && (!receipt.private || receipt.attendee_id === Account.id)
        );

        console.log("setting the filterReceipts to : "+filteredItems.length);

        setEventReceipts(filteredItems);
        setFilteredReceipts(filteredItems); // Initialize with all filtered receipts
    };

    const filterReceiptsByCategory = (category) => {
        const filteredByCategory = eventReceipts.filter(receipt => receipt.section === category);
        setFilteredReceipts(filteredByCategory);
    };

    const categories = [
        'General',
        'Lodging',
        'Meals',
        'Activities',
        'Supplies',
        'Shopping',
        'Flights',
    ];

    const categoryIcons = {
        General: 'icon-ruby',
        Activities: 'icon-theater',
        Flights: 'icon-airplane',
        Meals: 'icon-fork-knife2',
        Lodging: 'icon-bed',
        Shopping: 'icon-tag2',
        Supplies: 'icon-bag',
    };

    const getCategoryCounts = () => {
        const counts = categories.reduce((acc, category) => {
            acc[category] = 0;
            return acc;
        }, {});

        eventReceipts.forEach((receipt) => {
            counts[receipt.section] = (counts[receipt.section] || 0) + 1;
        });

        return counts;
    };

    const getSortedCategories = () => {
        const counts = getCategoryCounts();

        return categories.sort((a, b) => counts[b] - counts[a]);
    };



    const aggregateData = () => {
        const dataMap = {};

        filteredReceipts.forEach(receipt => {
            const section = receipt.section;
            const amount = receipt.amount;

            if (dataMap[section]) {
                dataMap[section] += amount;
            } else {
                dataMap[section] = amount;
            }
        });

        return dataMap;
    };

    const pieChartData = Object.entries(aggregateData()).map(([section, amount]) => ({
        name: section,
        value: amount,
        label: `${section}: $${amount.toFixed(2)}`
    }));



    return (

        <div className="receipts-list-container">

            {/* show the header */}
            <div className="receipts-list-header">
                <div className="receipts-list-header-text">
                    <h5>Receipts List</h5>
                </div>
                <div className="receipts-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                </div>
            </div>

            {/* Attendee List */}
            <div className="receipts-list-attendee-list-container">
                {sortedAttendees.length > 0 ? (
                    sortedAttendees.map((attendee, index) => (
                        <Badge
                            key={index}
                            badgeContent={attendee.receiptCount > 0 ? attendee.receiptCount : null}
                            sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'white',
                                    border: '1px solid #ddd',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '50%',
                                    top: '10px',
                                    right: '10px',
                                },
                            }}
                        >
                            <Chip
                                sx={selectedAttendee === attendee.id ? selectedChipStyle : unselectedChipStyle}
                                label={capitalizeFirstLetter(attendee.nickname)}
                                clickable
                                onClick={() => handleChipClick(attendee.id)}
                                style={{margin: '0.5rem'}}
                            />
                        </Badge>
                    ))
                ) : (
                    <div style={{padding: '20px'}}>
                        <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                        <Typography variant="caption">Need to add some Attendees first</Typography>
                    </div>
                )}
            </div>


            {/* Category List */}
            <div style={{display: 'flex', overflowX: 'auto'}}>
                {getSortedCategories().map((category) => (
                    <div key={category} style={{marginRight: '16px'}}>
                        <Badge
                            badgeContent={getCategoryCounts()[category]}
                            color="primary"
                            sx={{
                                '& .MuiBadge-badge': {
                                    minWidth: 'auto',
                                    height: 'auto',
                                    padding: '0 6px',
                                    fontSize: '0.75rem',
                                    lineHeight: '1',
                                    transform: 'translateX(-25%)',
                                },
                            }}
                        >
                            <IconButton onClick={() => filterReceiptsByCategory(category)}>
                                <i className={categoryIcons[category]}/>
                            </IconButton>
                        </Badge>
                    </div>
                ))}
            </div>


            {/* now show the pie chart */}
            <div>
                <PieChart
                    series={[
                        {
                            data: pieChartData,
                            outerRadius: 50,
                        },
                    ]}
                    width={350}
                    height={200}
                    legend={{
                        position: { vertical: 'middle', horizontal: 'right' },
                        layout: 'vertical',  // Arrange the legend items vertically
                    }}
                />
            </div>


            {/* Receipts List */}
            <div className="receipts-list-items-container">
                {filteredReceipts && filteredReceipts.length > 0 ? (
                    filteredReceipts.map((receipt) => (
                        <ReceiptCard key={receipt.id} receipt={receipt}/>
                    ))
                ) : (
                    <Typography variant="caption">No receipts available for this category.</Typography>
                )}
            </div>

        </div>
    )
}

export default ReceiptList;