import React from 'react';


function ActivitiesAdd() {


    return (
        <>Add Activity</>
    )

}

export default ActivitiesAdd;
