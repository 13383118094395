
import React, {useState} from 'react';

import {Container} from "@mui/material";

import './planning.css';
import PlannerContent from "./PlannerContent";



function Planner(props) {

    const [lookingAt, setLookingAt] = useState('Planner');
    const [zoomInOn, setZoomInOn] = useState({});

    return (

        <Container
            sx={{
                maxWidth: '100vw !important',
                padding: '0 !important'
            }}
        >

            <PlannerContent
                lookingAt={lookingAt}
                isSearching={props.isSearching}
                searchingFor={props.searchingFor}
                zoomInOn={zoomInOn}
            />

        </Container>

    )

}

export default Planner;
