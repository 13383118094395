
class TimeTable {
    constructor() {

        this.id = "";
        this.days = [];
        /*
            so this can look like
            [
                {'day 1': 'Thursday', 'all-day':true, 'notes':''},
                {'day 2': 'Friday', 'all-day':true, 'notes':''},
                {'day 3': 'Saturday', 'all-day':true, 'notes':''},
                {'day 4': 'Sunday', 'all-day':true, 'notes':''},
                {'day 5': 'Monday', 'all-day':true, 'notes':''},
            ]
             - or -
            [
                {'day 1': 'Friday', 'all-day':false, 'begin-time': '9:00 AM', 'end-time': '5:00 PM', 'notes':''},
            ]
             - or -
            [
                {'day 1': '', 'all-day':false, 'begin-time': '5:00 PM', 'notes':''},
                {'day 2': '', 'all-day':true, 'notes':''},
                {'day 3': '', 'all-day':false, 'end-time': '2:00 PM', 'notes':''},
            ]

         */

        this.dates = [];
        /*
            this can look like
            [
                {'date':'2023-12-16', 'dayOfWeek':'Thursday', 'all-day':true, 'notes':''},
                {'date':'2023-12-17', 'dayOfWeek':'Friday', 'all-day':true, 'notes':''},
                {'date':'2023-12-18', 'dayOfWeek':'Saturday', 'all-day':true, 'notes':''},
                {'date':'2023-12-19', 'dayOfWeek':'Sunday', 'all-day':true, 'notes':''},
                {'date':'2023-12-20', 'dayOfWeek':'Monday', 'all-day':true, 'notes':''},
            ]
             - or -
            [
               {'date':'2023-12-16', 'dayOfWeek':'Friday', 'all-day':false, 'begin-time': '9:00 AM', 'end-time': '5:00PM', 'notes':''},
            ]
             - or -
            [
                {'date':'2023-12-16', 'dayOfWeek':'Friday', 'all-day':false, 'begin-time': '5:00 PM', 'notes':''},
                {'date':'2023-12-17', 'dayOfWeek':'Saturday', 'all-day':true, 'notes':''},
                {'date':'2023-12-18', 'dayOfWeek':'Sunday', 'all-day':false, 'end-time': '2:00 PM', 'notes':''},
            ]
         */

    }
}

export default TimeTable;
