
class PALocation{

    constructor(){
        this.google_place_id = "";
        this.latitude = 0;
        this.longitude = 0;

        this.name = "";
        this.formatted_address = "";
        this.address = "";
        this.city = "";
        this.state = "";
        this.zip = "";
        this.country = "";
    }

}

export default PALocation;
