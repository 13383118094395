
import React, {useState} from 'react';

import {Container} from "@mui/material";
import HomeContent from "./HomeContent";
import './home.css';



function Home(props) {

    const [lookingAt, setLookingAt] = useState('home');
    const [zoomInOn, setZoomInOn] = useState({});

    return (

        <Container
            sx={{
                maxWidth: '100vw !important',
                padding: '0 !important'
            }}
        >

            <HomeContent
                lookingAt={lookingAt}
                setIsSearching={props.setIsSearching}
                setSearchingFor={props.setSearchingFor}
                setZoomInOn={setZoomInOn}
            />

        </Container>

    )

}

export default Home;
