import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Card, CardHeader, Chip, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import "./attendees.css";
import {cancelGradientStyle} from "../../ButtonStyle/Cancel";
import {addGradientStyle} from "../../ButtonStyle/Add";
import {addWaitGradientStyle} from "../../ButtonStyle/AddWait";


function AttendeesAddPrevious() {

    const navigate = useNavigate();

    const { dataArray, setPlanning } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [sortedAttendees, setSortedAttendees] = useState([]);

    useEffect(() => {
        // Sort the attendees by lname whenever the Attendees array changes

        try {
            setSortedAttendees([...Attendees]
                .filter((_att) => {
                    return !Planning.attendees.some((planningAtt) => planningAtt.id === _att.id );
                })
                .sort((attendeeA, attendeeB) => {
                    const lnameA = attendeeA.lname.toLowerCase();
                    const lnameB = attendeeB.lname.toLowerCase();

                    // Compare the lname values
                    if (lnameA < lnameB) return -1;
                    if (lnameA > lnameB) return 1;
                    return 0;
                }));
        } catch (e){
            console.log(e);
        }

    }, [Attendees]);

    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    function handlePromptChangeAddPreviousClose() {
        navigate('/Planner/Attendees')
    }

    const [selectedAttendees, setSelectedAttendees] = useState([]);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: 2,
                borderRadius: 2,
                textAlign: 'center',
                fontWeight: '700',
                background: `linear-gradient(195deg, #FFFFFF, ${stringToColor(name)})`,
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function toggleAttendeeSelection(attendee_id){
        console.log("Clicked on index : "+attendee_id);

        // Check if the attendee is already selected
        const isSelected = selectedAttendees.some((selected) => selected.id === attendee_id);

        console.log("Selected ? : "+isSelected);

        if (isSelected) {
            // If selected, remove from the selectedAttendees array
            setSelectedAttendees(selectedAttendees.filter((selected) => selected.id !== attendee_id));
        } else {
            // If not selected, add to the selectedAttendees array
            setSelectedAttendees([...selectedAttendees, sortedAttendees.find((_att) => _att.id === attendee_id)]);
        }

    }

    function handleAttendeesOK() {
        console.log("handleAttendeesOK");

        console.log("the selectedAttendees : "+JSON.stringify(selectedAttendees, null, '\t'));

        console.log("Planning : "+JSON.stringify(Planning, null, '\t'));
        if (Object.keys(Planning).length === 0){

            console.log("Creating new Event");

            //create a new Event
            const newEvent = new PAEvent();
            newEvent.title = "A New Event";

            for (const _att of selectedAttendees){
                console.log("adding : "+JSON.stringify(_att));

                _att.balance = Math.floor(Math.random() * 2000);

                if (newEvent.attendees.length === 0){
                    _att.role = "admin";
                }

                newEvent.attendees.push(_att);
            }

            setPlanning(newEvent);
            console.log("after setPlanning");
        } else {
            for (const _att of selectedAttendees){
                const attendeeExists = Planning.attendees.some((planningAtt) => planningAtt.id  === _att.id );

                if (!attendeeExists) {
                    console.log("adding : " + JSON.stringify(_att));

                    _att.balance = Math.floor(Math.random() * 2000);

                    if (Planning.attendees.length === 0){
                        _att.role = "admin";
                    }

                    Planning.attendees.push(_att);
                }
            }
        }

        console.log("Planning : "+JSON.stringify(Planning.attendees, null, '\t'));

        navigate('/Planner/Attendees');

    }



    return (

        <div className="attendee-list-container" >
            {/* show the header */}
            <div
                className="attendee-list-header"
            >
                <div className="attendee-list-header-text">
                    <h5>Previous Attendees</h5>
                </div>
                <div className="attendee-list-header-chip-holder">
                    <Chip
                        sx={cancelGradientStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptChangeAddPreviousClose}
                    />
                    <Chip
                        sx={selectedAttendees.length===0 ? addWaitGradientStyle : addGradientStyle}
                        label={selectedAttendees.length===0?'Add': 'Add '+selectedAttendees.length}
                        size="small"
                        onClick={selectedAttendees.length===0 ? undefined : handleAttendeesOK}
                    />
                </div>
            </div>


            {/* show the list of sample attendees */}
            <div className="attendee-list-items-container">

                <Grid direction="column">

                {
                    sortedAttendees.map((attendee, index) => (

                        <Grid item key={index} sx={{display:'flex'}}>

                            <Card
                                sx={{cursor:'pointer'}}
                                className="attendee-list-item"
                                onClick={() => toggleAttendeeSelection(attendee.id)}
                            >
                                <CardHeader
                                    sx={{
                                        padding:0
                                    }}
                                    avatar={<Avatar {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)} />}
                                    title={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                    subheader={attendee.email}
                                    action={
                                        selectedAttendees.some((selected) => selected.id === attendee.id) ? (
                                            <i className="icon-check attendee-list-item-add-item-checkmark" />
                                        ) : (
                                            <i className="attendee-list-item-add-item-no-checkmark" />
                                        )
                                    }

                                />
                            </Card>

                        </Grid>
                    ))
                }
                </Grid>
            </div>

        </div>

    )
}

export default AttendeesAddPrevious;
