import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent, timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import Typography from "@mui/material/Typography";


function FlightCard(props) {

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    const [flight, setFlight] = useState(props.flight);

    useEffect(() => {
        if (props.flight) {
            setFlight(props.flight);
        }
    }, [props.flight]);


    const formatTimestamp = (timestamp, timezone) => {
        const date = new Date(timestamp);

        // Use Intl.DateTimeFormat to format the time in the correct timezone
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,  // to get 12-hour format with AM/PM
            timeZoneName: 'short' // to get timezone abbreviation like "EDT"
        });

        return formatter.format(date);
    };



    return (

        <Timeline sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
            },
        }}>
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="caption" color="textSecondary">
                        Depart
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="success">
                        <i className="icon-airplane" style={{ fontSize: '1rem' }} />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <Typography variant="subtitle2" color="textSecondary">
                        {formatTimestamp(flight.depart_timestamp, flight.depart_timezone)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {(flight.depart_airport_name)} ({flight.depart_airport_code})
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {(flight.airline_name)} ({flight.flight_number})
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        {(flight.flight_duration)}
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="caption" color="textSecondary">
                        Arrive
                    </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                    <TimelineDot color="success">
                        <i className="icon-airplane" style={{ fontSize: '1rem', transform: 'rotate(90deg)' }} />
                    </TimelineDot>
                </TimelineSeparator>

                <TimelineContent>
                    <Typography variant="subtitle2" color="textSecondary">
                        {formatTimestamp(flight.arrive_timestamp, flight.arrive_timezone)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {(flight.arrive_airport_name)} ({flight.arrive_airport_code})
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>

    );

}

export default FlightCard;
