
import React, {useContext} from "react";


import LoginContent from "./LoginContent";





function Login() {

    return (

        <LoginContent />
    )

}

export default Login;
