import React from 'react';


function ActivitiesEdit() {


    return (
        <>Edit Activity</>
    )

}

export default ActivitiesEdit;
