import dayjs from "dayjs";


export function WeatherUsingVisualCrossing(Planning) {

    console.log("WeatherDetails : Need to pull weather report");

    if (Planning && Planning?.time_table?.dates?.length > 0
        && Planning?.lodging?.some(lodge => lodge.location && lodge.location.latitude && lodge.location.longitude)) {
        //get the weather data

        // Extract begin_date and end_date from time_table.dates
        const event_begin_date = Planning.time_table.dates[0].date; // First date in the time table
        const event_end_date = Planning.time_table.dates[Planning.time_table.dates.length - 1].date; // Last date in the time table

        //set up the different sections
        const _search_data_event_locations = [];

        // Add weather data for each lodging location
        Planning.lodging.forEach(lodge => {

            const lodgeBeginDate = lodge.time_table.dates[0].date; // First date in the time table
            const lodgeEndDate = lodge.time_table.dates[lodge.time_table.dates.length - 1].date; // Last date in the time table

            if (lodge.location.latitude !== 0 && lodge.location.longitude !== 0) {
                const lodge_weather_location = {
                    city: lodge.location.city,
                    state: lodge.location.state,
                    location_type: "latlng",
                    latitude: lodge.location.latitude,
                    longitude: lodge.location.longitude,
                    unit_group: (lodge.location.country === "US" ? "us" : "metric"),
                    begin_date: dayjs(lodgeBeginDate).format('YYYY-MM-DD'),
                    end_date: dayjs(lodgeEndDate).format('YYYY-MM-DD')
                };
                _search_data_event_locations.push(lodge_weather_location);

            } else if (lodge.location && lodge.location.city && lodge.location.state) {
                const lodgeBeginDate = lodge.time_table.dates[0].date; // First date in the time table
                const lodgeEndDate = lodge.time_table.dates[lodge.time_table.dates.length - 1].date; // Last date in the time table

                const lodge_weather_location = {
                    city: lodge.location.city,
                    state: lodge.location.state,
                    location_type: "address",
                    location: `${lodge.location.address} ${lodge.location.city} ${lodge.location.state} ${lodge.location.zip}`,
                    unit_group: (lodge.location.country === "US" ? "us" : "metric"),
                    begin_date: dayjs(lodgeBeginDate).format('YYYY-MM-DD'),
                    end_date: dayjs(lodgeEndDate).format('YYYY-MM-DD')
                };
                _search_data_event_locations.push(lodge_weather_location);
            }


        });

        console.log("_search_data_event_locations: ", JSON.stringify(_search_data_event_locations, null, 2));


        _search_data_event_locations.map((_weather_location) => {
            //pulling weather for

            const _visualCrossingBase = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/";
            const _visualCrossingTimelineService = "timeline/";
            const _search_location = (_weather_location.location_type==="address"
                ? _weather_location.location+"/"
                : (_weather_location.latitude+","+_weather_location.longitude)+"/");
            const _search_begin_date = _weather_location.begin_date+"/";
            const _search_end_date = _weather_location.end_date;

            let _search_string = "";

            console.log("days until : "+Math.ceil(dayjs(event_begin_date).diff(dayjs(), "days", true)));
            console.log("days after : "+Math.ceil(dayjs().diff(dayjs(event_end_date), "days", true)));

            let _in_event = false;
            if (Math.ceil(dayjs(event_begin_date).diff(dayjs(), "days", true)) <= 0
                && Math.ceil(dayjs().diff(dayjs(event_end_date), "days", true)) < 1) {
                _in_event = true;
            }

            if (_in_event) {
                //get hourly data
                console.log("Getting the hourly data : in Event");
                _search_string = _visualCrossingBase+_visualCrossingTimelineService+_search_location+_search_begin_date+_search_end_date
                    +"?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&unitGroup="+_weather_location.unit_group;
            } else {
                //just get daily numbers
                console.log("Getting the daily data");
                _search_string =_visualCrossingBase+_visualCrossingTimelineService+_search_location+_search_begin_date+_search_end_date
                    +"?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&include=days&unitGroup="+_weather_location.unit_group;
            }

            fetch(_search_string.replaceAll(" ", "%20"), {
                method: 'GET',
                headers: {

                },

            }).then(response => {

                if (!response.ok) {

                    throw response; //check the http response code and if isn't ok then throw the response as an error
                }

                return response.json(); //parse the result as JSON

            }).then(response => {
                //response now contains parsed JSON ready for use
                console.log("the weather response : "+JSON.stringify(response));
                _weather_location.report = response;

                return true;
            }).catch((errorResponse) => {

                console.log("error response : "+errorResponse);
                console.log("error response code : "+errorResponse.status);

                if (errorResponse.text) { //additional error information
                    errorResponse.text().then( errorMessage => {
                        //errorMessage now returns the response body which includes the full error message
                        console.log("Weather error : "+errorMessage);

                        //                   showWeatherDataErrorForEventWeather(_container);

                    })
                } else {
                    //no additional error information
                }

                return false;
            });

        });

        Planning.weather = _search_data_event_locations;


        /*

                //for the Event location
                const _search_data_event_location = {};
                if (Planning.location.latitude !== 0 && Planning.location.longitude !== 0) {
                    _search_data_event_location.location_type = "latlng";
                    _search_data_event_location.latitude = Planning.location.latitude;
                    _search_data_event_location.longitude = Planning.location.longitude;
                } else if (Planning.location.city !== "" && Planning.location.state !== "") {
                    _search_data_event_location.location_type = "address";
                    _search_data_event_location.location = Planning.location.address + " " + Planning.location.city + " " + Planning.location.state + " " + Planning.location.zip;
                }

                if (_search_data_event_location.location_type !== undefined) {

                    _search_data_event_location.unit_group = (Planning.location.country === "US" ? "us" : "metric");
                    _search_data_event_location.begin_date = dayjs(Planning.begin_date).format('YYYY-MM-DD');
                    _search_data_event_location.end_date = dayjs(Planning.end_date).format('YYYY-MM-DD');

                    //add to the master list
                    event_weather_locations.push({
                        "city": Planning.location.city,
                        "state": Planning.location.state
                    });
                }

         */

        //then be adding for lodging and attendees home locations



/*

        console.log("event_weather_locations : " + JSON.stringify(_search_data_event_location, null, '\t'));

        //don't pull the weather if nothing has changed
        //compare location and dates with any existing weather data


        const _visualCrossingBase = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/";
        const _visualCrossingTimelineService = "timeline/";
        const _search_location = (_search_data_event_location.location_type==="address"
            ? _search_data_event_location.location+"/"
            : (_search_data_event_location.latitude+","+_search_data_event_location.longitude)+"/");
        const _search_begin_date = _search_data_event_location.begin_date+"/";
        const _search_end_date = _search_data_event_location.end_date;

        let _search_string = "";

        console.log("days until : "+Math.ceil(dayjs(Planning.begin_date).diff(dayjs(), "days", true)));
        console.log("days after : "+Math.ceil(dayjs().diff(dayjs(Planning.end_date), "days", true)));

        let _in_event = false;
        if (Math.ceil(dayjs(Planning.begin_date).diff(dayjs(), "days", true)) <= 0
            && Math.ceil(dayjs().diff(dayjs(Planning.end_date), "days", true)) < 1) {
            _in_event = true;
        }

        if (_in_event) {
            //get hourly data
            console.log("Getting the hourly data : in Event");
            _search_string = _visualCrossingBase+_visualCrossingTimelineService+_search_location+_search_begin_date+_search_end_date
                +"?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&unitGroup="+_search_data_event_location.unit_group;
        } else {
            //just get daily numbers
            console.log("Getting the daily data");
            _search_string =_visualCrossingBase+_visualCrossingTimelineService+_search_location+_search_begin_date+_search_end_date
                +"?key=HQTW7H7BS2KSSU4Z3LTWMETCV&contentType=json&include=days&unitGroup="+_search_data_event_location.unit_group;
        }

        fetch(_search_string.replaceAll(" ", "%20"), {
            method: 'GET',
            headers: {

            },

        }).then(response => {

            if (!response.ok) {

                throw response; //check the http response code and if isn't ok then throw the response as an error
            }

            return response.json(); //parse the result as JSON

        }).then(response => {
            //response now contains parsed JSON ready for use
            console.log("the weather response : "+JSON.stringify(response));

            Planning.weather = response;
            return true;
        }).catch((errorResponse) => {

            console.log("error response : "+errorResponse);
            console.log("error response code : "+errorResponse.status);

            if (errorResponse.text) { //additional error information
                errorResponse.text().then( errorMessage => {
                    //errorMessage now returns the response body which includes the full error message
                    console.log("Weather error : "+errorMessage);

                    //                   showWeatherDataErrorForEventWeather(_container);

                })
            } else {
                //no additional error information
            }

            return false;
        });
*/
        return true;
    } else {
        return false;
    }

}

export default WeatherUsingVisualCrossing;
