import TimeTable from "./TimeTable";

class TourRadarTour {

    constructor() {
        this.id = 0;
        this.is_active = true;
        this.tour_name = "";
        this.description = "";

        this.images = [];

        this.time_table = new TimeTable();

        this.language = "";
        this.locale = "";

        this.operator = {};
        this.age_range = {};

        this.max_group_size = 0;
        this.tour_length_days = 0;

        this.ratings = {};

        this.destinations = {};
        this.start_city = {};
        this.end_city = {};

        this.prices = {};
        this.budget = "";

        this.tour_types = [];
        this.itinerary = [];
        this.links = [];

    }

}

export default TourRadarTour;
