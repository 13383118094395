import React, {useContext, useEffect, useRef, useState} from 'react';

import {Chip, Grid, ListItem, Paper} from "@mui/material";
import AttendeeCard from "./AttendeeCard";
import {PAContext} from "../../../Services/PAContext";
import Badge from "@mui/material/Badge";
import {addGradientStyle} from "../../ButtonStyle/Add";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";



function AttendeesList(props) {

    const { dataArray } = useContext(PAContext);
    const { AdminsActions } = dataArray;

    const [allAttendees, setAllAttendees] = useState(props.attendeesList);

    useEffect(() => {

        try {
            //get the master list of Attendees
            const _sendString = {};
            _sendString.action = "adminServicesGetMasterAttendeeList";
            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
        } catch (e){}
    }, []);


    useEffect(()=> {

        console.log("AdminsActions : "+AdminsActions);
        for (let i = AdminsActions.length - 1; i >= 0; i--) {
            const _incoming = AdminsActions[i];
            console.log('Action:', _incoming.action);

            switch (_incoming.action) {
                case "adminServicesGetMasterAttendeeListResults":
                    setAllAttendees(_incoming.attendee_list);

                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);

                    break;
                default:
                // Handle other cases if needed
            }

        }
    }, [AdminsActions]);


    // Function to group attendees by the first letter of their last name
    const groupByLastNameInitial = (allAttendees) => {
        return allAttendees.reduce((acc, attendee) => {
            const initial = attendee.fname.charAt(0).toUpperCase();
            if (!acc[initial]) {
                acc[initial] = 0;
            }
            acc[initial] += 1;
            return acc;
        }, {});
    };

    const groupedAttendees = groupByLastNameInitial(allAttendees);


    const [selectedGroup, setSelectedGroup] = useState("All");
    const selectedChipRef = useRef(null);

    const handleChipClick = (_group) => {
        console.log("setting the view to : "+_group);
        setSelectedGroup(_group);
    };

    const [ sortedAndFilteredAttendees, setSortedAndFilteredAttendees] = useState([]);
    useEffect(() => {
        console.log("updating the SortedAndFilteredAttendees");
        setSortedAndFilteredAttendees(getSortedAndFilteredAttendees());

    }, [selectedGroup, allAttendees]);



    const sortAlphabetically = (attendees) => {
        return [...attendees].sort((a, b) => a.fname.localeCompare(b.fname));
    };

    const sortChronologicallyAsc = (attendees) => {
        return [...attendees].sort((a, b) => b.created - a.created);
    };

    const sortChronologicallyDesc = (attendees) => {
        return [...attendees].sort((a, b) => a.created - b.created);
    };

    const filterByInitial = (attendees, initial) => {
        return attendees.filter(_att => _att.fname.charAt(0).toLowerCase() === initial.toLowerCase());
    };

    const getSortedAndFilteredAttendees = () => {
        if (selectedGroup === "All") {
            return sortAlphabetically(allAttendees);
        } else if (selectedGroup === "All-Asc") {
            return sortChronologicallyAsc(allAttendees);
        } else if (selectedGroup === "All-Desc") {
            return sortChronologicallyDesc(allAttendees);
        } else if (selectedGroup.length === 1) {
            return sortAlphabetically(filterByInitial(allAttendees, selectedGroup));
        }
        return [];
    };


    return (

        <Box>

                <Paper style={{ height: '100%' }}>

                    <Badge
                        badgeContent={allAttendees.length}
                        color="primary"
                        max={999999}
                    >
                        <h3>Attendees</h3>
                    </Badge>


                    {
                        /*
                            put the different filtering/sorting options here
                         */

                        <Box
                            display="flex"
                            overflow="auto" // Enable horizontal scrolling
                            p={2}
                            sx={{
                                whiteSpace: 'nowrap', // Prevent wrapping of child elements
                                maxWidth: '100%' // Ensure it doesn't exceed container width
                            }}
                        >
                            <List
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    p: 0,
                                    minWidth: 'max-content' // Ensure List takes minimum width required
                                }}
                            >

                                {
                                    /*
                                        add the button to sort Attendees chronologically ASC
                                     */
                                }
                                <ListItem key='123abc' sx={{ width: 'auto', p: 1 }}>
                                    <Chip
                                        ref={selectedGroup === "All-Asc" ? selectedChipRef : null}
                                        sx={selectedGroup === "All-Asc" ? selectedChipStyle : unselectedChipStyle}
                                        label="Newest"
                                        clickable
                                        onClick={() => handleChipClick("All-Asc")}
                                        style={{ margin: '0.5rem' }}
                                    />
                                </ListItem>
                                <ListItem key='123abcd' sx={{ width: 'auto', p: 1 }}>
                                    <Chip
                                        ref={selectedGroup === "All-Desc" ? selectedChipRef : null}
                                        sx={selectedGroup === "All-Desc" ? selectedChipStyle : unselectedChipStyle}
                                        label="Oldest"
                                        clickable
                                        onClick={() => handleChipClick("All-Desc")}
                                        style={{ margin: '0.5rem' }}
                                    />
                                </ListItem>

                                {
                                    /*
                                        add the button to show all Attendees
                                     */
                                }
                                <ListItem key='123abcde' sx={{ width: 'auto', p: 1 }}>
                                    <Badge
                                        badgeContent={allAttendees.length}
                                        color="primary"
                                        max={999999}
                                    >
                                        <Chip
                                            ref={selectedGroup === "All" ? selectedChipRef : null}
                                            sx={selectedGroup === "All" ? selectedChipStyle : unselectedChipStyle}
                                            label="All"
                                            clickable
                                            onClick={() => handleChipClick("All")}
                                            style={{ margin: '0.5rem' }}
                                        />
                                    </Badge>
                                </ListItem>

                                {
                                    Object.keys(groupedAttendees)
                                        .sort()
                                        .map((initial, _index) => (
                                            <ListItem key={_index} sx={{ width: 'auto', p: 1 }}>
                                                <Badge badgeContent={groupedAttendees[initial]} color="primary">
                                                    <Chip
                                                        ref={selectedGroup === initial ? selectedChipRef : null}
                                                        sx={selectedGroup === initial ? selectedChipStyle : unselectedChipStyle}
                                                        label={initial}
                                                        clickable
                                                        onClick={() => handleChipClick(initial)}
                                                        style={{ margin: '0.5rem' }}
                                                    />
                                                </Badge>
                                            </ListItem>
                                        ))
                                }
                            </List>
                        </Box>
                    }
                    <div style={{ height: '50vh', overflowY: 'auto', padding: '10px' }}> {/* Wrapper container */}
                        <Grid
                            className="dashboard-container"
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 1, sm: 8, md: 12 }}
                            sx={{padding:'10px'}}
                        >
                            {
                                sortedAndFilteredAttendees.length > 0 ? (
                                    sortedAndFilteredAttendees
                                        .map((attendee, index) => (
                                            <Grid item>
                                                <AttendeeCard attendee={attendee} />
                                            </Grid>
                                        ))
                                ) : (
                                    <></>
                                )

                            }
                        </Grid>
                    </div>
                </Paper>

        </Box>


    )

}

export default AttendeesList;
