

const suppliesSuggestionList = {
    "Bathroom": [
        "Towels",
        "Toilet Paper",
        "Shampoo",
        "Soap",
        "Toothbrush",
        "Toothpaste",
        "Shower Curtain",
        "Bath Mat",
        "Hand Soap",
        "Razor"
    ],
    "Laundry": [
        "Laundry Detergent",
        "Fabric Softener",
        "Dryer Sheets",
        "Laundry Basket",
        "Iron",
        "Ironing Board",
        "Hangers",
        "Stain Remover",
        "Lint Roller",
        "Laundry Bags"
    ],
    "Kitchen": [
        "Pots and Pans",
        "Cooking Utensils",
        "Cutlery",
        "Plates and Bowls",
        "Glasses and Mugs",
        "Toaster",
        "Coffee Maker",
        "Mixing Bowls",
        "Blender",
        "Food Storage Containers"
    ],
    "Bar": [
        "Cocktail Shaker",
        "Wine Glasses",
        "Bottle Opener",
        "Corkscrew",
        "Mixing Glass",
        "Ice Bucket",
        "Coasters",
        "Bar Towels",
        "Bar Stools",
        "Liquor Bottles"
    ],
    "Game Room": [
        "Board Games",
        "Playing Cards",
        "Darts",
        "Foosball Table",
        "Pool Cues",
        "Ping Pong Paddles",
        "Chess Set",
        "Video Game Console",
        "VR Headset",
        "Puzzle"
    ],
    "Other": [
        "Flashlight",
        "Batteries",
        "Duct Tape",
        "Sewing Kit",
        "Scissors",
        "First Aid Kit",
        "Tool Set",
        "Candles",
        "Matches",
        "Fire Extinguisher"
    ],
    "Bedroom": [
        "Bed Linens",
        "Pillows",
        "Blankets",
        "Alarm Clock",
        "Lamps",
        "Hangers",
        "Curtains"
    ],
    "Electronics": [
        "Laptop",
        "Smartphone",
        "Chargers",
        "Headphones",
        "Power Strip",
        "Power Converter",
        "Surge Protector",
        "Extension Cords",
        "Camera",
        "Tablet",
        "Bluetooth Speaker",
        "E-reader"
    ],
    "Documents & IDs": [
        "Passport",
        "Driver's License",
        "Travel Tickets",
        "Visa",
        "Health Insurance Card",
        "Emergency Contacts",
        "Credit Cards",
        "Cash",
        "Travel Guidebook"
    ],
    "Outdoor": [
        "Tent",
        "Sleeping Bag",
        "Camp Stove",
        "Backpack",
        "Hiking Boots",
        "Water Bottles",
        "Sunglasses",
        "Bug Spray",
        "Sunscreen",
        "Binoculars"
    ],
    "Exercise": [
        "Yoga Mat",
        "Resistance Bands",
        "Jump Rope",
        "Running Shoes",
        "Dumbbells",
        "Exercise Ball",
        "Water Bottle",
        "Fitness Tracker",
        "Workout Clothes",
        "Foam Roller"
    ],
    "Garage": [
        "Toolbox",
        "Screwdriver Set",
        "Wrenches",
        "Car Jack",
        "Jumper Cables",
        "Oil Funnel",
        "Workbench",
        "Gloves",
        "Car Wash Supplies",
        "Bike Rack"
    ],
    "Yard": [
        "Garden Hose",
        "Rake",
        "Shovel",
        "Pruning Shears",
        "Garden Gloves",
        "Grill",
        "Lanterns"
    ],
    "Pets": [
        "Pet Food",
        "Water Bowl",
        "Leash",
        "Collar",
        "Pet Bed",
        "Toys",
        "Litter Box",
        "Pet Carrier",
        "Brush",
        "ID Tags"
    ],
    "Clothing": [
        "T-Shirts",
        "Jeans",
        "Dresses",
        "Socks",
        "Underwear",
        "Jackets",
        "Hats",
        "Gloves",
        "Scarves",
        "Swimsuits"
    ],
    "Footwear": [
        "Sneakers",
        "Sandals",
        "Boots",
        "Flip-Flops",
        "Dress Shoes",
        "Running Shoes",
        "Slippers",
        "Hiking Boots",
        "Water Shoes",
        "Cleats"
    ],
    "Accessories": [
        "Sunglasses",
        "Watches",
        "Jewelry",
        "Handbags",
        "Belts",
        "Hats",
        "Scarves",
        "Umbrella",
        "Gloves",
        "Ties"
    ],
    "Toiletries": [
        "Shampoo",
        "Conditioner",
        "Body Wash",
        "Toothbrush",
        "Toothpaste",
        "Deodorant",
        "Razor",
        "Feminine Hygiene Products",
        "Cotton Balls",
        "Sunscreen"
    ],
    "Cleaning Supplies": [
        "Broom",
        "Mop",
        "Bucket",
        "Sponges",
        "Dish Soap",
        "Trash Bags",
        "Cleaning Cloths",
        "Vacuum Cleaner",
        "Dustpan",
        "Cleaning Gloves"
    ],
    "Paper Products": [
        "Toilet Paper",
        "Paper Towels",
        "Napkins",
        "Tissues",
        "Paper Plates",
        "Disposable Cups",
        "Plastic Cutlery",
        "Aluminum Foil",
        "Plastic Wrap",
        "Trash Bags"
    ],
    "Accessibility": [
        "Wheelchair",
        "Ramp",
        "Braille Labels",
        "Hearing Aid",
        "Cane",
        "Service Dog",
        "Voice Assistant",
        "Large Print Materials",
        "Elevator Access",
        "Accessible Restrooms"
    ],
    "Hygiene": [
        "Hand Sanitizer",
        "Face Masks",
        "Disinfectant Wipes",
        "Tissues",
        "Disposable Gloves",
        "Thermometer",
        "Antibacterial Soap",
        "Alcohol Swabs",
        "Face Shields",
        "First Aid Kit"
    ],
    "Grooming": [
        "Hairbrush",
        "Comb",
        "Hair Dryer",
        "Straightener",
        "Curling Iron",
        "Nail Clippers",
        "Shaving Cream",
        "Tweezers",
        "Makeup Kit",
        "Perfume"
    ]
};

const foodSuggestionList = {
    "Beverages": [
        "Water Bottles",
        "Soda",
        "Juice",
        "Coffee",
        "Tea",
        "Wine",
        "Beer",
        "Cocktail Mixers",
        "Energy Drinks",
        "Milk"
    ],
    "Condiments and Sauces": [
        "Ketchup",
        "Mustard",
        "Mayonnaise",
        "Soy Sauce",
        "Hot Sauce",
        "Barbecue Sauce",
        "Salad Dressing",
        "Ranch Dip",
        "Honey",
        "Maple Syrup"
    ],
    "Meat and Poultry": [
        "Chicken Breast",
        "Ground Beef",
        "Pork Chops",
        "Salmon",
        "Bacon",
        "Turkey",
        "Lamb",
        "Sausages",
        "Steak",
        "Ham"
    ],
    "Refrigerator": [
        "Milk",
        "Eggs",
        "Butter",
        "Cheese",
        "Yogurt",
        "Cream",
        "Fresh Vegetables",
        "Fresh Fruits",
        "Cold Cuts",
        "Hummus"
    ],
    "Herbs and Spices": [
        "Salt",
        "Pepper",
        "Basil",
        "Oregano",
        "Cumin",
        "Paprika",
        "Cinnamon",
        "Thyme",
        "Rosemary",
        "Chili Powder"
    ],
    "Dried and Packaged": [
        "Pasta",
        "Rice",
        "Cereal",
        "Canned Soup",
        "Granola Bars",
        "Oatmeal",
        "Popcorn",
        "Crackers",
        "Dried Fruits",
        "Nuts"
    ],
    "Canned and Preserved": [
        "Canned Vegetables",
        "Canned Fruit",
        "Canned Tuna",
        "Canned Beans",
        "Pickles",
        "Jam",
        "Peanut Butter",
        "Salsa",
        "Applesauce",
        "Olives"
    ],
    "Freezer": [
        "Frozen Vegetables",
        "Frozen Fruits",
        "Frozen Pizza",
        "Ice Cream",
        "Frozen Chicken Nuggets",
        "Frozen Burgers",
        "Frozen French Fries",
        "Frozen Dinners",
        "Frozen Waffles",
        "Frozen Fish"
    ],
    "Bread and Baked": [
        "Bread",
        "Bagels",
        "Croissants",
        "Muffins",
        "Donuts",
        "Pita Bread",
        "Tortillas",
        "Rolls",
        "Cakes",
        "Cookies"
    ],
    "Pantry": [
        "Canned Soup",
        "Ramen Noodles",
        "Pasta Sauce",
        "Peanut Butter",
        "Cereal",
        "Canned Beans",
        "Rice",
        "Instant Coffee",
        "Canned Vegetables",
        "Canned Fruit"
    ],
    "Fresh Produce": [
        "Apples",
        "Bananas",
        "Oranges",
        "Lettuce",
        "Tomatoes",
        "Cucumbers",
        "Carrots",
        "Bell Peppers",
        "Broccoli",
        "Spinach"
    ],
    "Snacks": [
        "Chips",
        "Pretzels",
        "Trail Mix",
        "Granola Bars",
        "Popcorn",
        "Nuts",
        "Cookies",
        "Candy",
        "Dried Fruits",
        "Rice Cakes"
    ],
    "Baking": [
        "Flour",
        "Sugar",
        "Baking Soda",
        "Baking Powder",
        "Chocolate Chips",
        "Vanilla Extract",
        "Cake Mix",
        "Pie Crust",
        "Brownie Mix",
        "Sprinkles"
    ],
    "Pet meals": [
        "Dog Food",
        "Cat Food",
        "Fish Food",
        "Bird Seed",
        "Rabbit Pellets",
        "Hamster Food",
        "Turtle Food",
        "Guinea Pig Food",
        "Lizard Food",
        "Cricket Food"
    ]
};

// Combine supplies and food lists into one array
const allSuggestedItems = [...Object.values(suppliesSuggestionList), ...Object.values(foodSuggestionList)].flat();

export { suppliesSuggestionList, foodSuggestionList, allSuggestedItems };
