export const unselectedChipStyle = {
    boxShadow: '0 0 5px #0096ff',
    border: '1px solid #0096ff',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#cbe0f7', // Reverse the gradient on hover
    },
};
export const unselectedGradientStyleOriginal = {
    boxShadow: 2,
    borderRadius: 2,
    textAlign: 'center',
    fontWeight: '700',
    background: 'linear-gradient(195deg, #cbe0f7, #719bf5)',
    border: 0, // Remove border (optional)
    color: 'black', // Text color (adjust as needed)
};
