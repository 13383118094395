import React, {useContext, useEffect, useState} from 'react';

import './receipt.css';
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {PAContext} from "../../../Services/PAContext";


const sectionMapping = {
    Meals: {
        data: 'meals',
        displayProperty: 'eatery_name',
    },
    Lodging: {
        data: 'lodging',
        displayProperty: 'name',
    },
    Activities: {
        data: 'activities',
        displayProperty: 'title',
    },
    Flights: {
        data: 'flights',
        displayProperty: 'airline_name',
    },
    Supplies: {
        data: 'supplies',
        displayProperty: 'name',
    },
    // Add more sections if needed
};

const formatAmount = (amount) => {
    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};



function ReceiptCard(props){

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    const [receipt, setReceipt] = useState(props.receipt);

    useEffect(() => {
        if (props.receipt) {
            setReceipt(props.receipt);
        }
    }, [props.receipt]);

    const renderSectionDetails = () => {
        const { section, section_item } = receipt;

        if (section_item === 0) {
            return null; // No link to an Event item
        }

        const sectionDetails = sectionMapping[section];
        if (!sectionDetails) {
            return null; // Section not found in mapping
        }

        const item = Planning[sectionDetails.data].find(
            (item) => item.id === section_item
        );

        if (!item) {
            return null; // No matching item found
        }

        return (
            <Typography sx={{ mb: 1.5 }} variant="body2">
                {item[sectionDetails.displayProperty]}
            </Typography>
        );
    };

    return (
        <Card>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant="body2">
                    {receipt['section']}
                </Typography>
                <Typography sx={{ mb: 1.5 }} variant="body2">
                    {formatAmount(receipt['amount'])}
                </Typography>

                {/* Render the event item details based on the section */}
                {
                    renderSectionDetails()
                }

                <Typography sx={{ mb: 1.5 }} variant="body2">
                    {receipt['notes']}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ReceiptCard;