import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PAContext} from "../../Services/PAContext";

import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import PAEvent from "../../MasterEventObjects/PAEvent";
import PAAttendee from "../../MasterEventObjects/Attendee";
import {browserStorageUpdateSpecificEvent} from "../../Services/BrowserDatabaseFunctions";




function FeaturesList(props) {

    const navigate = useNavigate();
    const { dataArray, setPlanning } = useContext(PAContext);
    const { Planning, authenticated, Account } = dataArray;

    const [currentFeature, setCurrentFeature] = useState(props.currentFeature);

    useEffect(() => {
        setCurrentFeature(props.currentFeature);
    }, [props.currentFeature]);

    const [needToSave, setNeedToSave] = useState(props.currentFeature);
    useEffect(() => {
        console.log("useEffect Planning!! : "+JSON.stringify(Planning));
        setNeedToSave(Planning.need_to_save);

        /*
            see if anything needs to change at the high level
            this may include activities, lodging, meals, flights, supplies, etc

         */







    }, [Planning]);

    const handleIconClick = (featureName) => {
        // Handle the click event here
        console.log(`Clicked on ${featureName}`);

        //decide whether to show the console or not
        if (featureName !== "Save" && featureName !== "Clear"){
            if (!props.showEventItemConfig){
                //if it's not showing then show it
                props.setShowEventItemConfig(true);
                setCurrentFeature(featureName);

            } else {
                //if it is showing then decide whether it is just changing features and needs to stay open
                // - or - if they are just meaning to close it
                if (featureName === currentFeature){
                    props.setShowEventItemConfig(false);
                    setCurrentFeature("Map");
                } else {
                    setCurrentFeature(featureName);
                }
            }
        }


        switch (featureName) {
            case "Save":
                savePlanning();
                break;
            case "Details":
                navigate('/Planner/Details')
                break;
            case "Location":
                navigate('/Planner/Location')
                break;
            case "Dates":
                navigate('/Planner/Dates')
                break;
            case "Map":
                props.setShowEventItemConfig(false);
                navigate('/Planner/Map')
                break;
            case "Itinerary":
                navigate('/Planner/Itinerary')
                break;
            case "Documents":
                navigate('/Planner/Documents')
                break;
            case "Activities":
                navigate('/Planner/Activities')
                break;
            case "Ideas":
                navigate('/Planner/Ideas')
                break;
            case "Attendees":
                navigate('/Planner/Attendees')
                break;
            case "Lodging":
                navigate('/Planner/Lodging')
                break;
            case "Flights":
                navigate('/Planner/Flights')
                break;
            case "carRentals":
                navigate('/Planner/CarRentals')
                break;
            case "Cruises":
                navigate('/Planner/Cruises')
                break;
            case "Messages":
                navigate('/Planner/Messages')
                break;
            case "Meals":
                navigate('/Planner/Meals')
                break;
            case "Photos":
                navigate('/Planner/Photos')
                break;
            case "Supplies":
                navigate('/Planner/Supplies')
                break;
            case "Receipts":
                navigate('/Planner/Receipts')
                break;
            case "To-Do":
                navigate('/Planner/Todo')
                break;
            case "Weather":
                navigate('/Planner/Weather')
                break;
            case "Clear":
                clearPlanning();
                break;
            default:
        }

        props.setShowEventItemExtendedConfig(false);
    };

    const theme = createTheme({
        palette: {
            badge: {
                main: '#cbe0f7'
            },
        }
    });


    function savePlanning(){

        //are they logged in
        if (authenticated && Account.user_id !== undefined){
            console.log("is logged in");

            //check if an Event name has been given
            //is this a bucket list item or an actual Event
            if (Planning.title === ""){
                //show the DialogBox to get the title

                const _sendString = {};
                _sendString.action = "E_setEventAllChanges";
                _sendString.event = Planning;

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            }

        } else {
            console.log("needs to login");


        }

    }

    function clearPlanning(){
        //show the prompt
        setOpenDialogDelete(true);

    }

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

        const _new_event = new PAEvent();
        _new_event.title = "My New Event";
        _new_event.status = 10;

        //see if they are logged in
        if (Object.keys(Account).length === 0){
            //create the default Planner Attendee
            console.log("Need to create the default Planner Attendee");
            const _planner = new PAAttendee(
                'Sample',
                'Planning',
                'sample.planning@plannedadventure.com');
            _planner.role = 'planner';
            _planner.nickname = 'Planner';
            _new_event.attendees.push(_planner);
        } else {
            //they are logged in so use their account
            console.log("User is logged in");

            const _planner = new PAAttendee(
                Account.fname,
                Account.lname,
                Account.email);
            _planner.role = 'planner';
            _planner.nickname = 'Planner';

            _planner.address = Account.address;
            _planner.city = Account.city;
            _planner.state = Account.state;
            _planner.zip = Account.zip;
            _planner.country = Account.country;

            _new_event.attendees.push(_planner);

        }

        setPlanning(_new_event);

        props.setClearEvent(true);

    }




    return (

        <ThemeProvider theme={theme}>

            {/* add the Details icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Details"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Details' ? '#fef4ec' : '',
                        border: currentFeature === 'Details' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Details")}
                >
                    {<i className="icon-settings-icons"/>}
                </IconButton>
                {Object.keys(Planning).length !== 0 && Planning.title.length > 3 ? (
                    <i className="icon-check"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                ) : (
                    <i className="icon-warning"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red'}}/>
                )}
            </div>

            {/* add the Location icon */}
            {/*

            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Location"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Location' ? '#fef4ec' : '',
                        border: currentFeature === 'Location' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Location")}
                >
                    {<i className="icon-map-marker"/>}
                </IconButton>
                {Object.keys(Planning).length !== 0 && Planning.locations.length > 0 ? (
                    <i className="icon-check"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                ) : (
                    <></>
                )}
            </div>

            */}


            {/* add the Dates icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Dates"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Dates' ? '#fef4ec' : '',
                        border: currentFeature === 'Dates' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Dates")}
                >
                    {<i className="icon-calendar3"/>}
                </IconButton>
                {Object.keys(Planning).length !== 0 && Planning.begin_date !== "" ? (
                    <i className="icon-check"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                ) : (
                    <i className="icon-warning"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red'}}/>
                )}
            </div>

            {/* add the Map icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Map"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Map' ? '#fef4ec' : '',
                        border: currentFeature === 'Map' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Map")}
                >
                    <i className="icon-map"/>
                </IconButton>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Itinerary"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Itinerary' ? '#fef4ec' : '',
                        color: '#f09637',
                        border: currentFeature === 'Itinerary' ? '1px solid #fccca5' : '0',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Itinerary")}
                >
                    {<i className="icon-calendar"/>}
                </IconButton>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning)?.length !== 0 ? (
                            Planning.documents?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Documents"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Documents' ? '#fef4ec' : '',
                            border: currentFeature === 'Documents' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Documents")}
                    >
                        {<i className="icon-file-check"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.activities.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                >
                    <IconButton
                        title="Activities"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Activities' ? '#fef4ec' : '',
                            border: currentFeature === 'Activities' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Activities")}
                    >
                        {<i className="icon-list-heart"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.ideas.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Ideas"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Ideas' ? '#fef4ec' : '',
                            border: currentFeature === 'Ideas' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Ideas")}
                    >
                        {
                            <i className="icon-lamp-bright"/>
                        }
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0
                            ? Planning.attendees === undefined
                                ? 0
                                : Planning.attendees.filter(attendee => attendee.status !== 4).length
                            : 0
                    }
                    color="badge"
                >
                    <IconButton
                        title="Attendees"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Attendees' ? '#fef4ec' : '',
                            border: currentFeature === 'Attendees' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Attendees")}
                    >
                        {<i className="icon-user-24"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.lodging.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Lodging"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Lodging' ? '#fef4ec' : '',
                            border: currentFeature === 'Lodging' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Lodging")}
                    >
                        {<i className="icon-bed"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.flights.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Flights"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Flights' ? '#fef4ec' : '',
                            border: currentFeature === 'Flights' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Flights")}
                    >
                        {<i className="icon-airplane"/>}
                    </IconButton>
                </Badge>
            </div>

            {/*
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.car_rentals.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Car Rentals"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'carRentals' ? '#fef4ec' : '',
                            border: currentFeature === 'carRentals' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("carRentals")}
                    >
                        {<i className="icon-car2"/>}
                    </IconButton>
                </Badge>
            </div>
            */}

            {/*
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.cruises.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Cruises"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'cruises' ? '#fef4ec' : '',
                            border: currentFeature === 'cruises' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("cruises")}
                    >
                        {<i className="icon-ship"/>}
                    </IconButton>
                </Badge>
            </div>
            */}

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.messages.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                    max={Infinity}
                    sx={{
                        '& .MuiBadge-badge': {
                            minWidth: 'auto', // Adjust the minimum width
                            height: 'auto', // Adjust the height
                            padding: '0 6px', // Adjust padding for better fit
                            fontSize: '0.75rem', // Adjust the font size
                            lineHeight: '1', // Ensure line height doesn't cause overflow
                        }
                    }}
                >
                    <IconButton
                        title="Messaging"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Messages' ? '#fef4ec' : '',
                            border: currentFeature === 'Messages' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Messages")}
                    >
                        {<i className="icon-smartphone-text"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.meals.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Meals"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Meals' ? '#fef4ec' : '',
                            border: currentFeature === 'Meals' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Meals")}
                    >
                        {<i className="icon-fork-knife2"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.photos.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Photos"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Photos' ? '#fef4ec' : '',
                            border: currentFeature === 'Photos' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Photos")}
                    >
                        {<i className="icon-picture2"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.supplies.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                >
                    <IconButton
                        title="Supplies"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Supplies' ? '#fef4ec' : '',
                            border: currentFeature === 'Supplies' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Supplies")}
                    >
                        {<i className="icon-checklist2"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.receipts.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Receipts"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Receipts' ? '#fef4ec' : '',
                            border: currentFeature === 'Receipts' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Receipts")}
                    >
                        {<i className="icon-receipt"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning)?.length !== 0 ? (
                            Planning.to_do?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="To-Do"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'To-Do' ? '#fef4ec' : '',
                            border: currentFeature === 'To-Do' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("To-Do")}
                    >
                        {<i className="icon-checklist-time"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Weather"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Weather' ? '#fef4ec' : '',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Weather")}
                    disabled={!props.weatherReportReceived}
                >
                    <i className="icon-sun-wind"/>
                </IconButton>
                {
                    props.weatherReportReceived ? (
                        <i className="icon-check"
                           style={{position: 'absolute', top: 5, right: 5, fontSize: '11px', color: 'green'}}/>
                    ) : (
                        <></>
                    )
                }
            </div>

            {/* add the Save icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Save to Database"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Save' ? '#fef4ec' : '',
                        border: currentFeature === 'Save' ? '1px solid #fccca5' : '0',
                        color: needToSave ? '#f00' : '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Save")}
                >
                    {<i className="icon-floppy-disk"/>}
                </IconButton>
            </div>

            {/* add the Clear icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Clear"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Clear' ? '#fef4ec' : '',
                        border: currentFeature === 'Clear' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Clear")}
                >
                    {<i className="icon-trash"/>}
                </IconButton>
            </div>


            <Dialog
                open={openDialogDelete}
                onClose={handleDialogDeleteClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to clear all of the information for this Event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </ThemeProvider>

    )
}

export default FeaturesList;
