import React from 'react';

const sample_attendees = [
    {
        "id": "100000",
        "fname": "John",
        "lname": "Doe",
        "email": "john.doe@example.com",
        "avatar": "https://example.com/avatar1.jpg"
    },
    {
        "id": "100001",
        "fname": "Jane",
        "lname": "Smith",
        "email": "jane.smith@example.com",
        "avatar": "https://example.com/avatar2.jpg"
    },
    {
        "id": "100002",
        "fname": "Michael",
        "lname": "Johnson",
        "email": "michael.johnson@example.com",
        "avatar": "https://example.com/avatar3.jpg"
    },
    {
        "id": "100003",
        "fname": "Emily",
        "lname": "Williams",
        "email": "emily.williams@example.com",
        "avatar": "https://example.com/avatar4.jpg"
    },
    {
        "id": "100004",
        "fname": "David",
        "lname": "Brown",
        "email": "david.brown@example.com",
        "avatar": "https://example.com/avatar5.jpg"
    },
    {
        "id": "100005",
        "fname": "Olivia",
        "lname": "Jones",
        "email": "olivia.jones@example.com",
        "avatar": "https://example.com/avatar6.jpg"
    },
    {
        "id": "100006",
        "fname": "Michael",
        "lname": "Garcia",
        "email": "michael.garcia@example.com",
        "avatar": "https://example.com/avatar7.jpg"
    },
    {
        "id": "100007",
        "fname": "Sophia",
        "lname": "Miller",
        "email": "sophia.miller@example.com",
        "avatar": "https://example.com/avatar8.jpg"
    },
    {
        "id": "100008",
        "fname": "William",
        "lname": "Davis",
        "email": "william.davis@example.com",
        "avatar": "https://example.com/avatar9.jpg"
    },
    {
        "id": "100009",
        "fname": "Isabella",
        "lname": "Martinez",
        "email": "isabella.martinez@example.com",
        "avatar": "https://example.com/avatar10.jpg"
    },
    {
        "id": '100010',
        "fname": "James",
        "lname": "Rodriguez",
        "email": "james.rodriguez@example.com",
        "avatar": "https://example.com/avatar11.jpg"
    },
    {
        "id": "100011",
        "fname": "Sophia",
        "lname": "Hernandez",
        "email": "sophia.hernandez@example.com",
        "avatar": "https://example.com/avatar12.jpg"
    },
    {
        "id": "100012",
        "fname": "Robert",
        "lname": "Lopez",
        "email": "robert.lopez@example.com",
        "avatar": "https://example.com/avatar13.jpg"
    },
    {
        "id": "100013",
        "fname": "Emma",
        "lname": "Gonzalez",
        "email": "emma.gonzalez@example.com",
        "avatar": "https://example.com/avatar14.jpg"
    },
    {
        "id": "100014",
        "fname": "John",
        "lname": "Wilson",
        "email": "john.wilson@example.com",
        "avatar": "https://example.com/avatar15.jpg"
    },
    {
        "id": "100015",
        "fname": "Olivia",
        "lname": "Anderson",
        "email": "olivia.anderson@example.com",
        "avatar": "https://example.com/avatar16.jpg"
    },
    {
        "id": "100016",
        "fname": "Noah",
        "lname": "Taylor",
        "email": "noah.taylor@example.com",
        "avatar": "https://example.com/avatar17.jpg"
    },
    {
        "id": "100017",
        "fname": "Ava",
        "lname": "Thomas",
        "email": "ava.thomas@example.com",
        "avatar": "https://example.com/avatar18.jpg"
    },
    {
        "id": "100018",
        "fname": "William",
        "lname": "Jackson",
        "email": "william.jackson@example.com",
        "avatar": "https://example.com/avatar19.jpg"
    },
    {
        "id": "100019",
        "fname": "Sophia",
        "lname": "White",
        "email": "sophia.white@example.com",
        "avatar": "https://example.com/avatar20.jpg"
    },
    {
        "id": "100020",
        "fname": "Liam",
        "lname": "Harris",
        "email": "liam.harris@example.com",
        "avatar": "https://example.com/avatar21.jpg"
    },
    {
        "id": "100021",
        "fname": "Isabella",
        "lname": "Martin",
        "email": "isabella.martin@example.com",
        "avatar": "https://example.com/avatar22.jpg"
    },
    {
        "id": "100022",
        "fname": "Elijah",
        "lname": "Lee",
        "email": "elijah.lee@example.com",
        "avatar": "https://example.com/avatar23.jpg"
    },
    {
        "id": "100023",
        "fname": "Olivia",
        "lname": "Lewis",
        "email": "olivia.lewis@example.com",
        "avatar": "https://example.com/avatar24.jpg"
    },
    {
        "id": "100024",
        "fname": "Mason",
        "lname": "Walker",
        "email": "mason.walker@example.com",
        "avatar": "https://example.com/avatar25.jpg"
    },
    {
        "id": "100025",
        "fname": "Ava",
        "lname": "Hall",
        "email": "ava.hall@example.com",
        "avatar": "https://example.com/avatar26.jpg"
    },
    {
        "id": "100026",
        "fname": "James",
        "lname": "Allen",
        "email": "james.allen@example.com",
        "avatar": "https://example.com/avatar27.jpg"
    },
    {
        "id": "100027",
        "fname": "Sophia",
        "lname": "Young",
        "email": "sophia.young@example.com",
        "avatar": "https://example.com/avatar28.jpg"
    },
    {
        "id": "100028",
        "fname": "Emma",
        "lname": "Hernandez",
        "email": "emma.hernandez@example.com",
        "avatar": "https://example.com/avatar29.jpg"
    },
    {
        "id": "100029",
        "fname": "Alexander",
        "lname": "King",
        "email": "alexander.king@example.com",
        "avatar": "https://example.com/avatar30.jpg"
    },
    {
        "id": "100030",
        "fname": "Olivia",
        "lname": "Wright",
        "email": "olivia.wright@example.com",
        "avatar": "https://example.com/avatar31.jpg"
    },
    {
        "id": "100031",
        "fname": "Benjamin",
        "lname": "Scott",
        "email": "benjamin.scott@example.com",
        "avatar": "https://example.com/avatar32.jpg"
    },
    {
        "id": "100032",
        "fname": "Mia",
        "lname": "Torres",
        "email": "mia.torres@example.com",
        "avatar": "https://example.com/avatar33.jpg"
    },
    {
        "id": "100033",
        "fname": "William",
        "lname": "Nguyen",
        "email": "william.nguyen@example.com",
        "avatar": "https://example.com/avatar34.jpg"
    },
    {
        "id": "100034",
        "fname": "Sophia",
        "lname": "Flores",
        "email": "sophia.flores@example.com",
        "avatar": "https://example.com/avatar35.jpg"
    },
    {
        "id": "100035",
        "fname": "Ethan",
        "lname": "Rivera",
        "email": "ethan.rivera@example.com",
        "avatar": "https://example.com/avatar36.jpg"
    },
    {
        "id": "100036",
        "fname": "Olivia",
        "lname": "Green",
        "email": "olivia.green@example.com",
        "avatar": "https://example.com/avatar37.jpg"
    },
    {
        "id": "100037",
        "fname": "Mia",
        "lname": "Ortiz",
        "email": "mia.ortiz@example.com",
        "avatar": "https://example.com/avatar38.jpg"
    },
    {
        "id": "100038",
        "fname": "Logan",
        "lname": "Ramos",
        "email": "logan.ramos@example.com",
        "avatar": "https://example.com/avatar39.jpg"
    },
    {
        "id": "100039",
        "fname": "Sophia",
        "lname": "Gomez",
        "email": "sophia.gomez@example.com",
        "avatar": "https://example.com/avatar40.jpg"
    },
    {
        "id": "100040",
        "fname": "Elijah",
        "lname": "Murray",
        "email": "elijah.murray@example.com",
        "avatar": "https://example.com/avatar41.jpg"
    },
    {
        "id": "100041",
        "fname": "Olivia",
        "lname": "Freeman",
        "email": "olivia.freeman@example.com",
        "avatar": "https://example.com/avatar42.jpg"
    },
    {
        "id": "100042",
        "fname": "Ava",
        "lname": "Wells",
        "email": "ava.wells@example.com",
        "avatar": "https://example.com/avatar43.jpg"
    },
    {
        "id": "100043",
        "fname": "Noah",
        "lname": "Webb",
        "email": "noah.webb@example.com",
        "avatar": "https://example.com/avatar44.jpg"
    },
    {
        "id": "100044",
        "fname": "William",
        "lname": "Simpson",
        "email": "william.simpson@example.com",
        "avatar": "https://example.com/avatar45.jpg"
    },
    {
        "id": "100045",
        "fname": "Isabella",
        "lname": "Stevens",
        "email": "isabella.stevens@example.com",
        "avatar": "https://example.com/avatar46.jpg"
    },
    {
        "id": "100046",
        "fname": "Oliver",
        "lname": "Tucker",
        "email": "oliver.tucker@example.com",
        "avatar": "https://example.com/avatar47.jpg"
    },
    {
        "id": "100047",
        "fname": "Sophia",
        "lname": "Porter",
        "email": "sophia.porter@example.com",
        "avatar": "https://example.com/avatar48.jpg"
    },
    {
        "id": "100048",
        "fname": "Sophia",
        "lname": "Hunter",
        "email": "sophia.hunter@example.com",
        "avatar": "https://example.com/avatar49.jpg"
    },
    {
        "id": "100049",
        "fname": "Evelyn",
        "lname": "Hicks",
        "email": "evelyn.hicks@example.com",
        "avatar": "https://example.com/avatar50.jpg"
    }
];

export default sample_attendees;
