import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {Chip, Menu, MenuItem, MenuList} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import './supplies.css';
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import _ from 'lodash';

import { allSuggestedItems } from '../../../sampleData/SupplyItemSuggestions';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Box from "@mui/material/Box";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";

function SuppliesList(props) {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Account, Planning } = dataArray;

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const [anchorSuppliesMenu, setAnchorSuppliesMenu] = useState(null);
    const handleSuppliesMenuOpenMenu = (event) => {
        setAnchorSuppliesMenu(event.currentTarget);
    };
    const handleSuppliesMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorSuppliesMenu(event.currentTarget);

        props.setSelectedAttendeeForSupplies(selectedAttendee);

        if (dataId === "new-entry"){
            navigate('/Planner/Supplies/AddNew');
        } else if (dataId === "from-essentials"){
            navigate('/Planner/Supplies/AddEssentials');
        } else if (dataId === "from-suggestion-list"){
            props.setShowEventItemConfig(true);
            props.setShowEventItemExtendedConfig(true);
            setAnchorSuppliesMenu(null);
            navigate('/Planner/Supplies/AddSuggestions');
        }
    };

    const handleSuppliesMenuClose = () => {
        setAnchorSuppliesMenu(null);
    };

    const [sortedGroupedItems, setSortedGroupedItems] = useState([]);




    const handleChipClick = (_attendee_id) => {
        console.log("Show supplies for : "+_attendee_id);

        setSelectedAttendee(_attendee_id);
        props.setSelectedAttendeeForSupplies(_attendee_id);

        if (Planning.supplies !== undefined) {
            const filteredItems = Planning.supplies.filter(item => item.assigned_to === _attendee_id);

            console.log("filteredItems : "+JSON.stringify(filteredItems, null, '\t'));

            // Group the filtered items by category and subcategory
            const groupedItems = _.groupBy(filteredItems, item => `${item.category}_${item.sub_category}`);

            console.log("groupedItems : "+JSON.stringify(groupedItems, null, '\t'));

            setSortedGroupedItems(groupedItems);

            // Sort the grouped items alphabetically by category and subcategory
//            setSortedGroupedItems(_.sortBy(Object.entries(groupedItems), ([key]) => key));
        }
    }

    useEffect(() => {
        // Sort the attendees by lname whenever the Attendees array changes
        console.log("Planning.supplies : "+JSON.stringify(Planning.supplies, null, '\t'));

        if (Planning.supplies !== undefined) {
            const filteredItems = Planning.supplies.filter(item => item.assigned_to === selectedAttendee);

            // Group the filtered items by category and subcategory
            const groupedItems = _.groupBy(filteredItems, item => `${item.category}_${item.sub_category}`);

            setSortedGroupedItems(groupedItems);

            // Sort the grouped items alphabetically by category and subcategory
//            setSortedGroupedItems(_.sortBy(Object.entries(groupedItems), ([key]) => key));

        }

    }, [props.setSelectedAttendeeForSupplies]);


    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        return '';
    }



    return (

        <div className="supplies-list-container" >
            {/* show the header */}
            <div
                className="supplies-list-header"
            >
                <div className="supplies-list-header-text">
                    <h5>Supplies List</h5>
                </div>

                <div className="supplies-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    {
                        Planning.attendees && Planning.attendees.length > 0 ? (
                            <>

                                <Chip
                                    sx={menuChipStyle}
                                    label={
                                        <i className="icon-menu2" style={{fontSize:'16px'}} />
                                    }
                                    size="small"
                                    onClick={handleSuppliesMenuOpenMenu}
                                />
                                    <Menu
                                        sx={{ width: 320, maxWidth: '100%' }}
                                        anchorEl={anchorSuppliesMenu}
                                        id="library-menu"
                                        open={Boolean(anchorSuppliesMenu)}
                                        onClose={handleSuppliesMenuClose}

                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuList
                                            dense
                                            sx={{ width: 320, maxWidth: '100%' }}
                                        >
                                            <MenuItem
                                                data-id="from-essentials"
                                                onClick={handleSuppliesMenuClick}
                                                //                disabled={dataArray.Account.essentials === undefined}
                                            >
                                                <ListItemText>From My Essentials</ListItemText>
                                                <Typography variant="body2" color="text.secondary">
                                                    {
                                                        dataArray.Account.essentials === undefined ? 0 : dataArray.Account.essentials.length
                                                    }
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem data-id="from-suggestion-list" onClick={handleSuppliesMenuClick}>
                                                <ListItemText>From Suggestions</ListItemText>
                                                <Typography variant="body2" color="text.secondary">
                                                    {
                                                        allSuggestedItems.length
                                                    }
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem data-id="new-entry" onClick={handleSuppliesMenuClick}>
                                                <ListItemText>New Entry</ListItemText>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </>
                        ) : (
                            <></>
                        )
                    }

                </div>
            </div>


            {/* show the list of attendees */}
            <div className="supplies-list-attendee-list-container">
                {
                    Planning.attendees && Planning.attendees.length > 0 ? (
                        Planning.attendees.map((_att, index) => (
                            <Badge
                                key={index}
                                badgeContent={
                                    Planning.supplies.reduce((count, item) => count + (item.assigned_to.includes(_att.id) ? 1 : 0), 0)
                                }
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'white', // Customize the dot color here
                                        border: '1px solid #ddd', // Make it a circle
                                        width: '16px', // Adjust the dot size as needed
                                        height: '16px',
                                        borderRadius: '50%', // Make it a circle
                                        top: '10px', // Adjust top and right values to move the dot
                                        right: '10px', // Adjust top and right values to move the dot
                                    },
                                }}
                            >
                                <Chip
                                    sx={selectedAttendee === _att.id ? selectedChipStyle : unselectedChipStyle}
                                    label={capitalizeFirstLetter(_att.nickname)}
                                    clickable
                                    onClick={() => handleChipClick(_att.id)}
                                    style={{ margin: '0.5rem' }}
                                />
                            </Badge>
                        ))

                    ) : (

                        <div style={{padding: '20px'}} >
                            {/* need to add some attendees first */}
                            <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                            <Typography variant="caption">Need to add some Attendees first</Typography>
                        </div>
                    )

                }
            </div>

            {/* show the list of supplies for the selected attendee */}
            <div className="supplies-list-items-container" style={{display: Planning.attendees === undefined || Planning.attendees.length === 0 ? "none":"block"}}>
                {
                    Object.keys(sortedGroupedItems).map((categoryKey) => (
                        <Box key={categoryKey}>
                            <Typography variant="h6" gutterBottom>
                                {categoryKey
                                    .replace('food_', '')
                                    .replace('supplies_', '')
                                    .replace(/_/g, ' ')}
                            </Typography>
                            {sortedGroupedItems[categoryKey].map((item) => (
                                <Box key={item.id} p={1}>
                                    <Typography variant="body1">{item.name}</Typography>
                                </Box>
                            ))}
                        </Box>
                    ))
                }
            </div>

        </div>
    )
}

export default SuppliesList;
