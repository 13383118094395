import React, {useState} from 'react';

import './contact.css';
import {TextField} from "@mui/material";



function ContactContent(props){

    const [ subject, setSubject] = useState("");
    const [ email, setEmail] = useState("");
    const [ message, setMessage] = useState("");

    function handleSubjectChange(event){
        setSubject(event.target.value);
    }
    function handleEmailChange(event){
        setEmail(event.target.value);
    }
    function handleMessageChange(event){
        setMessage(event.target.value);
    }


    return (

        <div id="content_area" className="content-area-contact">

            <div className="contact-page">
                <div className="contact-every-trip-section">
                    <div className="every-trip-title-holder">
                        <div className="every-trip-title">Reach out to us - <br />
                            We're Here to Make Your Travel Experience the Best It Can Be</div>
                    </div>

                    <div id="contact" className="contact-page-content-area">
                        <div className="contact-area-holder">
                            <div id="contact_area" className="contact-area">
                                <TextField
                                    id="email"
                                    label="Subject"
                                    required
                                    type="subject"
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    variant="standard"
                                    sx={{width: '80%', marginBottom: '20px'}}
                                />

                                <TextField
                                    id="email"
                                    label="Email"
                                    required
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    variant="standard"
                                    sx={{width: '80%', marginBottom: '20px'}}
                                />

                                <TextField
                                    id="_message"
                                    label="Message"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={handleMessageChange}
                                    variant="standard"
                                    sx={{width: '80%'}}
                                />

                                <div id="index_page_contact_us_submit_results"
                                     className="index-page-contact-us-submit-results"></div>

                                <div className="group login-inputbox-group no-margin-bottom">
                                    <button id="contact_us_submit_button" className="contact-us-submit-button"
                                            onClick="javascript:submitContactUsInfo();">Submit
                                        <i id="contact_us_submit_button_icon"
                                           className="contact-us-submit-button-icon position-right" title=""></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactContent;
