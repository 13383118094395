export const addGradientStyle = {
    marginLeft: '10px',
    display: 'inline-block', // Ensure the gradient wraps tightly around the Chip
    padding: '2px', // Space for the gradient border effect
    borderRadius: '10px', // Border radius for the outer container (gradient)
    background: 'linear-gradient(45deg, #0096ff, #fff)', // Gradient for the border
    textAlign: 'center', // Center the text inside
};

export const addChipStyle = {
    backgroundColor: '#fff', // Inner chip background (white)
    borderRadius: '8px', // Smaller border radius for the chip itself
    fontWeight: '700', // Make the text bold
    '&:hover': {
        background: 'linear-gradient(45deg, #0096ff, #fff)', // Reverse the gradient on hover
    },
};

export const addGradientStyleOriginal = {
    marginLeft: '10px',
    boxShadow: 2,
    borderRadius: 2,
    textAlign: 'center',
    fontWeight: '700',
    background: 'linear-gradient(45deg, #0096ff, #fff)',
    border: 0, // Remove border (optional)
    color: 'black', // Text color (adjust as needed)
};