import React from "react";

import PricingContent from "./PricingContent";
import {Grid} from "@mui/material";




function Pricing() {


    return (

        <Grid container spacing={12}>
            <PricingContent />
        </Grid>

    )

}

export default Pricing;
