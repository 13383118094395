import React from 'react';



function Events(props) {


    return (
        <>Events</>
    )

}

export default Events;
