import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";

import Grid from "@mui/material/Unstable_Grid2";
import {Chip, TextField} from "@mui/material";
import PAEvent from "../../../MasterEventObjects/PAEvent";

import './location.css';
import {cancelGradientStyle} from "../../ButtonStyle/Cancel";
import {addGradientStyle} from "../../ButtonStyle/Add";



function LocationDetails(props) {

    const { dataArray, setPlanning } = useContext(PAContext);
    const { Planning } = dataArray;

    const inputLocationRef = useRef(null);

    const [currentLocation, setCurrentLocation] = useState(props.searchingForLocation);
    const [updatedLocation, setUpdatedLocation] = useState("");
    const [showPromptChangeLocation, setShowPromptChangeLocation] = useState(true);


    function handleUpdatedLocation(e) {
        setUpdatedLocation(e.target.value);
    }
    function handlePromptChangeLocationOK(place) {
        console.log("place : "+place);
        console.log("updatedLocation : "+updatedLocation);

        if (typeof place.name !== 'undefined'){
            props.setSearchingForLocation(place.name);
        } else if (updatedLocation !== ""){
            props.setSearchingForLocation(updatedLocation);
        }
        setUpdatedLocation("");
        props.setShowEventItemConfig(false);
    }
    function handlePromptChangeLocationClose() {
        props.setShowEventItemConfig(false);
    }

    useEffect(() => {

        if (showPromptChangeLocation) {

            const autocomplete = new window.google.maps.places.Autocomplete(inputLocationRef.current);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                console.log(JSON.stringify(place, null, '\t'));
                console.log(place.formatted_address);

                if (typeof place.formatted_address === 'undefined'){
                    //they didn't select something from the list
                    //do a search for place.name
                    console.log("something from inputbox");



                    props.setSearchingForLocation(place.name);

                    handlePromptChangeLocationOK(place);
                } else {
                    //they DID select something from the list
                    console.log("something from list");
                    const geometry = place.geometry;

                    let _address_line = "";
                    let _address_city = "";
                    let _address_state = "";
                    let _address_zip = "";
                    let _address_country = "";

                    let _route_exists = false;
                    let _country_code = "";
                    for (const component of place.address_components){
                        if (component.types.includes("route")) {
                            _route_exists = true;
                        } else if (component.types.includes("country")){
                            _country_code = component.short_name;
                        }
                    }

                    place.address_components.forEach((component) => {
                        if (component.types.includes("street_number")){
                            _address_line = component.long_name;
                        } else if (component.types.includes("route")){
                            _address_line += " "+component.long_name;
                        } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                            _address_line += " "+component.long_name;
                        } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                            _address_city = component.long_name;
                        } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                            _address_state = component.short_name;
                        } else if (component.types.includes("postal_code")){
                            _address_zip = component.long_name;
                        } else if (component.types.includes("country")){
                            if (_country_code !== "US"){
                                _address_state = component.long_name;
                            }
                            _address_country = component.short_name;
                        }
                    });

                    if (_address_line === ""){
                        //try getting it from the formatted address
                        try {
                            const _f_address_pieces = place.formatted_address.split(',');
                            if (_f_address_pieces[0] !== ""){
                                _address_line = _f_address_pieces[0].trim();
                            }
                        } catch (e){}
                    }

                    console.log("the _address_line : "+_address_line);
                    console.log("the _address_city : "+_address_city);
                    console.log("the _address_state : "+_address_state);
                    console.log("the _address_zip: "+_address_zip);
                    console.log("the _address_country: "+_address_country);

                    if (Object.keys(Planning).length === 0){
                        //create a new Event
                        const newEvent = new PAEvent();

                        newEvent.title = "A New Event";

                        console.log("New Event : "+JSON.stringify(newEvent, null, '\t'));

                        setPlanning(newEvent);

                    }

                    setCurrentLocation(place.formatted_address);
                    props.setZoomInOnLocation({
                        'google_place_id': place.place_id,
                        'latitude': geometry.location.lat(),
                        'longitude': geometry.location.lng()
                    });
                    setShowPromptChangeLocation(false);
                    setUpdatedLocation("");
                    props.setShowEventItemConfig(false);
                    props.setSearchingForLocation(_address_city+' '+_address_state);

                    needToCheckWeather();

                }

            });
        }
    }, [showPromptChangeLocation]);


    function needToCheckWeather(){

        if (Planning && Planning.begin_date && Planning.location.google_place_id) {
            // Both properties are not empty, so call your function here
            console.log("Pull the weather using this new location")
            props.setPullWeatherReport(true);

        }
    }



    return (

        <div className="location-selection-container" >
            {/* show the header */}
            <div
                className="location-selection-header"
            >
                <div className="location-selection-header-text">
                    <h5>Event Location</h5>
                </div>
                <div className="location-selection-header-chip-holder">
                    <Chip
                        sx={cancelGradientStyle}
                        label="Cancel"
                        size="small"
                        onClick={handlePromptChangeLocationClose}
                    />
                    <Chip
                        sx={addGradientStyle}
                        className="location-selection-add-chip"
                        label="OK"
                        size="small"
                        onClick={handlePromptChangeLocationOK}
                    />
                </div>
            </div>


            {/* show the location input */}
            <div className="location-selection-dates-container">
                <Grid spacing={2} direction="column" sx={{padding: '0 10px'}}>

                <Grid item sx={{marginBottom:'20px'}}>
                    <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        id="event_location_input_box"
                        type="text"
                        variant="standard"
                        inputRef={inputLocationRef}
                        value={updatedLocation}
                        onChange={handleUpdatedLocation}
                    />
                </Grid>

            </Grid>

        </div>
        </div>
    )

}

export default LocationDetails;
