
import Location from "./Location";
import PAAttendee from "./Attendee";
import TimeTable from "./TimeTable";



class Activity {
    constructor(){

        const _temp = PAAttendee.generateRandomString(10);
        this.temp_id = _temp;
        this.id = _temp;
        this.created = 0;
        this.title = "";

        this.time_table = new TimeTable();

        this.begin_date = "";
        this.end_date = "";

        this.begin_day = "";
        this.end_day = "";

        this.begin_time = "";
        this.end_time = "";
        this.all_day_activity = true;

        this.details = "";
        this.bring = [];

        this.location = new Location();		//location object

        this.phone = "";
        this.booked_through = "";
        this.confirmation_number = "";
        this.reservation_number = "";
        this.reservation_name = "";
        this.website = "";

        this.attendees = [];

        this.photos = [];

        this.amount_total = 0.00;
        this.currency = "USD";
        this.assign_equal = true;

        this.notes= "";

        this.Duffel = {};
        this.Place = {};

        this.status = 0;
    }
}

export default Activity;
