import Message from "../../../MasterEventObjects/Message";
import {addEventMessage} from "../../../Services/BrowserDatabaseFunctions";




export const E_getEventMessagesResults = async (_incoming) => {
    console.log("E_getEventMessagesResults");

    /*
        add any Event Messages
     */


    try {

        const _result = _incoming.result;

        if (_result === "ok") {
            try {

                if (_incoming.messages_list.length > 0){

                    for (const _message of _incoming.messages_list){

                        const newMessage = new Message();
                        newMessage.id = _message.id;
                        newMessage.created = _message.created;

                        newMessage.priority = _message.priority;      //this can be an alert or something important from the Event organizer

                        newMessage.event_id = _message.event_id;
                        newMessage.attendee_id = _message.attendee_id;

                        newMessage.link_with_id = _message.linked_with_id;
                        newMessage.recipients = _message.recipients;

                        newMessage.message = _message.message;

                        newMessage.status = _message.status;

                        /*
                            add to the Browser database
                         */
                        try {
                            await addEventMessage(_incoming.event_id, newMessage);
                            console.log("newMessage added successfully");
                        } catch (error) {
                            console.error("Error adding newMessage: ", error);
                        }
                    }

                }

                return true; // Return the updated message regardless of success or failure

            } catch (error) {
                console.error("Unexpected error: ", error);
                return null; // Return null if there was an unexpected error
            }

        } else {
            console.log("Add result not ok");
            return null; // Return null if the result is not ok
        }
    } catch (e){
        console.log("ERROROROR : "+e);
    }

}
