import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import {Card, CardContent, CardMedia, Chip, Grid, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import MealCard from "../Meals/MealCard";
import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";
import './meals.css';
import Box from "@mui/material/Box";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";

function MealsList(props) {

    const navigate = useNavigate();
    const _global_map_ref = useRef();

    const { dataArray, setPlanningMeals } = useContext(PAContext);
    const { Planning } = dataArray;

    const [eventMeals, setEventMeals] = useState([]);

    const [anchorMealsListMenu, setAnchorMealsListMenu] = useState(null);
    const handleMealsListMenuOpenMenu = (event) => {
        setAnchorMealsListMenu(event.currentTarget);
    };
    const handleMealsListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorMealsListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Meals/AddNew');
        }
    };
    const handleMealsListMenuClose = () => {
        setAnchorMealsListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    useEffect(() => {
        console.log("Planning.meals : "+JSON.stringify(Planning.meals, null, '\t'));
        setEventMeals([...Planning.meals]);
    }, [Planning.meals]);

    const updateMealDetails = (updatedMeal) => {
        // Find the index of the updated Meal in eventMeals
        const mealIndex = eventMeals.findIndex((meal) => meal.id === updatedMeal.id);

        console.log("mealIndex to update : "+mealIndex);

        if (mealIndex !== -1) {
            // If the meal is found, update it in the eventMeals array
            const updatedEventMealsArray = [...eventMeals];
            updatedEventMealsArray[mealIndex] = updatedMeal;

            setEventMeals(updatedEventMealsArray);

            //this updates the meal at the PAContext level
            setPlanningMeals(Planning['id'], updatedEventMealsArray);

            console.log("updatedEventMeals");
        }

    };






    return (

        <div className="meals-list-container">

            <div
                ref={_global_map_ref}
                id="global_google_map_holder1"
                style={{display: 'none'}}
            />

            {/* show the header */}
            <div
                className="meals-list-header"
            >
                <div className="meals-list-header-text">
                    <h5>Meals List</h5>
                </div>
                <div className="meals-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize: '16px'}}/>
                        }
                        size="small"
                        onClick={handleMealsListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorMealsListMenu}
                        id="library-menu"
                        open={Boolean(anchorMealsListMenu)}
                        onClose={handleMealsListMenuClose}

                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList
                            dense
                            sx={{width: 320, maxWidth: '100%'}}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleMealsListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of meals for dates/days */}

            <div className="meals-list-timetable-list-container">
                {
                    eventMeals ? (
                        (() => {
                            const allMealChips = [];

                            Planning['time_table'].dates.forEach((eventDateObj) => {
                                const eventDate = eventDateObj.date;
                                const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd'); // e.g., "Wed"
                                const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D'); // e.g., "10/13"

                                // Filter meals for this specific date
                                const mealsForDate = eventMeals.filter(meal =>
                                    meal.time_table?.dates?.length > 0 && meal.time_table.dates[0].date === eventDate
                                );

                                const mealCount = mealsForDate.length;

                                // If there are meals for the date, show a Chip with a badge showing the count
                                if (mealCount > 0) {
                                    allMealChips.push({
                                        date: eventDate, // Use the event date for sorting
                                        component: (
                                            <Box key={`meal-${eventDate}`}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Chip Component with Meal Count */}
                                                <Chip
                                                    label={
                                                        <div style={{textAlign: 'center'}}>
                                                            <Typography variant="body2">{formattedDay}</Typography>
                                                            <Typography variant="caption">{formattedDate}</Typography>
                                                        </div>
                                                    }
                                                    sx={unselectedChipStyle} // Style for the Chips
                                                    style={{margin: '0.2rem', height: '40px'}}
                                                />

                                                {/* Badge with meal count */}
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-5px',
                                                        right: '-5px',
                                                        backgroundColor: 'white',
                                                        color: 'green',
                                                        borderRadius: '35%',
                                                        width: '15px',
                                                        height: '15px',
                                                        fontSize: '12px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1,
                                                        border: '1px solid',
                                                        fontWeight: '700'
                                                    }}
                                                >
                                        {mealCount}
                                    </span>
                                            </Box>
                                        )
                                    });
                                } else {
                                    // If no meals exist for the date, show a Chip with a warning icon
                                    allMealChips.push({
                                        date: eventDate, // Use the event date for sorting
                                        component: (
                                            <Box key={`placeholder-meal-${eventDate}`}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Chip Component without meals (with warning icon) */}
                                                <Chip
                                                    label={
                                                        <div style={{textAlign: 'center'}}>
                                                            <Typography variant="body2">{formattedDay}</Typography>
                                                            <Typography variant="caption">{formattedDate}</Typography>
                                                        </div>
                                                    }
                                                    sx={unselectedChipStyle} // Style for the placeholder Chips
                                                    style={{margin: '0.2rem', height: '40px'}}
                                                />

                                                {/* Warning icon */}
                                                <i
                                                    className="icon-warning"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-5px',
                                                        right: '-5px',
                                                        fontSize: '10px',
                                                        color: 'red',
                                                        backgroundColor: 'white',  // White background for the circle
                                                        borderRadius: '50%',       // Make it a circle
                                                        padding: '3px',            // Space between the icon and the border
                                                        zIndex: 1
                                                    }}
                                                />
                                            </Box>
                                        )
                                    });
                                }
                            });

                            // Now, return the rendered components from allMealChips
                            return allMealChips.map((chip, idx) => (
                                <React.Fragment key={idx}>
                                    {chip.component}
                                </React.Fragment>
                            ));

                        })()
                    ) : (
                        <div style={{padding: '20px'}}>
                            <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                            <Typography variant="caption">Need to add some Meal information first</Typography>
                        </div>
                    )
                }
            </div>


            {/* show the list of meals */}
            <div className="meals-list-items-container">

                {
                    eventMeals && eventMeals.length > 0 ? (
                        eventMeals
                            .sort((a, b) => {
                                // First, compare by date
                                const dateComparison = dayjs(a.date).diff(dayjs(b.date));
                                if (dateComparison !== 0) {
                                    return dateComparison;
                                }

                                // If the dates are the same, compare by time
                                return dayjs(a.time, 'HH:mm A').diff(dayjs(b.time, 'HH:mm A'));
                            })
                            .map((mealDetails, index) => (

                                <Grid item key={index} sx={{
                                    display: 'block',
                                    marginBottom: '10px'
                                }}
                                >

                                    <MealCard
                                        meal={mealDetails}
                                        updateMealDetails={updateMealDetails}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item>
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 140}}
                                        image={lodgingHeaderImage}
                                        title="Add Lodging"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Lodging
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Lodgings:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels,
                                            Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal
                                            Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and
                                            let you see what we've
                                            found. You can edit the results before we actually add the details to the
                                            Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click
                                            on the
                                            <i className="icon-menu2"/> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact
                                            Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

        </div>
    )

}

export default MealsList;
