import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Routes,
    Route
} from "react-router-dom";

import {PAContext} from "./Services/PAContext";

import Home from './Pages/HomePage/Home';
import Features from "./Pages/FeaturesPage/Features";
import Login from "./Pages/LoginPage/Login";
import Dashboard from './components/Restricted/Dashboard/Dashboard';
import CreateEvent from "./components/CreateEventPage/CreateEvent";

import './App.css';

import {Alert, Snackbar} from "@mui/material";
import Header from "./Pages/PageHeader/Header";
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Planner from "./components/Restricted/Planner/Planner";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Pricing from "./Pages/PricingPage/Pricing";
import Contact from "./Pages/ContactUsPage/Contact";
import Signup from "./Pages/SignupPage/Signup";
import Admins from "./Pages/Admins/Admins";



function App() {

    const isInitialMount = useRef(true);
    const { dataArray, setShowSuccessSnackbar } = useContext(PAContext);

    const [loginSuccessOpen, setLoginSuccessOpen] = useState(false);
    const handleSnackbarLoginClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLoginSuccessOpen(false);
    };
    const [logoutSuccessOpen, setLogoutSuccessOpen] = useState(false);
    const handleSnackbarLogoutSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLogoutSuccessOpen(false);
    };
    const [logoutFailedOpen, setLogoutFailedOpen] = useState(false);
    const handleSnackbarLogoutFailedClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLogoutFailedOpen(false);
    };

    const [showSuccessOpen, setShowSuccessOpen] = useState(false);
    const handleSnackbarSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
//            return;
        }
        setShowSuccessOpen(false);

        setShowSuccessSnackbar(false);
    };

    const [isSearching, setIsSearching] = useState(false);
    const [searchingFor, setSearchingFor] = useState('');

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            dataArray.authenticated ? setLoginSuccessOpen(true) : setLogoutSuccessOpen(true);
        }
    }, [dataArray.authenticated]);

    useEffect(() => {
        setShowSuccessOpen(dataArray.showSuccessSnackbar);
    }, [dataArray.showSuccessSnackbar]);

    const theme = createTheme({
        palette: {
            badge: {
                main: '#cbe0f7'
            },
        }
    });

    const [viewingEventGroup, setViewingEventGroup] = useState("current");

    return (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <div className="app">

                    <div className="app-header-holder">
                        <Header setViewingEventGroup={setViewingEventGroup}/>
                    </div>

                    <div className="app-routes-holder">

                        <Routes>

                            <Route
                                path="/*"
                                element={
                                    <Home
                                        setIsSearching={setIsSearching}
                                        setSearchingFor={setSearchingFor}
                                    />
                                }
                            />

                            <Route path="/Features" element={<Features />} />
                            <Route path="/Pricing" element={<Pricing />} />
                            <Route path="/ContactUs" element={<Contact />} />
                            <Route path="/login" element={<Login />} />

                            <Route
                                path="/Planner/*"
                                element={
                                    <Planner
                                        isSearching={isSearching}
                                        searchingFor={searchingFor}
                                    />
                                }
                            />
                            <Route path="/dashboard/*" element={
                                <Dashboard
                                    viewingEventGroup={viewingEventGroup}
                                    setViewingEventGroup={setViewingEventGroup}/>
                            } />
                            <Route path="/createEvent" element={<CreateEvent />} />

                            <Route path="/SignUp" element={<Signup />} />

                            <Route path="/Admins/*" element={<Admins />} />

                        </Routes>

                    </div>

                    <Snackbar
                        open={loginSuccessOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarLoginClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarLoginClose} severity="success" sx={{ width: '100%' }}>
                            Success
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={logoutSuccessOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarLogoutSuccessClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarLogoutSuccessClose} severity="success" sx={{ width: '100%' }}>
                            Success
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={logoutFailedOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarLogoutFailedClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarLogoutFailedClose} severity="error" sx={{ width: '100%' }}>
                            Failed
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        open={showSuccessOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarSuccessClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarSuccessClose} severity="success" sx={{ width: '100%' }}>
                            Success
                        </Alert>
                    </Snackbar>

                </div>
            </ThemeProvider>
        </LocalizationProvider>


    )
}

export default App;
