import {browserStorageUpdateSpecificEvent} from "../../../Services/BrowserDatabaseFunctions";


function mergeChanges(existing, changes) {
    if (!Array.isArray(existing)) {
        existing = [];
    }

    if (Array.isArray(changes)) {
        const changeIds = new Set(changes.map(change => change.id));

        // Remove items from existing that are not in changes
        for (let i = existing.length - 1; i >= 0; i--) {
            if (!changeIds.has(existing[i].id)) {
                existing.splice(i, 1);
            }
        }

        // Add or update items from changes to existing
        changes.forEach(change => {
            const index = existing.findIndex(item => item.id === change.id);
            if (index !== -1) {
                existing[index] = change; // Update existing item
            } else {
                existing.push(change); // Add new item
            }
        });
    }
}


function E_getEventChangesResults(_incoming, _planning) {
    console.log("E_getEventChangesResults : "+JSON.stringify(_incoming, null, '\t'));

    /*
        this brings in the results of the Photo upload
        the full size image should already be here and the generated thumbnail will be coming once the
        resizing process is complete
    */

    if (_incoming.result === 'ok') {
        if (_incoming.events && _incoming.events.length > 0) {

            console.log("Event(s) need updating: " + _incoming.events.length);

            _incoming.events.forEach(_evt => {
                browserStorageUpdateSpecificEvent(_evt).then(r => {
                    console.log("Back from updating browser storage for: " + _evt.name);
                    console.log("_planning.id === _evt.id? : " + _planning.id + " : "+ _evt.id);
                    if (_planning.id === _evt.id) {
                        console.log("the update was for the Event they're working on");
                        // See exactly what needs to be updated
                        // Check each part of the Event
                        _planning.title = _evt.name;
                        _planning.description = _evt.description;
                        _planning.eventIntro = _evt.eventIntro;
                        _planning.coverImage = _evt.coverImage;
                        _planning.image = _evt.image;
                        _planning.cover_image_thumbnail = _evt.cover_image_thumbnail;
                        _planning.host = _evt.host;
                        _planning.budget_total = _evt.budget_total;
                        _planning.budget_currency = _evt.budget_currency;
                        _planning.budget_actual = _evt.budget_actual;

                        mergeChanges(_planning.attendees, _evt.attendees);
                        mergeChanges(_planning.activities, _evt.activities);
                        mergeChanges(_planning.lodging, _evt.lodging);
                        mergeChanges(_planning.meals, _evt.meals);
                        mergeChanges(_planning.ideas, _evt.ideas);
                        mergeChanges(_planning.flights, _evt.flights);
                        mergeChanges(_planning.car_rentals, _evt.car_rentals);
                        mergeChanges(_planning.cruises, _evt.cruises);
                        mergeChanges(_planning.supplies, _evt.supplies);
                        mergeChanges(_planning.to_do, _evt.to_do);
                        mergeChanges(_planning.documents, _evt.documents);
                        mergeChanges(_planning.receipts, _evt.receipts);
                        mergeChanges(_planning.messages, _evt.messages);

                        mergeChanges(_planning.menu_items, _evt.menu_items);
                        mergeChanges(_planning.weather, _evt.weather);
                        mergeChanges(_planning.suggestions, _evt.suggestions);

                        /*
                            if any new photos were added then we need to get those
                            or if some were deleted then they need to be removed
                         */

                        mergeChanges(_planning.photos, _evt.photos);



                        console.log("Planning object updated with event details:", _planning);
                    } else {
                        console.log("the update was NOT for the Event they're working on");
                    }
                }).catch(error => {
                    console.error("Error updating browser storage for event: " + _evt.name, error);
                });
            });
        } else {
            console.log("No Events need updating");
        }

    } else {
        // Handle problems getting the updated Events list
        console.error("Problems getting the updated Events list");
    }
    
}

export default E_getEventChangesResults;



export function E_setEventAllChangesResults(_incoming, _planning){
    console.log("E_setEventAllChangesResults : "+JSON.stringify(_incoming, null, '\t'));

    if (_incoming.result === 'ok') {
        //update the
        browserStorageUpdateSpecificEvent(_planning).then(_success => {
            if (_success){
                console.log("successful save to browser database");
            } else {
                console.log("problems saving Event to browser database");
            }

        });

    } else {
        // Handle problems getting the updated Events list
        console.error("Problems getting the updated Events list");
    }

}
