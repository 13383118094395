

import Activity from "../../../MasterEventObjects/Activity";
import {addEventActivity, deleteEventActivity, updateEventActivity} from "../../../Services/BrowserDatabaseFunctions";
import PALocation from "../../../MasterEventObjects/Location";


export const E_addEventActivityResults = async (_incoming) => {
    console.log("E_addNewActivityResults");

    /*
        an Event Activity was added
     */


    try {

        const _result = _incoming.result;

        if (_result === "ok") {
            try {
                const newActivity = new Activity();

                /*
                    see if the incoming needs to be converted
                    look at the location
                 */
                newActivity.id = _incoming.activity_id;
                newActivity.title = _incoming.title;
                newActivity.begin_date = _incoming.begin_date;
                newActivity.end_date = _incoming.end_date;
                newActivity.begin_time = _incoming.begin_time;
                newActivity.end_time = _incoming.end_time;
                newActivity.all_day_activity = _incoming.all_day_activity;

                if (_incoming.location === undefined) {
                    // Convert the activity location
                    const _activity_location = new PALocation();
                    _activity_location.google_place_id = _incoming.location_google_place_id;
                    _activity_location.latitude = _incoming.location_latitude;
                    _activity_location.longitude = _incoming.location_longitude;

                    _activity_location.name = _incoming.location_name;
                    _activity_location.formatted_address = "";
                    _activity_location.address = _incoming.location_address;
                    _activity_location.city = _incoming.location_city;
                    _activity_location.state = _incoming.location_state;
                    _activity_location.zip = _incoming.location_zip;
                    _activity_location.country = _incoming.location_country;

                    newActivity.location = _activity_location;

                    newActivity.phone = _incoming.location_phone;
                    newActivity.booked_through = _incoming.location_booked_through;
                    newActivity.confirmation_number = _incoming.location_confirmation_number;
                    newActivity.reservation_number = _incoming.location_reservation_number;
                    newActivity.reservation_name = _incoming.location_reservation_name;

                } else {
                    newActivity.location = _incoming.location;
                }

                for (const _att of _incoming.attendees) {
                    if (_att.costs === undefined) {
                        const _att_with_cost = {};
                        _att_with_cost.id = _att;
                        _att_with_cost.cost = 0;
                        newActivity.attendees.push(_att_with_cost);
                    } else {
                        newActivity.attendees.push(_att);
                    }
                }

                newActivity.amount_total = _incoming.amount_total === undefined ? 0 : _incoming.amount_total;
                newActivity.currency = _incoming.currency === undefined ? "USD" : _incoming.currency;

                newActivity.details = _incoming.details;
                newActivity.bring = _incoming.bring;
                newActivity.notes = _incoming.notes;

                try {
                    await addEventActivity(_incoming.event_id, newActivity);
                    console.log("Activity added successfully");
                } catch (error) {
                    console.error("Error adding Activity: ", error);
                }

                return newActivity; // Return the updated activity regardless of success or failure

            } catch (error) {
                console.error("Unexpected error: ", error);
                return null; // Return null if there was an unexpected error
            }

        } else {
            console.log("Add result not ok");
            return null; // Return null if the result is not ok
        }
    } catch (e){
        console.log("ERROROROR : "+e);
    }

}

export const E_updateEventActivityResults = async (_incoming) => {
    console.log("E_updateEventActivityResults");

    /*
        an Event Activity was updated
     */

    try {
        const _result = _incoming.result;

        if (_result === "ok") {
            try {
                const updatedActivity = new Activity();

                /*
                    see if the incoming needs to be converted
                    look at the location
                 */
                updatedActivity.id = _incoming.activity_id;
                updatedActivity.title = _incoming.title;
                updatedActivity.begin_date = _incoming.begin_date;
                updatedActivity.end_date = _incoming.end_date;
                updatedActivity.begin_time = _incoming.begin_time;
                updatedActivity.end_time = _incoming.end_time;
                updatedActivity.all_day_activity = _incoming.all_day_activity;

                if (_incoming.location === undefined) {
                    // Convert the activity location
                    const _activity_location = new PALocation();
                    _activity_location.google_place_id = _incoming.location_google_place_id;
                    _activity_location.latitude = _incoming.location_latitude;
                    _activity_location.longitude = _incoming.location_longitude;

                    _activity_location.name = _incoming.location_name;
                    _activity_location.formatted_address = "";
                    _activity_location.address = _incoming.location_address;
                    _activity_location.city = _incoming.location_city;
                    _activity_location.state = _incoming.location_state;
                    _activity_location.zip = _incoming.location_zip;
                    _activity_location.country = _incoming.location_country;

                    updatedActivity.location = _activity_location;

                    updatedActivity.phone = _incoming.location_phone;
                    updatedActivity.booked_through = _incoming.location_booked_through;
                    updatedActivity.confirmation_number = _incoming.location_confirmation_number;
                    updatedActivity.reservation_number = _incoming.location_reservation_number;
                    updatedActivity.reservation_name = _incoming.location_reservation_name;

                } else {
                    updatedActivity.location = _incoming.location;
                }

                for (const _att of _incoming.attendees) {
                    if (_att.costs === undefined) {
                        const _att_with_cost = {};
                        _att_with_cost.id = _att;
                        _att_with_cost.cost = 0;
                        updatedActivity.attendees.push(_att_with_cost);
                    } else {
                        updatedActivity.attendees.push(_att);
                    }
                }

                updatedActivity.amount_total = _incoming.amount_total === undefined ? 0 : _incoming.amount_total;
                updatedActivity.currency = _incoming.currency === undefined ? "USD" : _incoming.currency;

                updatedActivity.details = _incoming.details;
                updatedActivity.bring = _incoming.bring;
                updatedActivity.notes = _incoming.notes;

                try {
                    await updateEventActivity(_incoming.event_id, updatedActivity);
                    console.log("Activity updated successfully");
                } catch (error) {
                    console.error("Error updating Activity: ", error);
                }

                return updatedActivity; // Return the updated activity regardless of success or failure

            } catch (error) {
                console.error("Unexpected error: ", error);
                return null; // Return null if there was an unexpected error
            }

        } else {
            console.log("Update result not ok");
            return null; // Return null if the result is not ok
        }

    } catch (e) {
        console.log("ERROROROR : " + e);
        return null; // Return null if there was a top-level error
    }
}

export const E_deleteEventActivityResults = async (_incoming) => {
    console.log("E_deleteEventActivityResults");

    /*
        an Event Activity was deleted
     */


    try {

        const _result = _incoming.result;

        if (_result === "ok") {

            try {
                await deleteEventActivity(_incoming.event_id, _incoming.activity_id);
                console.log("Activity deleted successfully");
            } catch (error) {
                console.error("Error deleting Activity: ", error);
            }

        }
    } catch (e){
        console.log("ERROROROR : "+e);
    }

}