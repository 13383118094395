import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Hidden from '@mui/material/Hidden';
import small_logo from '../../graphics/Color-logo-image-only.png';
import large_logo from '../../graphics/Color_logo_no_background.png';
import {
    Alert, Avatar,
    Button, Chip,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    FormControl, FormHelperText,
    InputAdornment,
    InputLabel, LinearProgress,
    ListItemButton,
    OutlinedInput
} from "@mui/material";
import {PAContext} from "../../Services/PAContext";
import {GoogleLogin} from "@react-oauth/google";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import './header.css';



const Header = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    console.log("location.pathname : "+location.pathname.replaceAll('/',''));

    const { dataArray, setSideMenuWidth, setLoginFailed } = useContext(PAContext);
    const { Account } = dataArray;

    const [progressBarIndicator, setProgressBarIndicator] = useState(0);
    useEffect(() => {
        console.log("dataArray.progressBar : "+dataArray.progressBar);
        setProgressBarIndicator(dataArray.progressBar);
    }, [dataArray.progressBar]);

    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    useEffect(() => {

        const path = location.pathname.replaceAll('/', '');

        if (path === "") {
            setSelectedMenuItem('home');
        } else if (path === "Features") {
            setSelectedMenuItem('features');
        } else if (path === "Pricing") {
            setSelectedMenuItem('pricing');
        } else if (path === "ContactUs") {
            setSelectedMenuItem('contact');
        } else if (path.startsWith("Planner")) {
            setSelectedMenuItem('planner');
        } else if (path === "Dashboard") {
            setSelectedMenuItem('dashboard');
        } else if (path === "SignUp") {
            setSelectedMenuItem('signup');
        }

    }, []);

    const [isDrawerOpen, setDrawerOpen] = useState(false);



    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const onLogoClick = () => {
        setSelectedMenuItem('');
        navigate('/');
        setSideMenuWidth(0);
    }
    const onButtonClick = (event) => {
        console.log('button click ', event.target.innerText);
        setDrawerOpen(false);

        if (event.target.innerText === "home"){
            setSelectedMenuItem('');
            navigate('/');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Features"){
            setSelectedMenuItem('features');
            navigate('/Features');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Pricing"){
            setSelectedMenuItem('pricing');
            navigate('/Pricing');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Contact Us"){
            setSelectedMenuItem('contact');
            navigate('/ContactUs');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Planner"){
            setSelectedMenuItem('planner');
            navigate('/Planner');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Admins"){
            setSelectedMenuItem('admins');
            navigate('/Admins');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Login"){
            setIsModalOpen(true);
        }
        if (event.target.innerText === "Logout"){
            //logout
            const _sendString = {};
            _sendString.action = "E_processUserLogout";
            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
        }
        if (event.target.innerText === "Dashboard"){
            setSelectedMenuItem('dashboard');
            props.setViewingEventGroup("current");
            navigate('/dashboard');
            dataArray.authenticated ? setSideMenuWidth(70) : setSideMenuWidth(0);
        }
        if (event.target.innerText === "Sign Up"){
            setSelectedMenuItem('signup');
            navigate('/SignUp');
            setSideMenuWidth(0);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [usernameErrorStatus, setUsernameErrorStatus] = useState(false);
    const [passwordErrorStatus, setPasswordErrorStatus] = useState(false);


    const handleUsernameInputChange = (event) => {
        const inputValue = event.target.value;
        setUsername(inputValue);
        if (inputValue.length > 0){
            setUsernameErrorStatus(false);
            setLoginFailed(false);
        }
    }
    const handlePasswordInputChange = (event) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
        if (inputValue.length > 0){
            setPasswordErrorStatus(false);
            setLoginFailed(false);
        }
    }

    const handleOk = () => {
        console.log("handleOk");

        //check input values
        if (username !== "" && password !== ""){

            console.log("dataArray.Websocket : "+dataArray.Websocket);

            if (dataArray.Websocket){

                const _sendString = {};
                _sendString.action = "E_processUserLogin";
                _sendString.username = username;
                _sendString.password = encode64(password);

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            } else {
                //websocket not available

            }

        } else {
            //something wrong with username or password
            username === "" ? setUsernameErrorStatus(true):setUsernameErrorStatus(false)
            password === "" ? setPasswordErrorStatus(true):setPasswordErrorStatus(false)
        }

    };
    const handleCancel = () => {
        console.log("handleCancel");
        setIsModalOpen(false);

    };

    useEffect(() => {
        setIsModalOpen(false);
    }, [dataArray.authenticated]);


    const keyStr = "ABCDEFGHIJKLMNOP" +
        "QRSTUVWXYZabcdef" +
        "ghijklmnopqrstuv" +
        "wxyz0123456789+/" +
        "=";

    function encode64(input) {
        input = escape(input);
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;

        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                keyStr.charAt(enc1) +
                keyStr.charAt(enc2) +
                keyStr.charAt(enc3) +
                keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);

        return output;
    }

    function decode64(input) {
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;

        // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
        const base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
            alert("There were invalid base64 characters in the input text.\n" +
                "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
                "Expect errors in decoding.");
        }
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        do {
            enc1 = keyStr.indexOf(input.charAt(i++));
            enc2 = keyStr.indexOf(input.charAt(i++));
            enc3 = keyStr.indexOf(input.charAt(i++));
            enc4 = keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 !== 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output = output + String.fromCharCode(chr3);
            }

            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";

        } while (i < input.length);

        return unescape(output);
    }


    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: `0 0 5px ${stringToColor(name)}`,
                border: `1px solid ${stringToColor(name)}`,
                borderRadius: '10px',
                textAlign: 'center',
                fontWeight: '700',
                backgroundColor: '#FFF',
                color: 'black', // Text color (adjust as needed)
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (

        <Container
            sx={{
                position: 'sticky',
                top : 0,
                maxWidth: '100% !important',
                padding: '0 !important',
                zIndex: 1,
                overflow: 'auto'
            }}>

            <LinearProgress variant="determinate" value={progressBarIndicator} />

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: (dataArray.authenticated ? (dataArray.sideMenuWidth===0 ? 0 : dataArray.sideMenuWidth+"px") : 0),
                position: 'relative',
                top: 0,
                left: 0,
                backdropFilter: 'blur(6px)',
                backgroundColor: 'rgba(249, 250, 251, 0.8)',
                zIndex: 1
            }}>

                <div className="logo-holder">

                    <div
                        style={{
                            backgroundColor: dataArray.ws_connected ? "green" : "red"
                        }}
                        id="ws_connection"
                        className="ws-connection"
                    />
                    <Hidden mdDown implementation="css">
                        <img
                            className="logo-large"
                            src={large_logo}
                            onClick={onLogoClick}
                            alt=""
                        />
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <img
                            className="logo"
                            src={small_logo}
                            onClick={onLogoClick}
                            alt=""
                        />
                    </Hidden>
                </div>

                {/* Desktop links */}
                <Hidden mdDown implementation="css">
                    <div style={{ justifyContent: 'flex-end' }}>
                        <List style={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemButton
                                className={selectedMenuItem === 'features' ? 'show-underline' : 'underline'}
                                onClick={onButtonClick}
                            >
                                <ListItemText
                                    primary="Features"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                className={selectedMenuItem === 'pricing' ? 'show-underline' : 'underline'}
                                onClick={onButtonClick}
                            >
                                <ListItemText
                                    primary="Pricing"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                className={selectedMenuItem === 'contact' ? 'show-underline' : 'underline'}
                                onClick={onButtonClick}
                            >
                                <ListItemText
                                    primary="Contact Us"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                className={selectedMenuItem === 'planner' ? 'show-underline' : 'underline'}
                                onClick={onButtonClick}
                            >
                                <ListItemText
                                    primary="Planner"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                />
                            </ListItemButton>
                            {dataArray.authenticated ? (
                                <>
                                    <ListItemButton
                                        className={selectedMenuItem === 'dashboard' ? 'show-underline' : 'underline'}
                                        onClick={onButtonClick}
                                    >
                                        <ListItemText
                                            primary="Dashboard"
                                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        />
                                    </ListItemButton>
                                    {
                                        Account.role === "master_admin" ? (
                                            <ListItemButton
                                                className={selectedMenuItem === 'admins' ? 'show-underline' : 'underline'}
                                                onClick={onButtonClick}
                                            >
                                                <ListItemText
                                                    primary="Admins"
                                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                                />
                                            </ListItemButton>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <ListItemButton
                                        className={selectedMenuItem === 'logout' ? 'show-underline' : 'underline'}
                                        onClick={onButtonClick}
                                    >
                                        <Avatar {...stringAvatar(`${formatName(dataArray.Account.fname)}, ${formatName(dataArray.Account.lname)}`)} />
                                    </ListItemButton>

                                </>
                            ) : (
                                <>
                                    <ListItemButton
                                        className={selectedMenuItem === 'login' ? 'show-underlined' : 'underline'}
                                        onClick={onButtonClick}
                                    >
                                        <ListItemText
                                            primary="Login"
                                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        />
                                    </ListItemButton>
                                    <ListItemButton
                                        className={selectedMenuItem === 'signup' ? 'show-underline' : 'underline'}
                                        onClick={onButtonClick}
                                    >
                                        <ListItemText
                                            primary="Sign Up"
                                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        />
                                    </ListItemButton>
                                </>
                            )}
                        </List>
                    </div>
                </Hidden>

                {/* Hamburger icon for mobile */}
                <Hidden mdUp implementation="css">
                    <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>

            </div>

            {/* Mobile drawer */}
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
                <List>
                    <ListItemButton onClick={onButtonClick}>
                        <ListItemText
                            primary="Features"
                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                            sx={{ textDecoration: selectedMenuItem === 'Features' ? 'underline' : 'none' }} />
                    </ListItemButton>
                    <ListItemButton onClick={onButtonClick}>
                        <ListItemText
                            primary="Pricing"
                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                            sx={{ textDecoration: selectedMenuItem === 'Pricing' ? 'underline' : 'none' }} />
                    </ListItemButton>
                    <ListItemButton onClick={onButtonClick}>
                        <ListItemText
                            primary="Contact Us"
                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                            sx={{ textDecoration: selectedMenuItem === 'Contact Us' ? 'underline' : 'none' }} />
                    </ListItemButton>
                    <ListItemButton onClick={onButtonClick}>
                        <ListItemText
                            primary="Planner"
                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                            sx={{ textDecoration: selectedMenuItem === 'Planner' ? 'underline' : 'none' }} />
                    </ListItemButton>
                    {dataArray.authenticated ? (
                        <>
                            <ListItemButton onClick={onButtonClick}>
                                <ListItemText
                                    primary="Dashboard"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                    sx={{ textDecoration: selectedMenuItem === 'Dashboard' ? 'underline' : 'none' }} />
                            </ListItemButton>
                            {
                                Account.role === "master_admin" ? (
                                    <ListItemButton
                                        className={selectedMenuItem === 'admins' ? 'show-underline' : 'underline'}
                                        onClick={onButtonClick}
                                    >
                                        <ListItemText
                                            primary="Admins"
                                            primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        />
                                    </ListItemButton>
                                ) : (
                                    <></>
                                )
                            }
                            <ListItemButton onClick={onButtonClick}>
                                <ListItemText
                                    primary={`Logout (${dataArray.Account.fname})`}
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                    sx={{ textDecoration: selectedMenuItem === 'logout' ? 'underline' : 'none' }} />
                            </ListItemButton>

                        </>
                    ) : (
                        <>
                            <ListItemButton onClick={onButtonClick}>
                                <ListItemText
                                    primary="Login"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                    sx={{ textDecoration: selectedMenuItem === 'Login' ? 'underline' : 'none' }} />
                            </ListItemButton>
                            <ListItemButton onClick={onButtonClick}>
                                <ListItemText
                                    primary="Sign Up"
                                    primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                    sx={{ textDecoration: selectedMenuItem === 'Sign Up' ? 'underline' : 'none' }} />
                            </ListItemButton>
                        </>
                    )}
                </List>
            </Drawer>


            <Dialog
                sx={{ '& .MuiDialog-paper': { maxHeight: 435 } }}
                open={isModalOpen}
            >
                <DialogTitle>Please Login</DialogTitle>

                <DialogContent >

                    <GoogleLogin
                        shape="pill"
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse.credential);

                            setLoginFailed(false);

                            //send this to the server for processing
                            const _sendString = {};
                            _sendString.action = "E_processUserLoginUsingGoogleJWT";
                            _sendString.jwt = credentialResponse;

                            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />

                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Divider>
                            <Chip label="OR" />
                        </Divider>
                    </div>

                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-username"
                            variant="outlined"
                            type="text"
                            label="Username"
                            onChange={handleUsernameInputChange}
                            value={username}
                            error={usernameErrorStatus}
                        />
                        {usernameErrorStatus && (
                            <FormHelperText sx={{color:"red"}}>* required</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={handlePasswordInputChange}
                            value={password}
                            error={passwordErrorStatus}
                        />
                        {passwordErrorStatus && (
                            <FormHelperText sx={{color:"red"}}>* required</FormHelperText>
                        )}
                    </FormControl>

                </DialogContent>


                {dataArray.loginFailed && (
                    <Alert severity="error">Incorrect username or password</Alert>
                )}


                <DialogActions>
                    <Button autoFocus onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk}>Ok</Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
};

export default Header;
