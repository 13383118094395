export const selectedAttendeeStyle = {
    boxShadow: '0 0 5px #0096ff',
    border: '1px solid #0096ff',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#cbe0f7',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#cbe0f7', // Reverse the gradient on hover
    },
};

export const unselectedAttendeeStyle = {
    boxShadow: '0 0 5px #0096ff',
    border: '1px solid #0096ff',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#cbe0f7', // Reverse the gradient on hover
    },
};