class Message{

    constructor(){
        this.id = 0;
        this.created = 0;

        this.priority = false;      //this can be an alert or something important from the Event organizer

        this.event_id = "";
        this.attendee_id = "";

        this.link_with_id = 0;
        this.recipients = [];

        this.message = "";

        this.status = 0;
    }

}

export default Message;
