import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {Card, CardContent, CardMedia, Checkbox} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";



function TodoCard(props){

    const { dataArray } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [eventTodo, setEventTodo] = useState(props.to_do);
    const [selectedAction, setSelectedAction] = useState("");
    const [todoTabSelected, setTodoTabSelected] = useState("");

    useEffect(()=> {
        console.log("props.to_do has been updated --- : ");
        setEventTodo(props.to_do);
    }, [props.to_do]);

    const handleCheckboxChange = (event) => {
        // Update the eventTodo status based on the checkbox
        const updatedTodo = { ...eventTodo, status: event.target.checked ? 0 : 1 };
        setEventTodo(updatedTodo);

        // Optionally, you can call a function to update this in your backend or parent component
        if (props.onStatusChange) {
            props.onStatusChange(updatedTodo);
        }
    };

    const getPriorityIconColor = (priority) => {
        switch (priority) {
            case "Low":
                return "#151269"; // Replace with your desired color
            case "Medium":
                return "#00a3f6"; // Replace with your desired color
            case "High":
                return "#FFA500"; // Replace with your desired color
            case "Urgent":
                return "#F00"; // Replace with your desired color
            default:
                return "#151269"; // Default color if priority is not recognized
        }
    };

    const priorityIconColor = eventTodo ? getPriorityIconColor(eventTodo.priority) : 'gray';

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        return '';
    }

    return (

        <Card
            style={{width:'100%', marginBottom:'20px'}}
        >

            <CardContent>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {/* Left Icon */}
                    <IconButton edge="start" size="small" style={{color: priorityIconColor}}>
                        <i className="icon-alarm2" title="Time"/>
                    </IconButton>

                    {/* time of the To-Do item */}
                    <Typography
                        variant="body1"
                        style={{
                            flexGrow: 1,
                            marginLeft: 8,
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '5px'
                        }}
                    >
                        {
                            dayjs(eventTodo['schedule_date']).format('ddd, MMM D')
                        }
                    </Typography>

                    {/* Right Icon */}
                    <IconButton edge="end" size="small">
                        <i className="icon-edit2" title="Edit"/>
                    </IconButton>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {/* time of the To-Do item */}
                    <Typography
                        variant="caption"
                        style={{
                            flexGrow: 1,
                            marginLeft: 8,
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '5px'
                        }}
                    >
                        {
                            eventTodo['schedule_all_day'] ? (
                                'No Specific Time'
                            ) : (
                                eventTodo['schedule_begin_time_selected'] && eventTodo['schedule_end_time_selected'] ? (
                                    `${eventTodo['schedule_begin_time']} - ${eventTodo['schedule_end_time']}`
                                ) : eventTodo['schedule_begin_time_selected'] ? (
                                    eventTodo['schedule_begin_time']
                                ) : eventTodo['schedule_end_time_selected'] ? (
                                    eventTodo['schedule_end_time']
                                ) : (
                                    'No Time Selected'
                                )
                            )
                        }
                    </Typography>
                </div>
            </CardContent>
            <CardContent>
                {/* Title */}
                <Typography
                    variant="body1"
                    style={{
                        marginLeft: 8,
                        textDecoration: eventTodo && eventTodo.status === 0 ? 'line-through' : 'none',
                    }}
                >
                    {eventTodo ? eventTodo['title'] : <></>}
                </Typography>
                {/* Notes */}
                <Typography variant="caption" style={{marginLeft: 16}}>
                    {eventTodo ? eventTodo['notes'] : <></>}
                </Typography>


                {/*
                    for the scheduled reminders area
                    this is an array of integers that correlate to the days before the schedule_date
                    so a [1] means there is a reminder scheduled 1 day before the schedule_date
                    likewise, a [1,3] would mean that there is a scheduled reminder 1 day and 3 days before
                    the schedule_date
                 */}
                {
                    eventTodo['reminder_days_before_array'].length > 0 ? (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Left Icon */}
                                <IconButton edge="start" size="small" style={{ color: priorityIconColor }}>
                                    <i className="icon-bell-ringing2" title="Time" />
                                </IconButton>
                                <Typography variant="caption" style={{ marginLeft: 16 }}>Scheduled Reminder:</Typography>
                            </div>
                            {
                                eventTodo['reminder_days_before_array'].map((_reminder, index) => (
                                    <Typography key={index} variant="caption" style={{ marginLeft: 16 }}>
                                        {_reminder} {_reminder === 1 ? 'Day before at' : 'Days before at'} {eventTodo['reminder_days_before_time']}
                                    </Typography>
                                ))
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    eventTodo['reminder_day_of_time_selected'] ? (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Left Icon */}
                                <IconButton edge="start" size="small" style={{ color: priorityIconColor }}>
                                    <i className="icon-bell-ringing2" title="Time" />
                                </IconButton>
                                <Typography variant="caption" style={{ marginLeft: 16 }}>Scheduled Reminder:</Typography>
                            </div>
                            <Typography variant="caption" style={{ marginLeft: 16 }}>
                                Day of : {eventTodo['reminder_day_of_time']}
                            </Typography>
                        </div>
                    ) : (
                        <></>
                    )
                }

                {eventTodo['assigned_to'] !== eventTodo['created_by'] && (
                    <Typography variant="caption" style={{ marginLeft: 16 }}>
                        Assigned By: {capitalizeFirstLetter(Planning.attendees.find((_att) => _att.id === eventTodo['assigned_to'])?.fname || '')}
                    </Typography>
                )}

                {/* Checkbox for completion status */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Typography variant="body2" style={{ marginRight: 8 }}>Done</Typography>
                    <Checkbox
                        checked={eventTodo.status === 0}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'Done' }}
                    />
                </div>

            </CardContent>
        </Card>
    )
}

export default TodoCard;