
import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import PAAttendee from "../MasterEventObjects/Attendee";
import sample_attendees from "../sampleData/Attendees";

import {
    addKeyValue,
    browserStorageUpdateAllEvents,
    getAllEventsFromDatabase,
    removeEventsFromDatabase
} from './BrowserDatabaseFunctions';
import {openDb} from "./BrowserDatabaseIDB";
import E_uploadEventAttendeePhotosResults, {
    E_deleteEventPhotosResults,
    E_getEventFullSizeImageResults,
    E_getEventThumbnailImageResults,
    E_updateEventAttendeePhotoPrivateResults,
    E_uploadEventAttendeePhotoThumbnailResults, E_uploadEventImageResults
} from "../components/EventFeatures/IncomingActions/IncomingEventPhotoActions";
import E_getEventChangesResults from "../components/EventFeatures/IncomingActions/IncomingEventActions";
import {
    E_addEventActivityResults,
    E_deleteEventActivityResults,
    E_updateEventActivityResults
} from "../components/EventFeatures/IncomingActions/IncomingEventActivities";
import {
    E_addEventMessageResults,
    E_getEventMessagesResults
} from "../components/EventFeatures/IncomingActions/IncomingEventMessages";
import PAEvent from "../MasterEventObjects/PAEvent";
import E_setEventAllChangesResults from "../components/EventFeatures/IncomingActions/IncomingEventActions";

import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import TimeTable from "../MasterEventObjects/TimeTable";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);



const PAContext = createContext();


const PAContextProvider = ({ children }) => {
    console.log("PAContextProvider");

    const location = useLocation();

    let mdnIncomingTotalMessageSize = 0;
    let mdnIncomingReceivedMessageSize = 0;

    let leftover_incoming_stuff = "";
    let incoming_stuff_length = 0;

    const [dataArray, setDataArray] = useState({
        Websocket: null,
        ws_connected: false,
        showSuccessSnackbar: false,
        progressBar: 0,
        sideMenuWidth: 0,
        Account: {},
        Events: [],
        Categories: [],
        Attendees: sample_attendees,

        IncomingActions: [],
        IncomingEventActions: [],
        ConciergeActions: [],
        ConciergeMapActions: [],

        ConciergeScanningDocumentActions: [],

        FeaturesPipeline: [],
        LibraryPipeline: [],
        MapPipeline: [],
        ConciergePipeline: [],
        SuggestionsPipeline: [],
        SignupPipeline: [],

        authenticated: false,
        loginFailed: false,
        loading: false,
        loadingProgress: 0,
        Planning: new PAEvent(),

        ConciergePlanning: [],

        SuggestionsLodging: [],
        SuggestionsFlights: [],
        SuggestionsMeals: [],
        SuggestionsTours: [],
        SuggestionsUnique: [],

        PlanningLibrary: [],
        Partnerships: [],
        workingOnEventId: "",

        AdminsActions: [],

    });
    const userAccount = useRef(null);
    const [ userAuthenticated, setUserAuthenticated ] = useState(false);

    useEffect(() => {
        console.log("----------------------- the userAccount has been updated : "+JSON.stringify(userAccount, null, '\t'));
    }, [userAccount]);
    useEffect(() => {
        console.log("----------------------- the userAuthenticated has been updated : "+JSON.stringify(userAuthenticated, null, '\t'));
    }, [userAuthenticated]);

    useEffect(() => {
        console.log("-- PPPPPPP ---- : "+JSON.stringify(dataArray.Planning, null, '\t'));

        if (dataArray.Planning && dataArray.Planning.need_to_save) {
            updatePlanningTimeTable();
        }
    }, [dataArray.Planning]);

    const setWsConnected = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ws_connected: value,
        }));
    };
    const setShowSuccessSnackbar = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            showSuccessSnackbar: value,
        }));
    };
    const setProgressBar = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            progressBar: value,
        }));
    };
    const setSideMenuWidth = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            sideMenuWidth: value,
        }));
    };
    const setAccount = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Account: value,
        }));
    };
    const clearAccount = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Account: {},
        }));
    };
    const addEvent = (newEvent) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Events: [...prevDataArray.Events, newEvent],
        }));
    };



    const updateEventLodging = (event_id, updatedEventLodgingArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the lodging with the updated Lodgings
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].lodging = updatedEventLodgingArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventActivities = (event_id, updatedEventActivitiesArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the activities with the updated Activities
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].activities = updatedEventActivitiesArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventMeals = (event_id, updatedEventMealsArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the meals with the updated Meals
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].meals = updatedEventMealsArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventFlights = (event_id, updatedEventFlightsArray) => {
        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === event_id);

            if (index !== -1) {
                // If the event exists, replace the flights with the updated Flights
                const updatedEvents = [...prevDataArray.Events];
                updatedEvents[index].flights = updatedEventFlightsArray;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const updateEventMessages = (_incoming) => {
        console.log("updateEventMessages");

        setDataArray((prevDataArray) => {
            // Find the index of the event to update
            const index = prevDataArray.Events.findIndex((event) => event.id === _incoming.event_id);

            if (index !== -1) {
                // If the event exists, replace the messages with the updated Messages
                const updatedEvents = [...prevDataArray.Events];

                // Combine current messages with new, checking for duplicates
                const currentMessageIds = new Set(updatedEvents[index].messages.map(msg => msg.id));
                const newMessages = _incoming.messages_list.filter(msg => !currentMessageIds.has(msg.id));

                // Proceed with adding the new messages if they are not already present
                const updatedMessages = [...updatedEvents[index].messages, ...newMessages];

                // Calculate the latest timestamp
                const latest_message_timestamp = updatedMessages.reduce((latest, message) => {
                    return latest > message.created ? latest : message.created;
                }, 0); // Start with 0 or an initial very old timestamp

                updatedEvents[index].messages = updatedMessages;
                updatedEvents[index].latest_message_timestamp = latest_message_timestamp;
                updatedEvents[index].need_to_save = true;

                return {
                    ...prevDataArray,
                    Events: updatedEvents,
                };
            } else {
                // If the event doesn't exist, simply return the previous state
                return prevDataArray;
            }
        });
    };
    const clearEvents = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Events: [],
        }));
    };

    const addCategory = (newCategory) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Categories: [...prevDataArray.Categories, newCategory],
        }));
    };
    const clearCategories = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Categories: [],
        }));
    };
    const addAttendee = (newAttendee) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Attendees: [...prevDataArray.Attendees, newAttendee],
        }));
    };
    const clearAttendees = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Attendees: [],
        }));
    };
    const addIncomingActions = (newIncomingActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            IncomingActions: [...prevDataArray.IncomingActions, newIncomingActions],
        }));
    };
    const addIncomingEventActions = (newIncomingEventActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            IncomingEventActions: [...prevDataArray.IncomingEventActions, newIncomingEventActions],
        }));
    };
    const addIncomingConciergeActions = (newIncomingConciergeActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ConciergeActions: [...prevDataArray.ConciergeActions, newIncomingConciergeActions],
        }));
    };
    const addIncomingConciergeMapActions = (newIncomingConciergeMapActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ConciergeMapActions: [...prevDataArray.ConciergeMapActions, newIncomingConciergeMapActions],
        }));
    };

    const addIncomingConciergeScanningDocumentActions = (newIncomingConciergeScanningDocumentActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            ConciergeScanningDocumentActions: [...prevDataArray.ConciergeScanningDocumentActions, newIncomingConciergeScanningDocumentActions],
        }));
    };

    const addIncomingConciergeSuggestionActions = (newIncomingConciergeSuggestionActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsPipeline: [...prevDataArray.SuggestionsPipeline, newIncomingConciergeSuggestionActions],
        }));
    };

    const addIncomingConciergeSignupActions = (newIncomingConciergeSignupActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SignupPipeline: [...prevDataArray.SignupPipeline, newIncomingConciergeSignupActions],
        }));
    };

    const setAuthenticated = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            authenticated: value,
        }));
    };
    const setLoginFailed = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            loginFailed: value,
        }));
    };
    const setPlanning = (_new_planning_event) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: _new_planning_event
        }));
    };

    function updatePlanningTimeTable(){
        console.log("updatePlanningTimeTable");

        /*
            go through all of the different features (activities, flights, lodging, meals) to update the Event timetable
            so it encompasses all of the dates/days
         */

        const { activities, meals, lodging, flights } = dataArray.Planning;

        let minDate = null;
        let maxDate = null;

        // Helper function to update min and max dates
        const updateMinMaxDates = (date) => {
            const parsedDate = dayjs(date, 'MM/DD/YYYY'); // Assuming date format is MM/DD/YYYY
            if (!parsedDate.isValid()) return;

            if (minDate === null || parsedDate.isBefore(minDate)) {
                minDate = parsedDate;
            }
            if (maxDate === null || parsedDate.isAfter(maxDate)) {
                maxDate = parsedDate;
            }
        };

        // Process Activities (assuming they have begin_date and end_date)
        activities.forEach((activity) => {
            updateMinMaxDates(activity.begin_date);
            updateMinMaxDates(activity.end_date);
        });

        // Process Meals (assuming they have a date)
        meals.forEach((meal) => {
            updateMinMaxDates(meal.date);
        });

        // Process Lodgings (assuming they have begin_date and end_date)
        lodging.forEach((_lodging) => {
            updateMinMaxDates(_lodging.begin_date);
            updateMinMaxDates(_lodging.end_date);
        });

        // Process Flights (assuming they have depart_timestamp and arrive_timestamp)
        flights.forEach((flight) => {
            const departDate = dayjs(flight.depart_timestamp);  // Assuming timestamp format is valid ISO format
            const arriveDate = dayjs(flight.arrive_timestamp);
            updateMinMaxDates(departDate.format('MM/DD/YYYY'));  // Convert timestamp to date
            updateMinMaxDates(arriveDate.format('MM/DD/YYYY'));  // Convert timestamp to date
        });

        // If no valid dates found, return an empty TimeTable
        if (!minDate || !maxDate) {
            return new TimeTable();
        }

        // Generate the full range of dates from minDate to maxDate
        const newTimeTable = new TimeTable();
        let currentDate = minDate;
        while (currentDate.isSameOrBefore(maxDate)) {
            newTimeTable.dates.push({
                date: currentDate.format("MM/DD/YYYY"),
                dayOfWeek: currentDate.format("dddd"),
                'all-day': true, // Assuming these are all-day events, modify if needed
                notes: "",       // You can customize this based on the specific event
            });
            currentDate = currentDate.add(1, 'day'); // Move to the next day
        }

//        dataArray.Planning['time_table'] = newTimeTable;

        console.log("dataArray.Planning : "+JSON.stringify(dataArray.Planning, null, '\t'));

    }

    const addPlanningAttendee = (_new_attendee) => {

        console.log("adding new Attendee to Event : "+_new_attendee["fname"]);

        setDataArray((prevDataArray) => {
            const existingAttendeeIndex = prevDataArray.Planning.attendees.findIndex(attendee => attendee.id === _new_attendee.id);

            // If attendee exists, update their status to 0; active
            if (existingAttendeeIndex !== -1) {
                return {
                    ...prevDataArray,
                    Planning: {
                        ...prevDataArray.Planning,
                        attendees: prevDataArray.Planning.attendees.map((attendee, index) =>
                            index === existingAttendeeIndex ? { ...attendee, status: 0 } : attendee
                        ),
                    },
                };
            }

            // If attendee doesn't exist, add them to the attendees array
            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    attendees: [...prevDataArray.Planning.attendees, _new_attendee],
                },
            };
        });
    };

    const removePlanningAttendee = (_attendee_id) => {
        console.log("removing Attendee from Event");

        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                attendees: prevDataArray.Planning.attendees.map(attendee =>
                    attendee.id === _attendee_id ? { ...attendee, status: 4 } : attendee
                ),
            }
        }));
    };

    const setPlanningLodging = (_event_id, _event_lodging_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                lodging: _event_lodging_array,
                need_to_save: true
            }
        }));

        updateEventLodging(_event_id, _event_lodging_array);

    };
    const setPlanningActivities = (_event_id, _event_activities_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                activities: _event_activities_array,
                need_to_save: true
            }
        }));

        updateEventActivities(_event_id, _event_activities_array);

    };
    const setPlanningMeals = (_event_id, _event_meals_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                meals: _event_meals_array,
                need_to_save: true
            }
        }));

        updateEventMeals(_event_id, _event_meals_array);

    };
    const setPlanningFlights = (_event_id, _event_flights_array) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {
                ...prevDataArray.Planning,
                flights: _event_flights_array,
                need_to_save: true
            }
        }));

        updateEventFlights(_event_id, _event_flights_array);

    };

    const addPlanningActivity = (event_id, newEventActivity) => {

        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id

            if (prevDataArray.Planning.id !== event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Proceed with adding the new activity
            const updatedActivities = [...prevDataArray.Planning.activities, newEventActivity];

            //update the Event Activities
            updateEventActivities(event_id, updatedActivities);

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    activities: updatedActivities,
                    need_to_save: true
                }
            };
        });
    };
    const updatePlanningActivity = (event_id, updatedEventActivity) => {

        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id

            if (prevDataArray.Planning.id !== event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Proceed with updating the activities
            const updatedActivities = prevDataArray.Planning.activities.map(activity =>
                activity.id === updatedEventActivity.id ? updatedEventActivity : activity
            );

            //update the Event Activities
            updateEventActivities(event_id, updatedActivities);

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    activities: updatedActivities,
                    need_to_save: true
                }
            };
        });
    };
    const deletePlanningActivity = (event_id, activity_id) => {
        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id
            if (prevDataArray.Planning.id !== event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Proceed with deleting the activity
            const updatedActivities = prevDataArray.Planning.activities.filter(activity => activity.id !== activity_id);

            //update the Event Activities
            updateEventActivities(event_id, updatedActivities);

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    activities: updatedActivities,
                    need_to_save: true
                }
            };
        });
    };


    const addPlanningMessages = (_incoming) => {
        console.log("addPlanningMessages");

        setDataArray((prevDataArray) => {
            // Check if the event_id matches Planning.id

            if (prevDataArray.Planning.id !== _incoming.event_id) {
                return prevDataArray; // Return previous state without changes
            }

            // Combine current messages with new, checking for duplicates
            const currentMessageIds = new Set(prevDataArray.Planning.messages.map(msg => msg.id));
            const newMessages = _incoming.messages_list.filter(msg => !currentMessageIds.has(msg.id));

            // Proceed with adding the new messages if they are not already present
            const updatedMessages = [...prevDataArray.Planning.messages, ...newMessages];

            // Calculate the latest timestamp
            const latestTimestamp = updatedMessages.reduce((latest, message) => {
                return latest > message.created ? latest : message.created;
            }, 0); // Start with 0 or an initial very old timestamp

            return {
                ...prevDataArray,
                Planning: {
                    ...prevDataArray.Planning,
                    messages: updatedMessages,
                    latest_message_timestamp: latestTimestamp,
                    need_to_save: true
                }
            };
        });
    };

    const clearPlanning = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Planning: {},
        }));
    };

    const addSuggestionsLodging = (newSuggestionsLodging) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsLodging: [...prevDataArray.SuggestionsLodging, newSuggestionsLodging],
        }));
    };
    const clearSuggestionsLodging = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsLodging: [],
        }));
    };

    const addSuggestionsFlights = (newSuggestionsFlights) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsFlights: [...prevDataArray.SuggestionsFlights, newSuggestionsFlights],
        }));
    };
    const clearSuggestionsFlights = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsFlights: [],
        }));
    };

    const addSuggestionsMeals = (newSuggestionsMeals) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsMeals: [...prevDataArray.SuggestionsMeals, newSuggestionsMeals],
        }));
    };
    const clearSuggestionsMeals= () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsMeals: [],
        }));
    };

    const addSuggestionsTours = (newSuggestionsUnique) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsTours: [...prevDataArray.SuggestionsTours, newSuggestionsUnique],
        }));
    };
    const clearSuggestionsTours = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsTours: [],
        }));
    };

    const addSuggestionsUnique = (newSuggestionsUnique) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsUnique: [...prevDataArray.SuggestionsUnique, newSuggestionsUnique],
        }));
    };
    const clearSuggestionsUnique = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            SuggestionsUnique: [],
        }));
    };

    const addPlanningLibrary = (newPlanningLibrary) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            PlanningLibrary: [...prevDataArray.PlanningLibrary, newPlanningLibrary],
        }));
    };
    const clearPlanningLibrary = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            PlanningLibrary: [],
        }));
    };
    const addPartnership = (newPartnership) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Partnerships: [...prevDataArray.Partnerships, newPartnership],
        }));
    };
    const clearPartnerships = () => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            Partnerships: [],
        }));
    };
    const setWorkingOnEventId = (value) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            workingOnEventId: value,
        }));
    };



    const addIncomingAdminsActions = (newIncomingAdminsActions) => {
        setDataArray((prevDataArray) => ({
            ...prevDataArray,
            AdminsActions: [...prevDataArray.AdminsActions, newIncomingAdminsActions],
        }));
    };


    const websocketRef = useRef(null);
    let [tryingToConnect, setTryingToConnect] = useState(false);

    useEffect(() => {
        const connectWebSocket = () => {
            if (!tryingToConnect && !dataArray.ws_connected) {
                console.log('Trying to make WebSocket connection...');
                setTryingToConnect(true);

                websocketRef.current = new WebSocket('wss://plannedadventure.com:8001/paws');
                setDataArray((prevData) => ({ ...prevData, Websocket: websocketRef.current }));

                websocketRef.current.onopen = () => {
                    console.log('WebSocket connection established.');
                    setWsConnected(true);
                    setTryingToConnect(false);

                    console.log('userAccount : '+JSON.stringify(userAccount));

                    if (!userAuthenticated && userAccount.current !== null){
                        const _send_string = {};
                        _send_string.action = "E_processUserLoginReconnect";
                        _send_string.username = userAccount.current.username;
                        _send_string.password = userAccount.current.password;
                        _send_string.last_checkin = userAccount.current.last_checkin;

                        console.log('trying to reauthorize : '+JSON.stringify(_send_string));

                        websocketRef.current.send(JSON.stringify(_send_string) + "|^");
                    }
                };

                websocketRef.current.onmessage = (event) => {
                    console.log('WebSocket message received:', event.data);
                    processIncoming(event.data);
                };

                websocketRef.current.onclose = (event) => {
                    console.log('WebSocket connection closed:', event.code, event.reason);
                    setWsConnected(false);
                    setUserAuthenticated(false);
                    reconnectWebSocket();
                };

                websocketRef.current.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
            }
        };

        const reconnectWebSocket = () => {
            console.log('Reconnecting in 5 seconds...');
            setTimeout(() => {
                if (!dataArray.ws_connected) {
                    connectWebSocket();
                }
            }, 5000);
        };

        connectWebSocket();

        function processIncoming(_blob) {
            console.log('processIncoming : ' + _blob);

            let reader = new FileReader();

            reader.onload = function (event) {

                let incomingData = event.target.result;

                if (leftover_incoming_stuff.length === 0) {
                    mdnIncomingTotalMessageSize = eval(incomingData.substring(0, incomingData.indexOf("{")));
                }

                leftover_incoming_stuff += incomingData;
                let terminationIndex = leftover_incoming_stuff.indexOf("|^");

                if (terminationIndex === -1) {
                    mdnIncomingReceivedMessageSize = leftover_incoming_stuff.length;

                    //update the progress bar
                    if ((mdnIncomingReceivedMessageSize === 0) || (mdnIncomingReceivedMessageSize === mdnIncomingTotalMessageSize)){
                        //nothing to show
                        setProgressBar(0);
                    } else {
                        setProgressBar( (mdnIncomingReceivedMessageSize / mdnIncomingTotalMessageSize) * 100 );
                    }

                } else {
                    //this part contains the last piece of the JSONObject for processing
                }


                while (terminationIndex > -1) {
                    let processString = leftover_incoming_stuff.slice(leftover_incoming_stuff.indexOf('{'), terminationIndex);

                    //just add the string, it gets turned into JSON later
//                    _incoming_stack.push(processString);
                    processIncomingAction(processString);

                    mdnIncomingTotalMessageSize = 0;
                    mdnIncomingReceivedMessageSize = 0;

                    //update the progress bar
                    setProgressBar(0);

                    //remove the part we just sent for processing
                    leftover_incoming_stuff = leftover_incoming_stuff.slice(terminationIndex + 2);
                    terminationIndex = leftover_incoming_stuff.indexOf("|^");

                    //let's see about the size of the next content
                    if (leftover_incoming_stuff.length > 0 && leftover_incoming_stuff.indexOf("{") !== -1) {
                        try {
                            incoming_stuff_length = eval(leftover_incoming_stuff.substring(0, leftover_incoming_stuff.indexOf("{")));
                            mdnIncomingTotalMessageSize = eval(leftover_incoming_stuff.substring(0, leftover_incoming_stuff.indexOf("{")));
                            if (terminationIndex === -1) {
                                mdnIncomingReceivedMessageSize = leftover_incoming_stuff.length;
                            }
                        } catch (e) {
                        }
                    }

                }
            };

            reader.readAsText(_blob);
        }

        function processIncomingAction(processString) {

            try {
                const _incoming = JSON.parse(processString);
                console.log("the Incoming action : " + JSON.stringify(_incoming.action));

                const action = _incoming.action;

                if (action === "timelyUpdate") {

//                    setDataArray((prevData) => ({...prevData, timelyUpdate: 0}));

                } else if (action === "E_processUserLoginResults" || action === "E_processUserLoginUsingGoogleJWTResults") {

                    processUserLogin(_incoming);

                } else if (action === "E_processUserLogoutResults") {

                    processUserLogout(_incoming);

                } else if (action === "E_getAllEventsResults") {
                    console.log("Number of events: " + _incoming.events.length);

                    for (const _event of _incoming.events) {
                        addEvent(_event);
                    }

                    //update the browser database
                    browserStorageUpdateAllEvents(_incoming.events);

                } else if (action === "E_getEventChangesResults") {

                    E_getEventChangesResults(_incoming, dataArray['Planning']);

                } else if (action === "E_getAllCategoriesResults") {
                    console.log("Number of categories: " + _incoming.categories.length);

                    for (const _category of _incoming.categories) {
                        addCategory(_category);
                    }
                } else if (action === "E_getMasterAttendeeListResults") {
                    console.log("Number of attendees: " + _incoming.attendees.length);

                    //clear any existing data
                    clearAttendees();

                    for (const _attendee of _incoming.attendees) {

                        const _newAttendee = new PAAttendee(
                            _attendee.fname,
                            _attendee.lname,
                            _attendee.email
                        );

                        _newAttendee.id = _attendee.id;
                        _newAttendee.image = _attendee.image;

                        _newAttendee.nickname = _attendee.nickname;

                        _newAttendee.address = _attendee.address;
                        _newAttendee.city = _attendee.city;
                        _newAttendee.state = _attendee.state;
                        _newAttendee.zip = _attendee.zip;
                        _newAttendee.country = _attendee.country;

                        _newAttendee.language = _attendee.language;

                        _newAttendee.phone_prefix = _attendee.phone_prefix;
                        _newAttendee.phone = _attendee.phone;
                        _newAttendee.mask_phone_number = _attendee.mask_phone_number;
                        _newAttendee.push_notifications = _attendee.push_notifications;

                        _newAttendee.events = _attendee.events;

                        _newAttendee.status = _attendee.status;

                        addAttendee(_newAttendee);
                    }



                } else if (action === "signupConciergeGetWelcomeMessageResults") {
                    addIncomingConciergeSignupActions(_incoming);
                } else if (action === "signupConciergeVerifyEmailAddressResults") {
                    addIncomingConciergeSignupActions(_incoming);
                } else if (action === "signupConciergeVerifyVerificationCodeResults") {
                    addIncomingConciergeSignupActions(_incoming);
                } else if (action === "signupConciergeCheckUsernameAndPasswordResults") {
                    addIncomingConciergeSignupActions(_incoming);



                } else if (action.startsWith("getAllAttendeeAssistantThreadsResults")) {

                    addIncomingConciergeActions(_incoming);

                } else if (action.startsWith("getAttendeeAssistantThreadMessagesResults")) {

                    addIncomingConciergeActions(_incoming);



                } else if (action.startsWith("planningConciergeService")) {

                    addIncomingConciergeActions(_incoming);

                } else if (action.startsWith("planningConciergeChatService")) {

                    addIncomingConciergeActions(_incoming);

                } else if (action.startsWith("planningConciergeMapService")) {

                    addIncomingConciergeMapActions(_incoming);

                } else if (action.startsWith("planningConciergeScanningService")) {

                    addIncomingConciergeScanningDocumentActions(_incoming);

                } else if (action.startsWith("planningConciergeSuggestionService")) {

                    addIncomingConciergeSuggestionActions(_incoming);




                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantThreadUserLoginResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantThreadUserLogoutResults")) {

                    addIncomingAdminsActions(_incoming);


                } else if (action.startsWith("adminServicesGetOpenAiAssistantFunctionListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiAssistantListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantPropertiesResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesDeleteOpenAiAssistantResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesCreateOpenAiAssistantThreadResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesCreateOpenAiAssistantLodgingNegotiatorThreadResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiAssistantThreadListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiAssistantThreadMessagesResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesSendOpenAiAssistantThreadMessageResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesSendOpenAiAssistantThreadMessageStatus")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesUpdateOpenAiAssistantThreadMetadataResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesDeleteOpenAiAssistantThreadResults")) {

                    addIncomingAdminsActions(_incoming);





                } else if (action.startsWith("adminServicesGetOpenAiFilesListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiFileContentsResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesGetOpenAiFileAssistantListResults")) {

                    addIncomingAdminsActions(_incoming);

                } else if (action.startsWith("adminServicesDeleteOpenAiFileResults")) {

                    addIncomingAdminsActions(_incoming);



                } else if (action.startsWith("adminServicesGetMasterAttendeeListResults")) {

                    addIncomingAdminsActions(_incoming);


                /*
                    for the Event : saving the entire Event from the PLanner
                */
                } else if (action === "E_setEventAllChangesResults") {

                    E_setEventAllChangesResults(_incoming, dataArray['Planning']);



               /*
                   for the Event Details
                */
                } else if (action === "E_updateEventCoverImageResults") {

//                    E_updateEventCoverImageResults(_incoming);

                } else if (action === "E_updateEventCoverImageThumbnailResults") {

//                    E_updateEventCoverImageThumbnailResults(_incoming);



                    /*
                        for the Event Attendees
                     */

                } else if (action === "E_updateAttendeePrivilegeResults") {

//                    E_updateAttendeePrivilegeResults(_incoming);




                    /*
                        for the Event Activities
                     */
                } else if (action === "E_addNewActivityResults") {

                    E_addEventActivityResults(_incoming).then(newActivity=> {
                        if (newActivity && _incoming.result === "ok"){
                            addPlanningActivity(_incoming.event_id, newActivity);
                        }
                    });

                } else if (action === "E_updateEventActivityResults") {

                    E_updateEventActivityResults(_incoming).then(updatedActivity=> {
                        if (updatedActivity && _incoming.result === "ok"){
                            updatePlanningActivity(_incoming.event_id, updatedActivity);
                        }
                    });

                } else if (action === "E_deleteEventActivityResults") {

                    E_deleteEventActivityResults(_incoming).then(deletedActivity=> {
                        if (deletedActivity && _incoming.result === "ok"){
                            deletePlanningActivity(_incoming.event_id, _incoming.activity_id);
                        }
                    });




                } else if (action === "E_uploadEventImageResults") {

                    E_uploadEventImageResults(_incoming);

                } else if (action === "E_uploadEventAttendeePhotoThumbnailResults") {

                    E_uploadEventAttendeePhotoThumbnailResults(_incoming);

                } else if (action === "E_uploadEventAttendeePhotosResults") {

                    E_uploadEventAttendeePhotosResults(_incoming, dataArray['Planning']);

                } else if (action === "E_getEventThumbnailImageResults") {

                    E_getEventThumbnailImageResults(_incoming);

                } else if (action === "E_getEventFullSizeImageResults") {

                    E_getEventFullSizeImageResults(_incoming);

                } else if (action === "E_updateEventAttendeePhotoPrivateResults") {

                    E_updateEventAttendeePhotoPrivateResults(_incoming);

                } else if (action === "E_deleteEventPhotosResults") {

                    E_deleteEventPhotosResults(_incoming);


                    /*
                        for the Event Messages
                    */
                } else if (action === "E_getEventMessagesResults") {
                    /*
                        this contains an array of messages
                        - add them to the browser database
                     */

                    E_getEventMessagesResults(_incoming).then(success=> {
                        if (success && _incoming.result === "ok"){
                            //add messages to the Event list and the Planning if the Event IDs match
                            addPlanningMessages(_incoming);
                            updateEventMessages(_incoming);
                            addIncomingEventActions(_incoming);
                        }
                    });






                }

            } catch (e) {
                console.log("error : " + e);
            }

        }

        function processUserLogin(_incoming) {
            console.log("processUserLogin : " + JSON.stringify(_incoming, null, '\t'));

            try {

                if (_incoming.result === "ok") {

                    setAuthenticated(true);

                    //create the Account object
                    const _account = {};
                    _account.id = _incoming.user_id;
                    _account.created = _incoming.created;
                    _account.last_checkin = _incoming.last_checkin;
                    _account.role = _incoming.role;
                    _account.username = _incoming.username;
                    _account.password = _incoming.password;
                    _account.last_password_change = _incoming.last_password_change;
                    _account.fname = _incoming.fname;
                    _account.lname = _incoming.lname;
                    _account.nickname = _incoming.nickname;
                    _account.image = _incoming.image;
                    _account.language = _incoming.language;
                    _account.address = _incoming.address;
                    _account.city = _incoming.city;
                    _account.state = _incoming.state;
                    _account.zip = _incoming.zip;
                    _account.country = _incoming.country;
                    _account.phone_prefix = _incoming.phone_prefix;
                    _account.phone = _incoming.phone;
                    _account.mask_phone_number = _incoming.mask_phone_number;
                    _account.email = _incoming.email;
                    _account.auto_login = _incoming.auto_login;
                    _account.notifications = _incoming.notifications;
                    _account.push_notifications = _incoming.push_notifications;
                    _account.menu_items = _incoming.menu_items;
                    _account.events = _incoming.events;
                    _account.documents = _incoming.documents;
                    _account.essentials = _incoming.essentials;
                    _account.pay_plan = _incoming.pay_plan;
                    _account.dark_mode = _incoming.dark_mode;
                    _account.show_welcome_tips = _incoming.show_welcome_tips;
                    _account.status = _incoming.status;

                    setAccount(_account);
                    userAccount.current = _account;
                    setUserAuthenticated(true);

                    //add the message for the Assistant Thread
                    const _assistant_message = {};
                    _assistant_message.action = "userLoggedIn";
                    _assistant_message.user_id = _incoming.user_id;
                    addIncomingAdminsActions(_assistant_message);
                    addIncomingConciergeActions(_assistant_message);

                    /*
                        before adding the _account to browser storage, see if there is an existing entry
                        this is so if you switch accounts it won't load Events from an Account belonging to another
                        person
                     */
                    //checkUserAccount(_account);
                    openDb('pa', 1, function (upgradeDb) {
                        if (!upgradeDb.objectStoreNames.contains('pa_data')) {
                            upgradeDb.createObjectStore('pa_data');
                            upgradeDb.createObjectStore('pa_photos');
                        }
                    }).then(function (db) {
                        const tx = db.transaction('pa_data', 'readonly');
                        const store = tx.objectStore('pa_data');
                        return store.get('Account')
                    }).then(function (_user_account) {
                        if (_user_account && _user_account.id === _account.id) {
                            console.log('Account found and id does match.');

                            //see if the Events are already in the browser database
                            getAllEventsFromDatabase(_incoming.user_id).then(eventsInDatabase => {

                                if (eventsInDatabase && eventsInDatabase.length > 0) {

                                    //add Events to the
                                    for (const _event of eventsInDatabase) {
                                        addEvent(_event);
                                    }

                                    // Check if we have the latest versions of all Events
                                    const extractedEvents = eventsInDatabase.map(event => ({ id: event.id, last_mod: event.last_mod }));

                                    const _sendString = {};
                                    _sendString.action = "E_getEventChanges";
                                    _sendString.user_id = _account.id;
                                    _sendString.events = extractedEvents;

                                    websocketRef.current.send(JSON.stringify(_sendString) + "|^");

                                } else {
                                    // Handle the case when no events are found in the database
                                    // Get all events from the server
                                    console.log("No Events in browser storage!! Get from server");
                                    const _sendString = {};
                                    _sendString.action = "E_getAllEvents";
                                    _sendString.user_id = _account.id;

                                    websocketRef.current.send(JSON.stringify(_sendString) + "|^");

                                }
                            }).catch(error => {
                                // Handle errors occurred during retrieving events from the database
                                console.error("Error occurred while retrieving events from the database:", error);
                            });

                        } else {
                            console.log('Account not found or id does not match.');
                            removeEventsFromDatabase().then(r => {
                                //add the Account to storage
                                addKeyValue("Account", _account);
                            });

                            //get the list of Events from server
                            const _sendString = {};
                            _sendString.action = "E_getAllEvents";
                            _sendString.user_id = _account.id;

                            websocketRef.current.send(JSON.stringify(_sendString) + "|^");

                        }
                        return null;
                    }).catch(function (error) {
                        console.error("An error occurred:", error);
                        throw error; // Re-throw the error to be caught by the caller
                    });

                    /*
                        get any OpenAi Threads associated with this Attendee
                     */
                    const _sendString = {};
                    _sendString.action = "getAllAttendeeAssistantThreads";
                    websocketRef.current.send(JSON.stringify(_sendString) + "|^");


                } else {
                    //bad login
                    console.log("login failed .....");

                    loginFailed();

                }

            } catch (e) {
                console.log(e);
            }

        }

        function loginFailed() {
            console.log("loginFailed");
            //this alerts the Header and shows the message on the Dialog box
            setLoginFailed(true);
        }

        function processUserLogout(_incoming) {
            console.log("processUserLogout : " + JSON.stringify(_incoming, null, '\t'));

            try {

                if (_incoming.results === "ok") {

                    //add the message for the Assistant Thread
                    const _assistant_message = {};
                    _assistant_message.action = "userLoggedOut";
                    _assistant_message.user_id = _incoming.user_id;
                    addIncomingAdminsActions(_assistant_message);


                    //good logout
                    setAuthenticated(false);
                    clearAccount();
                    clearEvents();
                    clearAttendees();

                    setSideMenuWidth(0);

                } else {
                    //problems logging out
                }
            } catch (e) {
            }
        }


        // Cleanup on component unmount or before reconnecting
        return () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };
    }, []); // Ensure dependencies are correctly managed


    return (

        <PAContext.Provider value={{ dataArray, setShowSuccessSnackbar, setSideMenuWidth, clearEvents,
            clearAccount, addAttendee, clearAttendees, addIncomingActions, setAuthenticated,
            setLoginFailed,
            setPlanning, addPlanningAttendee, removePlanningAttendee, setPlanningLodging, setPlanningActivities,
            setPlanningMeals, setPlanningFlights,
            addSuggestionsLodging, clearSuggestionsLodging,
            addSuggestionsFlights, clearSuggestionsFlights,
            addSuggestionsMeals, clearSuggestionsMeals,
            addSuggestionsTours, clearSuggestionsTours,
            addSuggestionsUnique, clearSuggestionsUnique,
            addPlanningLibrary, clearPlanning, addPartnership, clearPartnerships,
            setWorkingOnEventId }} >
            {children}
        </PAContext.Provider>

    );
};

export { PAContext, PAContextProvider };
