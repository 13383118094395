import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, FormControlLabel,
    Grid,
    ListItem,
    ListItemAvatar, Menu, MenuItem, MenuList, Radio, RadioGroup, Tab,
    TextField
} from "@mui/material";
import Badge from "@mui/material/Badge";
import {addChipStyle, addGradientStyle} from "../../ButtonStyle/Add";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {editChipStyle} from "../../ButtonStyle/Edit";
import {Autocomplete} from "@mui/lab";



function MealCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { dataArray, addPlanningAttendee } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [eventMeal, setEventMeal] = useState(props.meal);
    const [selectedAction, setSelectedAction] = useState("");
    const [mealTabSelected, setMealTabSelected] = useState("");

    const [showCalendarSelectMealDate, setShowCalendarSelectMealDate] = useState(false);
    const [showSelectMealDay, setShowSelectMealDay] = useState(false);
    const [selectedMealDay, setSelectedMealDay] = useState('');
    const [showClockSelectMealTime, setShowClockSelectMealTime] = useState(false);

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);


    useEffect(()=> {
        console.log("props.meal has been updated --- : ");
//        setEventMeal(props.meal);
    }, [props.meal]);

    useEffect(() => {
        console.log("eventMeal has been updated --- : ");
        console.log(JSON.stringify(eventMeal, null, '\t'));
        console.log("--------------------------------");

        props.updateMealDetails(eventMeal);

    }, [eventMeal]);

    useEffect(()=> {

        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move activity attendees to the top of the list
            for (let i = eventMeal.attendees.length - 1; i >= 0; i--) {
                const activityAttendee = eventMeal.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === activityAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);

    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.meal[amount_total] : "+props.meal["amount_total"]+" and "+eventMeal["amount_total"]);
        if (props.meal["amount_total"] !== eventMeal["amount_total"] || props.meal["currency"] !== eventMeal["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventMeal["attendees"].some((_attendee, index) => {
                const initialAttendee = props.meal["attendees"].find(a => a.id === _attendee.id);

//                console.log("comparing attendee costs : "+_attendee.cost);
//                console.log("comparing initialAttendee costs : "+initialAttendee["cost"]);

                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
            //turn on the button

            console.log("an attendee cost has changed");

            setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);

    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: `0 0 5px ${stringToColor(name)}`,
                border: `1px solid ${stringToColor(name)}`,
                borderRadius: '10px',
                textAlign: 'center',
                fontWeight: '700',
                backgroundColor: '#FFF',
                color: 'black', // Text color (adjust as needed)
                fontSize: '15px'            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function handlePromptShowMealDateCalendar(){
        //toggle the button

        //post the update
        if (showCalendarSelectMealDate){
            props.updateMealDetails(eventMeal);
        }

        setShowCalendarSelectMealDate(!showCalendarSelectMealDate);
    }
    function handleMealDateChange(meal_date) {

        const newMealDate = dayjs(meal_date).format('MM/DD/YYYY');

        // Update the eventMeal state and ensure only one date is in the time_table.dates array
        setEventMeal(prevState => ({
            ...prevState,
            meal_date: newMealDate,
            time_table: {
                ...prevState.time_table,
                dates: [{
                    date: dayjs(meal_date).format('YYYY-MM-DD'), // Store the new date in YYYY-MM-DD format
                    dayOfWeek: dayjs(meal_date).format('dddd'), // Store the day of the week
                    meal_time: prevState.time_table?.dates?.[0]?.meal_time || '', // Keep the existing meal_time if it exists
                    notes : ''
                }]
            }
        }));
    }
    const handlePromptShowMealDaySelect = () => {
        // Get the current selected day from eventMeal.time_table.days
        const currentSelectedDay = eventMeal.time_table?.days?.length > 0
            ? Object.keys(eventMeal.time_table.days[0])[0] // e.g., 'day 1', 'day 2'
            : '';

        // Set the selectedMealDay state to the current day (if it exists)
        setSelectedMealDay(currentSelectedDay);

        //toggle the button
        setShowSelectMealDay(!showSelectMealDay);
    }
    const handleMealDayChange = (e) => {
        console.log("selectedDay : " + e.target.value);
        const selected = e.target.value;
        setSelectedMealDay(selected);

        // Initialize variables for selectedDate and dayOfWeek
        let selectedDate = null;
        let dayOfWeek = '';

        // Find the corresponding date from the Planning time_table if dates exist
        if (Planning['time_table']?.dates?.length > 0) {
            const dayIndex = parseInt(selected.split(' ')[1]) - 1; // Extract the number from "day 1", "day 2", etc.
            selectedDate = Planning['time_table'].dates[dayIndex];
            console.log("selectedDate : " + selectedDate);
        }

        // If dates array is empty or selectedDate is not found, fallback to days
        if (!selectedDate && Planning['time_table']?.days?.length > 0) {
            const dayIndex = parseInt(selected.split(' ')[1]) - 1; // Extract the number from "day 1", "day 2", etc.
            const selectedDayEntry = Planning['time_table'].days[dayIndex];
            dayOfWeek = selectedDayEntry[selected]; // Use the day label (e.g., "Thursday", "Friday")
            console.log("selectedDayEntry : " + dayOfWeek);
        } else if (selectedDate) {
            // If selectedDate is found, format the day of the week using dayjs
            dayOfWeek = dayjs(selectedDate.date, 'YYYY-MM-DD').format('dddd');
        }

        // If either selectedDate or dayOfWeek was found, update meal's time_table.days
        if (dayOfWeek) {
            const updatedDays = [
                {
                    [selected]: dayOfWeek,
                    'all-day': false,
                    'meal_time' : '',
                    'notes': ''
                }
            ];

            // Update the meal object (assuming updateMealTimeTable is a function passed in as a prop)
            setEventMeal({
                ...eventMeal,
                time_table: {
                    ...eventMeal.time_table,
                    days: updatedDays,
                },
            });
        }
    };


    function handlePromptShowMealTime(){
        //toggle the button

        //post the update
        if (showClockSelectMealTime){
            props.updateMealDetails(eventMeal);
        }

        //change button back to 'Edit'
        setShowClockSelectMealTime(!showClockSelectMealTime);
    }
    function handleMealTimeChange(meal_time){
        console.log("meal_time : "+dayjs(meal_time).format('h:mm A'));
        setEventMeal(prevState => ({
            ...prevState,
            meal_time: dayjs(meal_time).format('h:mm A')
        }));

    }

    const handleAttendeesToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = eventMeal["attendees"].findIndex(item => item.id === _attendee_id);

        let updatedList = [...eventMeal["attendees"]];

        if (index !== -1) {
            // Remove attendee from activity list
            updatedList.splice(index, 1);
        } else {

            //need to see if this Attendee has already been added to the Event
            //add if necessary
            const _att_index = Planning["attendees"].findIndex(att => att.id === _attendee_id);
            if (_att_index === -1){
                //need to add Attendee to the Event first
                addPlanningAttendee(Attendees.find((_att) => _att.id === _attendee_id));
            }

            // Add attendee to activity list
            const _att_with_cost = { id: _attendee_id, cost: 0 };
            updatedList = [...eventMeal["attendees"], _att_with_cost];
        }

        if (eventMeal["assign_equal"]) {

            const mealCost = parseFloat(eventMeal["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((mealCost / numberOfAttendees) * 100) / 100).toFixed(2);
            const remainder = Math.round((mealCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

        }

        setEventMeal(prevState => ({
            ...prevState,
            attendees: updatedList
        }));

    };

    useEffect(() => {
        console.log('Attendees have been updated:', eventMeal.attendees);
        props.updateMealDetails(eventMeal);

    }, [eventMeal.attendees]);


    function handleCostOverride(event) {
        const valueToAdd = parseFloat(event.target.value);

        setEventMeal(prevState => ({
            ...prevState,
            amount_total: valueToAdd
        }));

    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventMeal["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventMeal["attendees"]];
                let activityCost = parseFloat(eventMeal["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("participants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

                props.updateMealDetails(eventMeal);

            }
        }

        setShowPriceOverride(!showPriceOverride);
    }

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventMeal(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        //recalculate to see if that changes the Attendees total breakdown
        setCheckShowPriceChanges(true);

        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventMeal["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventMeal(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventMeal["attendees"]];

            const activityCost = parseFloat(eventMeal["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventMeal(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }

    function handleCustomAttendeeCost(_attendee_id, e){
        console.log("handleCustomAttendeeCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventMeal(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);
    }

    function handleSaveMealCostAssignment(event){

        props.updateMealDetails(eventMeal);

        setShowPriceChangesUpdateButton(false);

    }


    function handleBookedThroughChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            booked_through: _newValue
        }));
    }
    function handleConfirmationNumberChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            confirmation_number: _newValue
        }));
    }
    function handleReservationNameChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            reservation_name: _newValue
        }));
    }
    function handleReservationNumberChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            reservation_number: _newValue
        }));
    }
    function handleNotesChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            notes: _newValue
        }));
    }

    const handleFooterItemClick = (_item) => {
        setSelectedAction(_item);
        mealTabSelected === _item ? setMealTabSelected("") : setMealTabSelected(_item);
    };

    const [openDialogDateRange, setOpenDialogDateRange] = useState(false);
    function handleDialogDateRangeClose() {
        setOpenDialogDateRange(false);
    }
    function handleDialogDateRangeOK(){
        setOpenDialogDateRange(false);
    }


    const [anchorMealListItemMenu, setAnchorMealListItemMenu] = useState(null);
    const handleMealListItemMenuOpenMenu = (event) => {
        setAnchorMealListItemMenu(event.currentTarget);
    };
    const handleMealListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorMealListItemMenu(event.currentTarget);

    };
    const handleMealListItemMenuClose = () => {
        setAnchorMealListItemMenu(null);
    };


    const [datesCount, setDatesCount] = useState(0);
    const [updatedBeginDate, setUpdatedBeginDate] = useState(null);
    const [updatedEndDate, setUpdatedEndDate] = useState(null);
    const [beginTimeSelected, setBeginTimeSelected] = useState(false);
    const [endTimeSelected, setEndTimeSelected] = useState(false);
    const [beginTimeValue, setBeginTimeValue] = useState(null);
    const [endTimeValue, setEndTimeValue] = useState(null);
    const [updatedBeginTime, setUpdatedBeginTime] = useState(null);
    const [updatedEndTime, setUpdatedEndTime] = useState(null);

    const [tabValue, setTabValue] = useState("date");
    const handleTabValueChange = (event, newValue) => {
        console.log("handleTabValueChange");
        setTabValue(newValue);
    };

    const [ eventDaysCount, setEventDaysCount ] = useState(0);
    const [ eventDaysStartingOnDay, setEventDaysStartingOnDay ] = useState('');
    function handleEventDaysCountChange(e){
        setEventDaysCount(e.target.value);
    }
    function handleEventDaysStartingOnChange(event){
        setEventDaysStartingOnDay(event.target.value);
    }

    return (


        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >
                <CardHeader
                    title={(() => {
                        const { time_table } = eventMeal;

                        if (time_table?.dates?.length > 0) {
                            const mealTime = time_table.dates[0].meal_time;
                            // If meal_time is not empty, format it, otherwise show only the date
                            return mealTime
                                ? `${dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('dddd')} ${dayjs(mealTime, 'HH:mm A').format('h:mm A')}`
                                : `${dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('dddd')}`;
                        } else if (time_table?.days?.length > 0) {
                            const mealTime = time_table.days[0].meal_time;
                            // Get the day of the week from the value (e.g., 'Thursday' instead of 'day 2')
                            const dayOfWeek = time_table.days[0][Object.keys(time_table.days[0])[0]]; // Extract the day of the week
                            // If meal_time is not empty, format it, otherwise show only the day of the week
                            return mealTime
                                ? `${dayOfWeek} ${dayjs(mealTime, 'HH:mm A').format('h:mm A')}`
                                : dayOfWeek;
                        }

                        return ''; // Return an empty string if no dates or days are available
                    })()}

                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleMealListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{paddingBottom:'0'}}
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorMealListItemMenu}
                    id="meal-item-menu"
                    open={Boolean(anchorMealListItemMenu)}
                    onClose={handleMealListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="delete"
                            onClick={() => handleFooterItemClick('Delete')}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '0 16px 16px 16px' }}>
                    <Typography variant="body2" color="textSecondary">
                        {eventMeal['name'].toUpperCase()}
                    </Typography>
                </Box>

                {/* add any photos if they exist */}
                {
                    eventMeal['photos'] && eventMeal['photos'].length >= 0 ? (
                        <div className="meals-suggestions-add-list-item-photos">
                            {
                                eventMeal['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }


                <CardContent>
                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                        {
                            (() => {
                                const { time_table } = eventMeal;

                                if (time_table?.dates?.length > 0) {
                                    return `${dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('D MMM YYYY')}`;
                                } else if (time_table?.days?.length > 0) {
                                    const mealTime = time_table.days[0].meal_time;
                                    // Get the day of the week from the value (e.g., 'Thursday' instead of 'day 2')
                                    const dayOfWeek = time_table.days[0][Object.keys(time_table.days[0])[0]]; // Extract the day of the week
                                    // If meal_time is not empty, format it, otherwise show only the day of the week
                                    return mealTime
                                        ? `${dayOfWeek} ${dayjs(mealTime, 'HH:mm A').format('h:mm A')}`
                                        : dayOfWeek;
                                }
                                return ''; // Return an empty string if no dates or days are available
                            })()
                        }
                    </Typography>
                    {
                        eventMeal['location'].name ? (
                            <>
                                <Typography variant="h6" sx={{ fontSize:'14px' }} color="text.secondary">
                                    {eventMeal['location'].name}
                                </Typography>
                                <Typography sx={{ fontSize:'12px' }} color="text.secondary">
                                    {eventMeal['location'].address}
                                </Typography>
                                <Typography sx={{ fontSize:'12px' }} color="text.secondary">
                                    {eventMeal['location'].city} {eventMeal['location'].state} {eventMeal['location'].zip}
                                </Typography>
                                <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                    {eventMeal['phone']}
                                </Typography>
                            </>

                        ):(
                            <></>
                        )
                    }
                    {
                        eventMeal["eatery_notes"] ? (
                            <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                Notes : {eventMeal['eatery_notes']}
                            </Typography>
                        ) : (
                            <></>
                        )
                    }
                    {
                        eventMeal["eat_in_details"] ? (
                            <Typography
                                sx={{ mb: 1.5, fontSize:'12px' }}
                                color="text.secondary"
                                dangerouslySetInnerHTML={{ __html: eventMeal['eat_in_details'].replace(/\n/g, '<br />') }}
                            />
                        ) : (
                            <></>
                        )
                    }

                </CardContent>

                <CardActions>
                    <div className="meals-suggestions-add-list-item-footer-container">
                        {/* add the meal date */}
                        <div
                            style={{backgroundColor: selectedAction === "DateTime" ? '#EDEDED' : 'initial'}}
                            className="meals-suggestions-add-list-item-footer-dates-container"
                            onClick={() => handleFooterItemClick('DateTime')}
                        >
                            <i className="icon-calendar3" title="Date and Time"/>
                        </div>
                        {/* add the meal location */}
                        <div
                            style={{backgroundColor: selectedAction === "Location" ? '#EDEDED' : 'initial'}}
                            className="meals-suggestions-add-list-item-footer-location-container"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <i className="icon-map-marker" title="Location"/>
                        </div>
                        {/* add the meal participants */}
                        <div
                            style={{backgroundColor: selectedAction === "Participants" ? '#EDEDED' : 'initial'}}
                            className="meals-suggestions-add-list-item-footer-attendees-container"
                            onClick={()=>handleFooterItemClick('Attendees')}
                        >

                            <Badge
                                badgeContent={eventMeal['attendees'].length}
                                color="badge"
                                showZero
                            >
                                <i className="icon-user-24"  title="Attendees"/>
                            </Badge>
                        </div>
                        {/* add the meal costs */}
                        <div
                            style={{backgroundColor: selectedAction === "Costs" ? '#EDEDED' : 'initial'}}
                            className="meals-suggestions-add-list-item-footer-costs-container"
                            onClick={()=>handleFooterItemClick('Costs')}
                        >
                            <i className="icon-wallet"  title="Costs"/>
                            <span className="meals-suggestions-add-list-item-footer-costs-tab-amount">
                            {parseFloat(eventMeal['amount_total']).toFixed(2)}
                        </span>
                        </div>
                        {/* add the meal details */}
                        <div
                            style={{backgroundColor: selectedAction === "Details" ? '#EDEDED' : 'initial'}}
                            className="meals-suggestions-add-list-item-footer-details-container"
                            onClick={()=>handleFooterItemClick('Details')}
                        >
                            <Badge
                                badgeContent={0}
                                color="badge"
                            >
                                <i className="icon-settings-icons"  title="Details"/>
                            </Badge>
                        </div>
                    </div>
                </CardActions>

                <CardContent sx={{padding:'20px 0', display:mealTabSelected?'block':'none'}}>
                    {
                        selectedAction === "DateTime" ? (
                            <div className="meals-suggestions-add-list-item-footer-date-holder">


                                <Grid container spacing={2} direction="column">

                                    <Grid item >
                                        <TabContext sx={{ position:'relative'}} value={tabValue}>
                                            <Box
                                                sx={{
                                                    width: '318px',
                                                    marginTop: '-5px',
                                                    borderBottom: 1,
                                                    borderColor: 'divider',
                                                    backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                                    backdropFilter: 'blur(6px)',
                                                    zIndex:'2'
                                                }}
                                            >
                                                <TabList
                                                    centered
                                                    variant="fullWidth"
                                                    onChange={handleTabValueChange}
                                                    aria-label="Meal Date / Day"
                                                >
                                                    <Tab
                                                        label="Date"
                                                        value="date"
                                                    />
                                                    <Tab
                                                        label="Day"
                                                        value="day"
                                                    />
                                                </TabList>
                                            </Box>

                                            <TabPanel value="date" sx={{padding: '50px 10px 10px 10px'}}>
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-date"
                                                >
                                                    Meal Date :&nbsp;
                                                    {
                                                        (() => {
                                                            const { time_table } = eventMeal;

                                                            // Check for time_table.dates first
                                                            if (time_table?.dates?.length > 0) {
                                                                return dayjs(time_table.dates[0].date, 'YYYY-MM-DD').format('D MMM, YYYY');
                                                            }

                                                            // If neither, display nothing
                                                            return '';
                                                        })()
                                                    }
                                                    <Chip
                                                        sx={editChipStyle}
                                                        label={showCalendarSelectMealDate ? 'Done' : 'Edit'}
                                                        size="small"
                                                        onClick={handlePromptShowMealDateCalendar}
                                                    />
                                                </div>
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-date-input"
                                                    style={{display: showCalendarSelectMealDate ? 'block' : 'none'}}>
                                                    <DatePicker
                                                        sx={{width: '100%'}}
                                                        label="Meal Date"
                                                        value={
                                                            eventMeal['time_table']?.dates?.length > 0
                                                                ? dayjs(eventMeal['time_table'].dates[0].date, 'YYYY-MM-DD') // Return the correct date value for DatePicker
                                                                : null // Set to null if no date is available
                                                        }
                                                        onChange={handleMealDateChange}
                                                    />
                                                </div>

                                                {/* for the meal time */}
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-time"
                                                >
                                                    Meal Time :&nbsp;
                                                    {
                                                        (() => {
                                                            const { time_table } = eventMeal;

                                                            // Check for time_table.dates first
                                                            if (time_table?.dates?.length > 0) {
                                                                return time_table.dates[0].meal_time;
                                                            }

                                                            // If neither, display nothing
                                                            return '';
                                                        })()
                                                    }
                                                    <Chip
                                                        sx={editChipStyle}
                                                        label={showClockSelectMealTime ? 'Done' : 'Edit'}
                                                        size="small"
                                                        onClick={handlePromptShowMealTime}
                                                    />
                                                </div>
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-time-input"
                                                    style={{display: showClockSelectMealTime ? 'block' : 'none'}}>
                                                    <TimePicker
                                                        sx={{width: '100%'}}
                                                        label="Meal Time"
                                                        value={dayjs(eventMeal['time'], 'h:mm A')}
                                                        onChange={handleMealTimeChange}
                                                    />
                                                </div>

                                            </TabPanel>

                                            <TabPanel value="day" sx={{padding: '50px 10px 10px 10px'}}>

                                                <div
                                                    className="meals-suggestions-add-list-item-footer-date"
                                                >
                                                    Meal Day :&nbsp;
                                                    {
                                                        (() => {
                                                            const {time_table} = eventMeal;

                                                            // If no dates, check for time_table.days
                                                            if (time_table?.days?.length > 0) {
                                                                return time_table.days[0][Object.keys(time_table.days[0])[0]]; // Display day (e.g., 'Day 1')
                                                            }

                                                            // If neither, display nothing
                                                            return '';
                                                        })()
                                                    }
                                                    <Chip
                                                        sx={editChipStyle}
                                                        label={showSelectMealDay ? 'Done' : 'Edit'}
                                                        size="small"
                                                        onClick={handlePromptShowMealDaySelect}
                                                    />
                                                </div>

                                                <div
                                                    className="meals-suggestions-add-list-item-footer-date-input"
                                                    style={{display: showSelectMealDay ? 'block' : 'none'}}
                                                >
                                                    {
                                                        Planning['time_table']?.days?.length > 0 ? (
                                                            <RadioGroup
                                                                value={selectedMealDay} // The state that holds the currently selected day
                                                                onChange={handleMealDayChange} // Update the selected day
                                                            >
                                                                {Planning['time_table']?.days.map((_day, index) => {
                                                                    const dayNumber = Object.keys(_day)[0]; // Get the day number (e.g., "day 1")
                                                                    const dayOfWeek = _day[dayNumber]; // Get the day of the week (e.g., "Thursday")

                                                                    return (
                                                                        <FormControlLabel
                                                                            key={index}
                                                                            value={dayNumber} // The key (e.g., "day 1")
                                                                            control={<Radio />}
                                                                            label={
                                                                                <div>
                                                                                    {dayOfWeek} <span style={{ fontSize: '12px', color: '#888' }}>({dayNumber})</span>
                                                                                </div>
                                                                            } // Display the day of the week with the day number in smaller text
                                                                        />
                                                                    );
                                                                })}
                                                            </RadioGroup>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>


                                                {/* for the meal time */}
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-time"
                                                >
                                                    Meal Time :&nbsp;
                                                    {
                                                        eventMeal['time']
                                                    }
                                                    <Chip
                                                        sx={editChipStyle}
                                                        label={showClockSelectMealTime ? 'Done' : 'Edit'}
                                                        size="small"
                                                        onClick={handlePromptShowMealTime}
                                                    />
                                                </div>
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-time-input"
                                                    style={{display: showClockSelectMealTime ? 'block' : 'none'}}>
                                                    <TimePicker
                                                        sx={{width: '100%'}}
                                                        label="Meal Time"
                                                        value={dayjs(eventMeal['time'], 'h:mm A')}
                                                        onChange={handleMealTimeChange}
                                                    />
                                                </div>

                                            </TabPanel>
                                        </TabContext>
                                    </Grid>

                                </Grid>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="meals-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{marginTop: '0'}}>
                                    <Grid item style={{flex: '1'}}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_meal_location_name"
                                                    label="Name"
                                                    value={eventMeal['location'].name}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_address"
                                                    label="Address"
                                                    value={eventMeal['location'].address}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                            <TextField
                                                    fullWidth
                                                    id="new_activity_location_city"
                                                    label="City"
                                                    value={eventMeal['location'].city}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_state"
                                                    label="State"
                                                    value={eventMeal['location'].state}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_zip"
                                                    label="Zip"
                                                    value={eventMeal['location'].zip}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_country"
                                                    label="Country"
                                                    value={eventMeal['location'].country}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_phone"
                                                    label="Phone"
                                                    value={eventMeal['location'].phone}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Attendees" && (
                            Attendees.length > 0 ? (
                                <List dense sx={{ width: '100%' }} >
                                    {/* Filter out meal attendees and sort by name */}
                                    {Attendees.filter(_attendee => Planning['attendees'].some(_att => _att.id === _attendee.id && _att.status !== 4))
                                        .sort((a, b) => `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(`${formatName(b.lname)}, ${formatName(b.fname)}`))
                                        .map((_attendee, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={handleAttendeesToggle(_attendee.id)}
                                                            checked={eventMeal['attendees'].some(_att => _att.id === _attendee.id)}
                                                            inputProps={{ 'aria-labelledby': index }}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            id={index}
                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))
                                    }

                                    <Box mt={6} mb={1}>
                                        <Divider />
                                    </Box>

                                    {/* Autocomplete for non-activity attendees */}
                                    <Autocomplete
                                        multiple
                                        options={Attendees.filter(_attendee => !Planning['attendees'].some(_att => (_att.id === _attendee.id && _att.status !== 4)))}
                                        getOptionLabel={option => `${formatName(option.lname)}, ${formatName(option.fname)}`}
                                        renderOption={(props, option) => (
                                            <ListItem {...props} key={option.id}>
                                                <ListItemAvatar>
                                                    <Avatar {...stringAvatar(`${formatName(option.fname)}, ${formatName(option.lname)}`)} />
                                                </ListItemAvatar>
                                                <ListItemText primary={`${formatName(option.lname)}, ${formatName(option.fname)}`} />
                                            </ListItem>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Add Attendees" placeholder="Search Attendees" />
                                        )}
                                        onChange={(event, newValue) => {
                                            // Handle the selected attendees and toggle them
                                            newValue.forEach((newAttendee) => {
                                                // Trigger handleAttendeesToggle for each selected attendee
                                                handleAttendeesToggle(newAttendee.id)();
                                            });
                                        }}
                                    />

                                    <Box mt={6} mb={1}>
                                        <Divider />
                                    </Box>

                                    {Attendees.filter(_attendee => !Planning['attendees'].some(_att => (_att.id === _attendee.id && _att.status !== 4)))
                                        .sort((a, b) => `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(`${formatName(b.lname)}, ${formatName(b.fname)}`))
                                        .map((_attendee, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={handleAttendeesToggle(_attendee.id)}
                                                            checked={eventMeal['attendees'].some(_att => _att.id === _attendee.id)}
                                                            inputProps={{ 'aria-labelledby': index }}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            id={index}
                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))
                                    }
                                </List>
                            ) : (
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <i className="icon-warning" style={{ color: '#FF0000' }} /> Add Attendees to Event first
                                    </Typography>
                                </div>
                            )
                        )
                    }
                    {
                        selectedAction === "Costs" ? (
                            <div className="meals-suggestions-add-list-item-footer-costs-holder">
                                {/* Cost Summary */}
                                <Typography variant="body2">
                                    Cost Summary
                                </Typography>
                                <div
                                    className="meals-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="meals-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventMeal['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventMeal['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventMeal['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="meals-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="meals-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            type="number"
                                            label="Meal Total"
                                            value={eventMeal["amount_total"]}
                                            onChange={handleCostOverride}
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_activity is the Assignment area */}
                                <div className="meals-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        Planning.attendees.length === 0 ? (
                                            <div
                                                className="meals-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="meals-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_meal is the toggle section */}
                                                <div className="meals-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventMeal['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventMeal['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_activity is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventMeal['attendees']
                                                            .sort((a, b) => {
                                                                const _a = Attendees.find((_att)=>_att.id === a.id);
                                                                const _b = Attendees.find((_att)=>_att.id === b.id);
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => (
                                                                <div
                                                                    className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                    key={index}
                                                                >
                                                                    <ListItem
                                                                        className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                        secondaryAction={
                                                                            eventMeal['assign_equal'] ? (
                                                                                <span className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost">
                                                                                {_attendee.cost}&nbsp;{eventMeal['currency']}
                                                                            </span>
                                                                            ) : (
                                                                                <span className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                <TextField
                                                                                    label={
                                                                                        formatName(Attendees.find((_att)=>_att.id === _attendee.id).lname)
                                                                                        + ', ' +
                                                                                        formatName(Attendees.find((_att)=>_att.id === _attendee.id).fname)
                                                                                    }
                                                                                    value={_attendee.cost}
                                                                                    onChange={(e)=>handleCustomAttendeeCost(_attendee.id, e)}
                                                                                    variant="standard"
                                                                                />
                                                                            </span>
                                                                            )
                                                                        }
                                                                        disablePadding
                                                                    >
                                                                        <ListItemAvatar>
                                                                            {Attendees.find((_att) => _att.id === _attendee.id) && ( // Check if the Attendee is found
                                                                                <Avatar {...stringAvatar(`${formatName(Attendees.find((_att) => _att.id === _attendee.id).fname)}, ${formatName(Attendees.find((_att) => _att.id === _attendee.id).lname)}`)} />
                                                                            )}
                                                                        </ListItemAvatar>

                                                                        <ListItemText
                                                                            sx={{ display: eventMeal['assign_equal'] ? 'block' : 'none' }}
                                                                            id={index}
                                                                            primary={Attendees.find((_att) => _att.id === _attendee.id) ? // Check if Attendee is found
                                                                                `${formatName(Attendees.find((_att) => _att.id === _attendee.id).lname)}, ${formatName(Attendees.find((_att) => _att.id === _attendee.id).fname)}`
                                                                                : '' // Return empty string if Attendee is not found
                                                                            }
                                                                        />

                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>

                                                            ))
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div className="meals-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span className="meals-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventMeal['amount_total']) - eventMeal['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventMeal['currency']
                                                        }
                                                    </span>

                                                </div>

                                                {/* show the update button if changes were made */}

                                                {
                                                    showPriceChangesUpdateButton ? (
                                                        <div style={{cursor: 'pointer'}} onClick={handleSaveMealCostAssignment}>
                                                            <Box sx={addGradientStyle}>
                                                                <Chip
                                                                    sx={addChipStyle}
                                                                label='Save Changes'
                                                                size="small"
                                                            />
                                                            </Box>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_booked_through"
                                                label="Booked Through"
                                                value={eventMeal['booked_through']}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_confirmation_number"
                                                label="Confirmation Number"
                                                value={eventMeal['confirmation_number']}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_name"
                                                label="Reservation Name"
                                                value={eventMeal['reservation_name']}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_number"
                                                label="Reservation Number"
                                                value={eventMeal['reservation_number']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_website"
                                                label="Website"
                                                value={eventMeal['website']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_bring"
                                                label="Bring"
                                                multiline
                                                rows={4}
                                                value={eventMeal['bring']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_details"
                                                label="Details"
                                                multiline
                                                rows={4}
                                                value={eventMeal['details']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={eventMeal['notes']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                </CardContent>
            </Card>
        </div>
    )
}

export default MealCard;

