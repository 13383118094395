import React from 'react';



function Dashboard(props) {


    return (
        <>
            Dashboard
            <div>
                {props.dashboardNotices}
            </div>

        </>
    )

}

export default Dashboard;
