import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import MessageCard from "./MessageCard";
import {Button, Chip, FormControlLabel, Switch, TextField} from "@mui/material";
import {cancelChipStyle, cancelGradientStyle} from "../../ButtonStyle/Cancel";
import './messages.css';
import {addGradientStyle} from "../../ButtonStyle/Add";
import {updateChipStyle} from "../../ButtonStyle/Update";



function MessagesList(props) {

    const { dataArray } = useContext(PAContext);
    const { Planning, IncomingEventActions, Account } = dataArray;

    const messagesEndRef = useRef(null);  // Create a ref

    const [ eventMessages, setEventMessages ] = useState([]);

    useEffect(() => {
        console.log('Planning.messages:', JSON.stringify(Planning.messages, null, '\t'));
        setEventMessages(Planning.messages);
    },[Planning.messages]);

    useEffect(() => {
        //handle the incoming action
        for (let i = IncomingEventActions.length - 1; i >= 0; i--) {
            const _incoming = IncomingEventActions[i];
            // Perform operations on the current action
            console.log('Action:', JSON.stringify(_incoming, null, '\t'));

            switch (_incoming.action) {
                case "E_getEventMessagesResults":

                    //add the messages
                    setEventMessages(prevMessages => {
                        // Create a Set of existing message IDs for quick lookup
                        const existingIds = new Set(prevMessages.map(msg => msg.id));

                        // Filter the incoming messages list to exclude any that already exist
                        const newMessages = _incoming["messages_list"].filter(msg => !existingIds.has(msg.id));

                        // Concatenate the previous messages with the new, non-duplicate messages
                        return [...prevMessages, ...newMessages];
                    });

                    // Remove the one item we just processed
                    IncomingEventActions.splice(i, 1);

                    break;

                default:

            }

        }
    }, [IncomingEventActions]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [eventMessages]);

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    const [ newMessage, setNewMessage ] = useState("");
    const [ priority, setPriority ] = useState(false);


    // Function to handle sending a message
    const handleSendMessage = () => {
        if (newMessage.trim() !== "") {
            // Code to handle sending message (e.g., API POST request)
            console.log("Sending message:", newMessage);

            const _send_string = {};
            _send_string.action = "E_sendEventMessage";
            _send_string.event_id = Planning.id;
            _send_string.link_with_id = 0;
            _send_string.priority = priority;
            _send_string.message = newMessage;
            _send_string.recipients = [];

            console.log(JSON.stringify(_send_string));
            dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");

            // Clear input after sending
            setNewMessage("");

            //don't leave the priority button selected
            setPriority(false);
        }
    };

    return (

        <div className="messages-list-container">

            {/* show the header */}
            <div
                className="messages-list-header"
            >
                <div className="messages-list-header-text">
                    <h5>Messages List</h5>
                </div>
                <div className="messages-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />

                </div>
            </div>

            {/* show the list of Messages */}
            <div className="messages-list-items-container"
                 style={{
                     height: 'calc(68vh)',
                     width: '100%',
                     display: 'flex',
                     flexDirection: 'column',
                     position:'relative'
                 }}>
                {/* Scrollable area for messages */}
                <div
                    style={{
                        height: '500px',
                        flex: 1,
                        overflowY: 'auto',
                        padding: '10px',
                        marginBottom: '125px'
                    }}
                >
                    {
                        eventMessages?.length > 0 ? (
                            eventMessages
                                .sort((a, b) => a.created - b.created)
                                .map((_message, index) => (
                                    <MessageCard
                                        key={index}
                                        message={_message}
                                        align={_message.attendee_id !== Account.id ? "left" : "right"}
                                    />
                                ))
                        ) : (
                            <p>No Messages Yet</p>
                        )
                    }
                    <div ref={messagesEndRef}/>
                </div>
                {/* Input box and POST button */}
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '94%',
                    borderTop: '1px solid #ccc',
                    padding: '10px',
                    backgroundColor: 'white'
                }}>
                    {/* TextField on its own line */}
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Type your message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                    />

                    {/* Toggle and Button on the line below */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}>
                        {/* Toggle Switch */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={priority}
                                    onChange={() => setPriority(!priority)}
                                    color="primary"
                                />
                            }
                            label="Priority"
                        />

                        {/* Send Button */}
                        <Chip
                            sx={updateChipStyle}
                            variant="contained"
                            label="Send"
                            onClick={handleSendMessage}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MessagesList;
